<template>
  <v-card tile>
    <loading :value="updating"></loading>
    <v-card-text>
      <div class="overflow-x-auto">
        <v-btn-toggle v-model="state" mandatory class="ml-2 mt-2">
          <v-btn
            v-for="state in states"
            :key="state"
            :value="state"
            v-show="state != agreement.DELETED || permission('agreement-edit')"
            :class="{ 'pa-0': xsOnly, 'pa-2': !xsOnly }"
            :disabled="loading"
            :ripple="false"
          >
            <span>{{ agreement.stateName(state) }}</span>
            <v-chip
              outlined
              v-show="
                !xsOnly && showstateCounts && permission('agreement-view')
              "
              small
              class="ml-1"
            >
              {{ stateCounts[state] }}
            </v-chip>
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-divider></v-divider>
            <template v-for="(item, index) in items">
              <v-list-item
                :key="index * 2 + 1"
                :to="`/agreements/${item.id}`"
                exact
                :ripple="false"
              >
                <v-list-item-action
                  class="align-self-start hidden-xs-only mr-1"
                >
                  <v-list-item-action-text>
                    {{ index + 1 }}
                  </v-list-item-action-text>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.businessTitle }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="d-flex align-center mt-1">
                    <span>{{ item.executor }}</span>
                    <span v-if="vendorVisible" class="ml-1">
                      | {{ item.vendorName }}
                    </span>
                    <v-spacer></v-spacer>
                    <agreement-state
                      :state="item.state"
                      :small="smAndDown"
                    ></agreement-state>
                    <span class="ml-1">
                      {{ registerTime(item.registerTime) }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index * 2 + 0"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <div v-show="loadMore">
        <v-progress-linear
          v-show="loading"
          :indeterminate="loading"
        ></v-progress-linear>
        <v-btn v-show="!loading" @click="load()" block text>
          {{ $t('load-more') }}
        </v-btn>
        <v-divider v-show="!loading" class="mt-2"></v-divider>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col cols="12" :sm="exactVisible ? 9 : 12">
            <v-text-field
              ref="search"
              v-model="search"
              :label="$t('search')"
              clearable
              hide-details
              @change="query()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" v-show="exactVisible">
            <v-checkbox
              ref="exact"
              v-model="exact"
              :label="$t('search-exact')"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-select
              v-model="duration"
              :items="durationItems"
              :label="$t('agreement-duration')"
              hide-details
              :menu-props="{ maxHeight: 'auto' }"
            ></v-select>
            <v-dialog
              ref="dateDialog"
              v-model="dateModal"
              :return-value.sync="date"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="dateVisible"
                  v-model="date"
                  :label="dateLabel"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datePicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="dateModal = false">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.dateDialog.save(datePicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="untilDialog"
              v-model="untilModal"
              :return-value.sync="until"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="untilVisible"
                  v-model="until"
                  :label="$t('agreement-until')"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="untilPicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="untilModal = false">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.untilDialog.save(untilPicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="6" sm="4" v-if="permission('agreement-view')">
            <v-autocomplete
              v-model="vendor"
              :items="$store.state.vendorItems"
              hide-no-data
              hide-details
              auto-select-first
              clearable
              :label="$t('vendor')"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.label }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="permission('agreement-view') && false">
            <v-autocomplete
              v-model="region"
              :loading="regionLoading"
              :items="regionItems"
              :search-input.sync="regionText"
              hide-no-data
              hide-details
              auto-select-first
              clearable
              :label="
                $t('agreement-region') + ': ' + $t('agreement-region-hint')
              "
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          fab
          fixed
          bottom
          right
          to="/agreements/new"
          exact
          v-if="canAdd"
        >
          <v-icon>{{ $svg('mdi-plus') }}</v-icon>
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import AgreementState from './AgreementState.vue';
import agreement from '@/agreement';
import i18n from '@/i18n';
import Loading from './Loading';
import datefmt from '@/datefmt';

export default {
  name: 'agreements',
  components: {
    'agreement-state': AgreementState,
    'loading': Loading,
  },

  data: () => ({
    q: '',
    agreement: agreement,
    duration: 0,
    durationItems: [
      { value: 0, text: i18n.t('agreement-duration-0') },
      { value: 1, text: i18n.t('agreement-duration-1') },
      { value: 2, text: i18n.t('agreement-duration-2') },
      { value: 3, text: i18n.t('agreement-duration-3') },
      { value: 4, text: i18n.t('agreement-duration-4') },
      { value: 5, text: i18n.t('agreement-duration-5') },
      { value: 6, text: i18n.t('agreement-duration-6') },
    ],
    date: datefmt.today(),
    dateModal: false,
    until: datefmt.today(),
    untilModal: false,
    untilPicker: '',
    datePicker: '',
    search: '',
    vendor: '',
    items: [],
    state: agreement.ALL,
    states: [
      agreement.ALL,
      agreement.REGISTERED,
      agreement.ACCEPTED,
      agreement.COMPLETED,
      agreement.DENIED,
      agreement.DRAFT,
      agreement.DELETED,
    ],
    showstateCounts: true,
    stateCounts: {},
    loading: false,
    updating: false,
    listMaxCount: 20,
    exact: false,
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },
    vendorVisible() {
      return this.permission('agreement-view');
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    dateVisible() {
      return this.duration > 0;
    },
    dateLabel() {
      return this.duration < 6
        ? i18n.t('agreement-register-time')
        : i18n.t('agreement-since');
    },
    untilVisible() {
      return this.duration == 6;
    },
    canAdd: function () {
      return this.permission('agreement-add');
    },
    loadMore() {
      return this.loading || (this.items.length > 0 && this.loadStart);
    },
    exactVisible() {
      return (this.search || '').trim().indexOf(' ') > 0;
    },
    loadCount() {
      return this.$vuetify.breakpoint.xsOnly ? 20 : 20;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.agreementReload) vm.init();
      vm.$store.commit('setButtons', [
        {
          icon: 'mdi-download',
          text: vm.$t('agreement-xlsx-export'),
          click: vm.exportXLSX,
        },
        {
          icon: 'mdi-magnify',
          text: vm.$t('search'),
          click: vm.gotoSearch,
        },
      ]);
    });
  },

  beforeRouteLeave(to, from, next) {
    next();
    this.$store.commit('reloadAgreements', to.name != 'agreement');
    this.$store.commit('clearButtons');
    this.loading = false;
  },

  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },

  created() {
    this.init();
  },

  watch: {
    state: function (v) {
      if (!agreement.stateCode(v)) return;
      this.query();
    },
    vendor: function () {
      this.query();
    },
    duration: function () {
      this.query();
    },
    date: function () {
      this.query();
    },
    dateModal: function (v) {
      if (v) this.datePicker = this.date;
    },
    until: function () {
      this.query();
    },
    untilModal: function (v) {
      if (v) this.untilPicker = this.until;
    },
    exact: function () {
      this.query();
    },
  },
  methods: {
    init: function () {
      let self = this;
      self.items = [];

      self.state = agreement.ALL;
      self.loadStart = undefined;
      self.showstateCounts = true;
      self.vendor = '';
      self.search = '';
      self.duration = 0;
      self.date = datefmt.today();
      self.until = datefmt.today();
      self.q = self.$route.query.q;
      if (self.q) {
        let q = self.q.split('+');
        q.forEach((v) => {
          let k = v.split(':');
          if (k[0] == 'state') {
            self.state = parseInt(k[1]);
          } else if (k[0] == 'vendor') {
            self.vendor = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'search') {
            self.search = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'duration') {
            self.duration = parseInt(k[1]);
            self.showstateCounts = false;
          } else if (k[0] == 'date') {
            self.date = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'until') {
            self.until = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'exact') {
            self.exact = parseInt(k[1]) ? true : false;
            self.showstateCounts = false;
          }
        });
      }
      if (!self.permission('agreement-view')) {
        self.vendor = self.user.vendor;
      }
      self.loadStateCounts();
      self.load();
    },
    load: async function () {
      let self = this;
      if (self.loading) return;
      self.loading = true;

      if (!self.loadStart) self.items = [];
      let limit = self.loadCount;

      while (limit > 0 && self.loading) {
        let result = await agreement.list({
          state: self.state,
          vendor: self.vendor,
          duration: self.duration,
          date: self.date,
          untilDate: self.until,
          vendorName: self.vendorName,
          search: self.search.trim(),
          last: self.loadStart,
          limit: limit,
          exact: self.exact,
        });

        self.loadStart = result.last;
        result.docs.forEach((v) => {
          const customer = v.customer.replace(/\s/g, '');
          const business = v.business.replace(/\s/g, '');
          if (
            customer.length > 0 &&
            business.length > 0 &&
            business.indexOf(customer) < 0
          )
            v.businessTitle = `${v.customer.trim()} ${v.business.trim()}`;
          else v.businessTitle = v.business.trim();

          v.vendorName = this.$store.getters.getVendor(v.vendor).name;
          self.items.push(v);
        });

        if (result.docs.length == limit || !result.last) break;

        limit = limit - result.docs.length;
        await this.$nextTick();
      }

      self.loading = false;
    },
    loadStateCounts: async function () {
      if (!this.showstateCounts) return;
      let self = this;
      let data = await agreement.loadStateCounts();
      self.stateCounts = {};
      self.states.forEach(function (state) {
        self.stateCounts[state] =
          data['count_' + agreement.stateCode(state)] || 0;
      });
    },
    registerTime(v) {
      return datefmt.readable(new Date(v));
    },
    permission: function (perm) {
      return this.$store.state.permissions[perm] || false;
    },
    dayFormat: function (d) {
      return parseInt(d.substr(8, 10));
    },
    query: function () {
      let q = [];
      if (this.state != agreement.ALL) q.push('state:' + this.state);
      if (this.vendor) q.push('vendor:' + this.vendor);
      if (this.search) q.push('search:' + this.search.trim());
      if (this.duration > 0) q.push('duration:' + this.duration);
      if (this.date != datefmt.today()) q.push('date:' + this.date);
      if (this.until != datefmt.today()) q.push('until:' + this.until);
      if (this.exact) q.push('exact:' + (this.exact ? '1' : '0'));

      let query = {};
      if (q.length > 0) query.q = q.join('+');

      if (this.q && this.q === query.q) return;

      this.$router
        .push({ path: this.$route.path, query: query })
        .catch(function () {});
    },
    gotoSearch: function () {
      this.$vuetify.goTo(this.$refs.search);
      this.$refs.search.focus();
    },
    exportXLSX: async function () {
      this.updating = true;
      await agreement.exportXLSX({
        state: this.state,
        vendor: this.vendor,
        duration: this.duration,
        date: this.date,
        untilDate: this.until,
        vendorName: this.vendorName,
        search: this.search.trim(),
        limit: 0,
        exact: this.exact,
      });
      this.updating = false;
    },
  },
};
</script>

<style scoped>
.v-chip {
  margin-right: 4px;
}
</style>
