<template>
  <v-overlay :value="value">
    <v-progress-circular
      v-if="false"
      indeterminate
      :size="64"
      :color="progressColor"
    ></v-progress-circular>
    <h1 v-if="message != null">
      {{ message }}
    </h1>
  </v-overlay>
</template>

<script>
export default {
  name: 'Loading',
  data: function () {
    return {};
  },
  props: {
    value: { type: Boolean, default: false },
    message: { type: String, default: null },
    progressColor: { type: String, default: 'primary' },
  },
};
</script>
