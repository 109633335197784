<template>
  <v-container class="fill-height">
    <v-overlay v-show="!authenticated" color="transparent">
      <v-progress-circular
        indeterminate
        color="amber darken-1"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row align="center">
      <v-col cols="8" offset="2" sm="4" offset-sm="4" v-show="authenticated">
        <v-list class="py-0">
          <v-list-item
            v-for="link in links"
            :key="link.name"
            :to="link.path"
            exact
            class="elevation-2 my-2"
          >
            <v-list-item-action>
              <v-icon>{{ $svg(link.meta.icon) }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(link.name) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="8"
        offset="2"
        sm="4"
        offset-sm="4"
        v-show="authenticated"
        class="text-center"
      >
        <span class="text-caption">{{ versionText }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import version from '@/version';

export default {
  name: 'home',
  data: () => ({
    copyrightHolders: (function () {
      var year = new Date().getFullYear();
      return year === 2019 ? '2019' : '2019-' + year;
    })(),
    versionText: version,
  }),

  computed: {
    authenticated() {
      return (
        this.$store.state.authenticated &&
        !this.$store.state.initialPath &&
        this.$store.state.userItems.length > 0
      );
    },
    links() {
      return this.$router.options.routes.filter((route) => {
        if (this.$store.state.user.disabled) return false;
        if (route.path == '/') return false;
        if (!route.meta) return false;
        if (!route.meta.home) return false;
        if (!route.meta.icon) return false;
        if (!route.meta.ignoreAuth && !this.$store.state.authenticated)
          return false;
        if (
          route.meta.permission &&
          !this.$store.state.permissions[route.meta.permission]
        )
          return false;
        return true;
      });
    },
  },

  watch: {
    links(val) {
      if (val.length == 1 && this.authenticated)
        this.$router.replace(val[0].path);
    },
  },
};
</script>
