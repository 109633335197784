import i18n from './i18n';
import firebase from './firebase';
import store from './store';
import datefmt from '@/datefmt';
import product from './product';
import { saveAs } from 'file-saver';

const db = firebase.firestore();
const functions = firebase.functions();

const ALL = -999;
const DELETED = -1;
const DRAFT = 0;
const REGISTERED = 1;
const ACCEPTED = 2;
const COMPLETED = 3;
const CANCELED = 4;

const stateNames = {};
stateNames[ALL] = i18n.t('estimate-state-all');
stateNames[DELETED] = i18n.t('estimate-state-deleted');
stateNames[DRAFT] = i18n.t('estimate-state-draft');
stateNames[REGISTERED] = i18n.t('estimate-state-registered');
stateNames[ACCEPTED] = i18n.t('estimate-state-accepted');
stateNames[COMPLETED] = i18n.t('estimate-state-completed');
stateNames[CANCELED] = i18n.t('estimate-state-canceled');

const stateCodes = {};
stateCodes[ALL] = 'all';
stateCodes[DELETED] = 'deleted';
stateCodes[DRAFT] = 'draft';
stateCodes[REGISTERED] = 'registered';
stateCodes[ACCEPTED] = 'accepted';
stateCodes[COMPLETED] = 'completed'
stateCodes[CANCELED] = 'canceled';

const stateForCodes = {};
stateForCodes['all'] = ALL;
stateForCodes['deleted'] = DELETED;
stateForCodes['draft'] = DRAFT;
stateForCodes['registered'] = REGISTERED;
stateForCodes['accepted'] = ACCEPTED;
stateForCodes['completed'] = COMPLETED;
stateForCodes['canceled'] = CANCELED;

const stateColors = {};
stateColors[ALL] = '';
stateColors[DELETED] = 'warning';
stateColors[DRAFT] = '';
stateColors[REGISTERED] = '';
stateColors[ACCEPTED] = 'info';
stateColors[COMPLETED] = 'success'
stateColors[CANCELED] = 'error';

const stateTextColors = {};
stateTextColors[ALL] = '';
stateTextColors[DELETED] = 'white';
stateTextColors[DRAFT] = '';
stateTextColors[REGISTERED] = '';
stateTextColors[ACCEPTED] = 'white';
stateTextColors[COMPLETED] = 'white';
stateTextColors[CANCELED] = 'white';

const states = [];
states.push('ALL');
states.push('DELETED');
states.push('DRAFT');
states.push('REGISTERED');
states.push('ACCEPTED');
states.push('COMPLETED');
states.push('CANCELED');

const estimate = {
  ALL: ALL,
  DELETED: DELETED,
  DRAFT: DRAFT,
  REGISTERED: REGISTERED,
  ACCEPTED: ACCEPTED,
  COMPLETED: COMPLETED,
  CANCELED: CANCELED,

  stateCode: function (state) {
    return stateCodes[state] || '';
  },
  stateForCode: function (code) {
    return stateForCodes[code];
  },
  stateName: function (state) {
    return stateNames[state] || '';
  },
  stateColor: function (state) {
    return stateColors[state] || '';
  },
  stateTextColor: function (state) {
    return stateTextColors[state] || '';
  },

  urgentDay: function () {
    const now = new Date();
    now.setDate(now.getDate() - 60);
    return now;
  },

  hit: async function (id, uid) {
    let ref = db.collection('estimates').doc(id);
    let hitRef = ref.collection('hits').doc(uid);
    let doc = await hitRef.get()
    if (doc.exists) {
      hitRef.update({
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
      return;
    }
    await hitRef.set({
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: uid,
    });
  },
  save: async function (doc, id, uid, currency) {
    let log = [];
    if (doc.state != null) {
      log.push('state:' + doc.state);
    }
    if (doc.vendor != null) {
      log.push('vendor:' + doc.vendor);
    }
    if (doc.sender != null) {
      log.push(i18n.t('estimate-sender') + ': ' + store.getters.getUser(doc.sender).name);
    }
    if (doc.estimator != null) {
      let name = doc.estimator == 0 ? i18n.t('estimate-estimator-0') : i18n.t('estimate-estimator-1');
      log.push(i18n.t('estimate-estimator') + ': ' + name);
    }
    if (doc.customer != null) {
      log.push(i18n.t('estimate-customer') + ': ' + doc.customer);
    }
    if (doc.customerLevel != null) {
      let level = '';
      if (doc.customerLevel == 'subsidiary') {
        level = i18n.t('vendor-level-subsidiary');
      } else if (doc.customerLevel == 'branch') {
        level = i18n.t('vendor-level-branch');
      } else if (doc.customerLevel == 'oem') {
        level = i18n.t('vendor-level-oem');
      } else if (doc.customerLevel == 'agency') {
        level = i18n.t('vendor-level-agency');
      } else if (doc.customerLevel == 'company') {
        level = i18n.t('vendor-level-company');
      } else if (doc.customerLevel == 'consumer') {
        level = i18n.t('vendor-level-consumer');
      }
      log.push(i18n.t('estimate-customer-level') + ': ' + level);
    }
    if (doc.manager != null) {
      log.push(i18n.t('estimate-manager') + ': ' + doc.manager);
    }
    if (doc.business != null) {
      log.push(i18n.t('estimate-business') + ': ' + doc.business);
    }
    if (doc.decision != null) {
      let decision = '';
      if (doc.decision == 0)
        decision = i18n.t('estimate-decision-0');
      else if (doc.decision == 1)
        decision = i18n.t('estimate-decision-1');
      else if (doc.decision == -1)
        decision = i18n.t('estimate-decision-2');

      log.push(i18n.t('estimate-decision') + ': ' + decision);
    }
    if (doc.decisionOther != null) {
      if (doc.decision == -1)
        log.push(i18n.t('estimate-decision-other') + ': ' + doc.decisionOther);
    }
    if (doc.note != null) {
      log.push(i18n.t('estimate-note') + ': ' + doc.note.split('\n').join(' '));
    }
    if (doc.privateNote != null) {
      log.push(i18n.t('estimate-private-note') + ': ' + doc.privateNote.split('\n').join(' '));
    }
    if (doc.products != null) {
      log.push(i18n.t('estimate-products') + ': ' + doc.products.map(function (v) {
        let note = (v.note && v.note !== '') ? `(${v.note})` : '';
        return [`${v.model} [${v.standard}] ${v.unit} x ${v.count} ${estimate.currencySymbol(currency, v.price)} ${note}`].join(', ');
      }).join(' | '));
    }
    if (doc.address != null) {
      log.push(i18n.t('estimate-address') + ': ' + doc.address.split('\n').join(' '));
    }
    if (doc.phoneNumber != null) {
      log.push(i18n.t('estimate-phone-number') + ': ' + doc.phoneNumber.split('\n').join(' '));
    }
    if (doc.currency != null) {
      log.push(i18n.t('estimate-currency') + ': ' + doc.currency.split('\n').join(' '));
    }
    if (doc.piNumber != null) {
      log.push(i18n.t('estimate-pi-number') + ': ' + doc.piNumber.split('\n').join(' '));
    }

    if (log.length <= 0) return id;

    let logText = log.join('\n');
    if (id) {
      await db.collection('estimates').doc(id).update(doc);
    } else {
      let newRef = await db.collection('estimates').add(doc);
      id = newRef.id;
    }
    await this.log(id, uid, logText);

    return id;
  },

  load: async function (id) {
    let doc = await db.collection('estimates').doc(id).get();
    return doc.data();
  },

  listUrgent: async function (condition) {
    let docs = [];
    let ref;

    ref = db.collection('estimates');
    if (condition.vendor)
      ref = ref.where('vendor', '==', condition.vendor);

    ref = ref.where('state', '==', estimate.ACCEPTED);
    ref = ref.where('acceptedTime', '<=', this.urgentDay());
    ref = ref.orderBy('acceptedTime', 'desc');

    const snapshot = await ref.get();
    snapshot.docs.some((doc) => {
      let data = doc.data();

      data.id = doc.id;
      data.registerTime = data.registerTime.toDate();
      data.acceptedTime = data.acceptedTime.toDate();
      docs.push(data);
    });

    return { docs: docs };
  },

  list: async function (condition) {
    if (condition.query.length > 0) {
      const res = await functions.httpsCallable('estimateSearch')(condition);
      return res.data;
    }

    let docs = [];
    const limit = condition.limit || 20;
    let last = null;

    let ref = await this.dbRef(condition);

    if (condition.last) {
      const docRef = await db.collection('estimates').doc(condition.last).get();
      if (docRef.exists) {
        ref = ref.startAfter(docRef);
      }
    }
    const snapshot = await ref.limit(limit).get();
    snapshot.docs.forEach((doc) => {
      let data = doc.data();

      data.id = doc.id;
      data.registerTime = data.registerTime.toDate();
      data.acceptedTime = data.acceptedTime ? data.acceptedTime.toDate() : '';
      docs.push(data);
    });

    if (docs.length > 0 && docs.length == limit) {
      last = docs[docs.length - 1].id;
    }

    return { docs: docs, last: last };
  },

  dbRef: async function (condition) {
    let ref = db.collection('estimates');
    if (condition.author) ref = ref.where('author', '==', condition.author);
    if (condition.vendor) ref = ref.where('vendor', '==', condition.vendor);
    if (condition.sender) ref = ref.where('sender', '==', condition.sender);
    if (condition.state != estimate.ALL) ref = ref.where('state', '==', condition.state);
    else ref = ref.where('state', 'in', [estimate.REGISTERED, estimate.ACCEPTED]);
    if (condition.duration > 0) {
      switch (condition.duration) {
        case 1: // day
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            until.setDate(until.getDate() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 2: // month
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            until.setDate(1);
            until.setMonth(until.getMonth() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 3: // quarter
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 3) * 3);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 3) + 1) * 3);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 4: // half-year
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 6) * 6);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 6) + 1) * 6);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 5: // year
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(0);
            until.setDate(1);
            until.setMonth(0);
            until.setFullYear(until.getFullYear() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 6: // custom
          {
            let since = new Date(condition.date);
            let until = new Date(condition.untilDate);
            if (since.getTime() > until.getTime()) {
              let tmp = since;
              since = until;
              until = tmp;
            }
            until.setDate(until.getDate() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
      }
    }
    return ref.orderBy('registerTime', 'desc');
  },
  loadStateCounts: async function () {
    let doc = await db.collection('admin').doc('estimate').get();

    return doc.data();
  },
  log: async function (id, uid, text) {
    await db.collection('estimates')
      .doc(id)
      .collection('logs')
      .add({
        time: firebase.firestore.FieldValue.serverTimestamp(),
        uid: uid,
        text: text,
      })
  },
  subscribe: function (id, callback) {
    let first = true;
    return db.collection('estimates').doc(id).onSnapshot((doc) => {
      // Only server change.
      if (!doc.metadata.hasPendingWrites) {
        // The first snapshot change is always called.
        if (first)
          first = false;
        else
          callback(doc.data());
      }
    });
  },
  unsubscribe: function (listener) {
    typeof (listener) === 'function' && listener();
  },
  loadHits: async function (id) {
    let hitLogs = [];
    let docRef = db.collection('estimates').doc(id).collection('hits');
    let snapshot = await docRef.orderBy('time', 'asc').get();

    snapshot.forEach(function (doc) {
      let data = doc.data();
      let user = store.getters.getUser(data.uid);
      let hit = {
        time: data.time ? datefmt.full(data.time.toDate()) : '',
        user: user.name,
        vendor: store.getters.getVendor(user.vendor).name,
      };

      hitLogs.push(hit);
    });
    return hitLogs;
  },
  loadLogs: async function (id) {
    let logs = [];
    let snapshot = await db.collection('estimates').doc(id).collection('logs').orderBy('time').get();
    snapshot.forEach(function (doc) {
      let data = doc.data();
      let user = store.getters.getUser(data.uid);
      let log = {
        time: data.time.toDate(),
        user: user.name,
        userVendor: store.getters.getVendor(user.vendor).name,
        photoURL: user.photoURL,
      };
      let texts = data.text.split('\n');
      if (texts[0] && texts[0].startsWith('state:')) {
        log.state = parseInt(texts[0].split(':')[1]);
        texts.splice(0, 1);
      }
      if (texts[0] && texts[0].startsWith('vendor:')) {
        let vendor = texts[0].split(':')[1];
        log.vendor = store.getters.getVendor(vendor).name;
        texts.splice(0, 1);
      }
      let userLevel = store.getters.getVendor(store.state.user.vendor).level;
      if (userLevel != 'subsidiary' && !store.state.user.admin) {
        texts = texts.filter((text) =>
          !text.startsWith(i18n.t('estimate-private-note') + ':'));
      }

      log.texts = texts;
      logs.push(log);
    });
    return logs;
  },
  print: async function (id, uid, currency) {
    await estimate.log(id, uid, `${i18n.t('action')}: ${i18n.t('estimate-print')}`);
    //functions.useEmulator('localhost', 5000);
    const res = await functions.httpsCallable('estimatePrint2')({
      id: id,
      locale: i18n.locale,
      currency: currency,
    });
    saveAs(product.b64toBlob(res.data.data, 'application/pdf'), res.data.filename);
  },
  printPI: async function (id, uid, currency) {
    await estimate.log(id, uid, `${i18n.t('action')}: ${i18n.t('estimate-print-pi')}`);
    //functions.useEmulator('localhost', 5000);
    const res = await functions.httpsCallable('estimatePrintPI')({
      id: id,
      currency: currency,
    });
    saveAs(product.b64toBlob(res.data.data, 'application/pdf'), res.data.filename);
  },
  currencySymbol: function (currency, cost) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'KRW',
    }).format(cost);
  },
  getPINumber: async function () {
    const res = await functions.httpsCallable('estimateGetPINumber')();

    return res.data;
  },
}
export default estimate;
