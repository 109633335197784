import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';

const config = {
  apiKey: 'AIzaSyDfw4UPaWv8YdmdYihVfFnAJxLVZDOw1nk',
  authDomain: 'hub.emstone.com',
  databaseURL: 'https://emstone-hub.firebaseio.com',
  projectId: 'emstone-hub',
  storageBucket: 'emstone-hub.appspot.com',
  messagingSenderId: '1042803287856',
  appId: '1:1042803287856:web:a9fd0d310f466daf878af5',
  measurementId: 'G-22LRWEW273',
};
firebase.initializeApp(config);
firebase.analytics();

firebase.auth().onAuthStateChanged(function (user) {
  firebase.authCallback && firebase.authCallback(user);
});

export default firebase;
