import naturalCompare from 'string-natural-compare';
import sanitize from 'sanitize-filename';
import { saveAs } from 'file-saver';
import i18n from './i18n';
import datefmt from './datefmt';
import firebase from './firebase';
import store from './store';

const db = firebase.firestore();
const functions = firebase.functions();

function keyName(product) {
  if (product.option)
    return `${product.model} ${product.group.replace(/\s/gm, '')} ${product.category.replace(/\s/gm, '')}`;
  else
    return product.model;
}

let tempSnapshot = undefined;
let currencyNames = ['KRW', 'JPY', 'USD'];
let cachedCurrencies = {};

let settings = {};

const product = {
  init: async function () {
    settings = Object.assign({ categories: {} }, store.state.settings.product);
    await Promise.all(currencyNames.map(async (name) => {
      cachedCurrencies[name] = {
        list: [],
        map: {},
        items: [],
      };
      await product._load();
    }));
  },

  loadSettings: function () {
    return settings;
  },

  saveSettings: async function (productSettings) {
    let s = Object.assign({}, store.state.settings);
    s.product = productSettings;
    await store.dispatch('saveSettings', s);
    settings = productSettings;
  },

  categoryOrdered: function (category, currency) {
    let prefix = settings.categories[currency || 'KRW'][category] || 999;
    return `${prefix} ${category}`;
  },

  modelOrdered: function (product) {
    let prefix = '';
    if (product.eol) {
      prefix = 'zzz';
    } else if (product.option) {
      prefix = 'yyy';
    }
    return `${prefix} ${product.model}`;
  },

  getCellText: (cell) => {
    if (!cell)
      return cell;
    if (!cell.richText)
      return cell.toString().replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ').trim();
    return cell.richText.map(v => v.text).join('').replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ').trim();
  },

  loadExcelFile: async (currency, filename) => {
    let productList = cachedCurrencies[currency || 'KRW'].list;
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = async () => {
        let products = [];

        const ExcelJS = await import(/* webpackChunkName: "exceljs" */ 'exceljs');
        const workbook = new ExcelJS.Workbook();
        const wb = await workbook.xlsx.load(reader.result);
        const sheet = wb.getWorksheet(1);
        sheet.eachRow((row) => {
          const doc = {
            category: product.getCellText(row.values[1]),
            group: product.getCellText(row.values[2]),
            model: product.getCellText(row.values[3]),
            standard: product.getCellText(row.values[4]),
            prices: {
              cost: Number(product.getCellText(row.values[5])) || 0,
              subsidiary: Number(product.getCellText(row.values[6])) || 0,
              branch: Number(product.getCellText(row.values[7])) || 0,
              oem: Number(product.getCellText(row.values[8])) || 0,
              agency: Number(product.getCellText(row.values[9])) || 0,
              company: Number(product.getCellText(row.values[10])) || 0,
              consumer: Number(product.getCellText(row.values[11])) || 0,
            },
            eol: false,
            option: false,
          };
          if (!doc.category ||
            !doc.group ||
            !doc.model ||
            !doc.prices.subsidiary ||
            !doc.prices.branch ||
            !doc.prices.oem ||
            !doc.prices.agency ||
            !doc.prices.company ||
            !doc.prices.consumer)
            return;

          if (doc.model.indexOf('(EOL)') >= 0) {
            doc.eol = true;
            doc.model = doc.model.replace('(EOL)', '').replace(/\s+/g, ' ').trim();
          }
          if (doc.model.indexOf('(Option)') >= 0) {
            doc.option = true;
            doc.model = doc.model.replace('(Option)', '').replace(/\s+/g, ' ').trim();
          }

          doc.changes = {};
          let found = productList.find((item) => keyName(item) == keyName(doc))
          if (found) {
            Object.keys(found).forEach(key => {
              if (key == 'prices') {
                Object.keys(found['prices']).forEach((x) => {
                  if (doc['prices'][x] != found['prices'][x])
                    doc.changes[x] = true;
                });
              } else if (doc[key] != found[key]) {
                doc.changes[key] = true;
              }
            });
          } else {
            doc.changes = { add: true };
          }

          if (Object.keys(doc.changes).length == 0)
            return;

          products.push(doc);
        });
        resolve(products.sort((a, b) => {
          return naturalCompare(
            product.categoryOrdered(a.category, currency || 'KRW'),
            product.categoryOrdered(b.category, currency || 'KRW')
          ) ||
            naturalCompare(a.group, b.group) ||
            naturalCompare(product.modelOrdered(a), product.modelOrdered(b))
        }));

      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(filename);
    });
  },

  remove: async function (model, currency) {
    await db
      .collection('products')
      .doc('currencies')
      .collection(currency)
      .doc(model)
      .delete();

    await store.dispatch('log', {
      module: 'product',
      id: store.state.user.id,
      text: 'remove ' + model,
    });

    await db.collection('products').doc('settings-' + currency).update({
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });

    await product._load();
  },

  save: async function (products, currency) {
    let ref = db
      .collection('products')
      .doc('currencies')
      .collection(currency || 'KRW');
    let modify = '';
    let promises = [];
    products.forEach((product) => {
      const doc = {
        category: product.category || '',
        group: product.group || '',
        model: product.model,
        standard: product.standard || '',
        prices: {
          cost: product.prices.cost || 0,
          subsidiary: product.prices.subsidiary || 0,
          branch: product.prices.branch || 0,
          oem: product.prices.oem || 0,
          agency: product.prices.agency || 0,
          company: product.prices.company || 0,
          consumer: product.prices.consumer || 0,
        },
        eol: product.eol || false,
        option: product.option || false,
        hidden: product.hidden || false,
      };
      if (product.changes.add)
        modify = 'add ';
      else
        modify = 'update ';

      let docPromise = ref.doc(keyName(doc)).set(doc);
      let logPromise = store.dispatch('log', {
        module: 'product',
        id: store.state.user.id,
        text:
          modify +
          [
            doc.category,
            doc.group,
            doc.model,
            doc.standard,
            doc.prices.cost,
            doc.prices.subsidiary,
            doc.prices.branch,
            doc.prices.oem,
            doc.prices.agency,
            doc.prices.company,
            doc.prices.consumer,
            doc.eol,
            doc.option,
            doc.hidden,
          ].join(' | '),
      });
      promises.push(docPromise);
      promises.push(logPromise);
    });
    await Promise.all(promises);
    await db.collection('products').doc('settings-' + currency).update({
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await product._load();
  },

  saveOne: async function (product, currency) {
    let ref = db
      .collection('products')
      .doc('currencies')
      .collection(currency || 'KRW');

    let modify = '';
    const doc = {
      category: product.category.trim() || '',
      group: product.group.trim() || '',
      model: product.model.trim(),
      standard: product.standard.trim() || '',
      prices: {
        cost: product.prices.cost || 0,
        subsidiary: product.prices.subsidiary || 0,
        branch: product.prices.branch || 0,
        oem: product.prices.oem || 0,
        agency: product.prices.agency || 0,
        company: product.prices.company || 0,
        consumer: product.prices.consumer || 0,
      },
      eol: product.eol || false,
      option: product.option || false,
      hidden: product.hidden || false,
    };
    if (product.changes.add)
      modify = 'add ';
    else
      modify = 'update ';

    await ref.doc(keyName(doc)).set(doc);
    await store.dispatch('log', {
      module: 'product',
      id: store.state.user.id,
      text:
        modify +
        [
          doc.category,
          doc.group,
          doc.model,
          doc.standard,
          doc.prices.cost,
          doc.prices.subsidiary,
          doc.prices.branch,
          doc.prices.oem,
          doc.prices.agency,
          doc.prices.company,
          doc.prices.consumer,
          doc.eol,
          doc.option,
          doc.hidden,
        ].join(' | '),
    });
    await db.collection('products').doc('settings-' + currency).update({
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });

    await this._load();
  },

  list: function (currency) {
    return cachedCurrencies[currency || 'KRW'].list;
  },

  _fetch: async function (currnecy) {
    tempSnapshot = await db
      .collection('products')
      .doc('currencies')
      .collection(currnecy)
      .get();
  },

  _setup: function (currency) {
    let products = [];
    let maps = {};
    tempSnapshot.forEach((row) => {
      let product = row.data();
      const doc = {
        category: product.category,
        group: product.group,
        model: product.model,
        standard: product.standard,
        prices: {
          cost: Number(product.prices.cost) || 0,
          subsidiary: Number(product.prices.subsidiary) || 0,
          branch: Number(product.prices.branch) || 0,
          oem: Number(product.prices.oem) || 0,
          agency: Number(product.prices.agency) || 0,
          company: Number(product.prices.company) || 0,
          consumer: Number(product.prices.consumer) || 0,
        },
        eol: product.eol,
        option: product.option,
        hidden: product.hidden || false,
      }
      products.push(doc);
      maps[product.model] = doc;
    });
    cachedCurrencies[currency || 'KRW'].list = products.sort((a, b) => {
      return naturalCompare(
        product.categoryOrdered(a.category, currency || 'KRW'),
        product.categoryOrdered(b.category, currency || 'KRW')
      ) ||
        naturalCompare(a.group, b.group) ||
        naturalCompare(product.modelOrdered(a), product.modelOrdered(b))
    });
    cachedCurrencies[currency || 'KRW'].map = maps;
    cachedCurrencies[currency || 'KRW'].items = products.map((doc) => {
      let item = {
        value: doc.model,
        text: `${doc.model} | ${doc.category} | ${doc.group}`,
        disabled: doc.eol,
        model: doc.model,
        category: doc.category,
        group: doc.group,
        standard: doc.standard,
        prices: doc.prices,
      };
      return item;
    });
    tempSnapshot = null;
  },

  _load: async function () {
    Promise.all(currencyNames.map(async (name) => {
      await product._fetch(name);
      product._setup(name);
    }));
  },

  loadItems: function (currency) {
    return cachedCurrencies[currency || 'KRW'].items;
  },

  lookupModel: function (doc, currency) {
    return cachedCurrencies[currency || 'KRW'].map[doc] || { prices: {} };
  },

  exportXLSX: async function (products, columns, currency) {
    let res = confirm(i18n.t('service-xlsx-export-confirm'));
    if (!res) return;
    const headerLine = 5;
    let preCategory = '';
    let preGroup = '';
    let startCategoryCount = headerLine + 1;
    let endCategoryCount = 0;
    let startGroupCount = headerLine + 1;
    let endGroupCount = 0;
    let groupCount = 0;

    const ExcelJS = await import(/* webpackChunkName: "exceljs" */ 'exceljs');
    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet('Sheet1');

    let productTitle = workSheet.getCell('D1');
    productTitle.value = i18n.t('product-list-title');

    workSheet.mergeCells('D1:E4');

    let dayCell = workSheet.getCell('A4');
    dayCell.value = datefmt.date(await product.getUpdateTime());

    workSheet.mergeCells('A4:B4');
    let headerRow = [
      i18n.t('product-category'),
      i18n.t('product-group'),
      i18n.t('product-model'),
      i18n.t('product-standard')
    ]

    if (columns.cost) headerRow.push(i18n.t('product-price-cost'));
    if (columns.subsidiary) headerRow.push(i18n.t('product-price-subsidiary'));
    if (columns.branch) headerRow.push(i18n.t('product-price-branch'));
    if (columns.oem) headerRow.push(i18n.t('product-price-oem'));
    if (columns.agency) headerRow.push(i18n.t('product-price-agency'));
    if (columns.company) headerRow.push(i18n.t('product-price-company'));
    if (columns.consumer) headerRow.push(i18n.t('product-price-consumer'));

    workSheet.addRow(headerRow);

    products.forEach((product, index) => {
      let model = product.model;
      if (product.eol)
        model = `(EOL)${model}`;
      if (product.option)
        model = `(Option)${model}`;
      let row = [product.category, product.group, model, product.standard]

      if (columns.cost) row.push(this.getCurrencySymbol(product.prices.cost, currency));
      if (columns.subsidiary) row.push(this.getCurrencySymbol(product.prices.subsidiary, currency));
      if (columns.branch) row.push(this.getCurrencySymbol(product.prices.branch, currency));
      if (columns.oem) row.push(this.getCurrencySymbol(product.prices.oem, currency));
      if (columns.agency) row.push(this.getCurrencySymbol(product.prices.agency, currency));
      if (columns.company) row.push(this.getCurrencySymbol(product.prices.company, currency));
      if (columns.consumer) row.push(this.getCurrencySymbol(product.prices.consumer, currency));

      workSheet.addRow(row);
      if (index == 0) {
        preCategory = product.category;
        preGroup = product.group;
      }

      if (preCategory != product.category || index + 1 == products.length) {
        if (index + 1 == products.length)
          endCategoryCount = products.length + headerLine;
        else
          endCategoryCount = headerLine + index;

        workSheet.mergeCells(
          `A${startCategoryCount}:A${endCategoryCount}`
        );
        preCategory = product.category;
        startCategoryCount = endCategoryCount + 1;
      }

      if (preGroup != product.group || index + 1 == products.length) {
        if (index + 1 == products.length)
          endGroupCount = products.length + headerLine;
        else
          endGroupCount = headerLine + index;

        workSheet.mergeCells(
          `B${startGroupCount}:B${endGroupCount}`
        );
        if (groupCount % 2 == 0) {
          for (let i = startGroupCount; startGroupCount <= endGroupCount; startGroupCount++)
            workSheet.getRow(i).eachCell(function (col, colIndex) {
              if (colIndex < 2) return;
              workSheet.getRow(startGroupCount).getCell(colIndex).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '11B4C7E7' },
              }
            });
        }
        groupCount++;
        preGroup = product.group;
        startGroupCount = endGroupCount + 1;
      }

      if (product.eol) {
        workSheet.getRow(headerLine + index + 1).getCell(3).font = {
          color: { argb: 'FFFF0000' },
          strike: true,
        }
        workSheet.getRow(headerLine + index + 1).getCell(4).font = {
          color: { argb: 'FFFF0000' },
          strike: true,
        }
      }
    });

    workSheet.columns.forEach(function (column, index) {
      let maxLength = 0;
      column['eachCell']({ includeEmpty: true }, function (cell) {
        let columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
      if (index == 0 || index == 1) {
        column.alignment = {
          horizontal: 'center',
          vertical: 'middle'
        };
      }
      if (index > 3)
        workSheet.getColumn(index + 1).numFmt = '#,##';
    });

    workSheet.eachRow({ includeEmpty: false }, function (row, rowIndex) {
      row.eachCell({ includeEmpty: false }, function (col, colIndex) {
        col.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if (colIndex == 6 || colIndex == 9) {
          workSheet.getRow(rowIndex).getCell(colIndex).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '11FFFF00' },
          }
        }
        if (rowIndex == headerLine)
          workSheet.getRow(headerLine).getCell(colIndex).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '77D0CECE' },
          };
      });
    });

    workSheet.getRow(headerLine).alignment = { horizontal: 'center' };
    productTitle.font = { size: 30, }
    productTitle.alignment = { vertical: 'middle', horizontal: 'center', };
    dayCell.alignment = { vertical: 'middle', horizontal: 'center', };
    let buffer = await workbook.xlsx.writeBuffer();
    let blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let filename = sanitize(`${i18n.t('products')} ${datefmt.full(new Date())}.xlsx`);
    saveAs(blob, filename);
  },

  getCurrencySymbol: function (val, currency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'KRW',
    }).format(val);
  },

  getUpdateTime: async function (currency) {
    let name = `settings-${currency || 'KRW'}`;
    let doc = await db
      .collection('products')
      .doc(name)
      .get();
    return doc.data().updateTime.toDate();
  },

  b64toBlob: function (b64Data, contentType) {
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  },

  print: async function (products, visibleColumns, currency) {
    //functions.useEmulator('localhost', 5000);
    const res = await functions.httpsCallable('productPrint')({
      products: products,
      visibleColumns: visibleColumns,
      locale: i18n.locale,
      currency: currency,
    });
    saveAs(product.b64toBlob(res.data.data, 'application/pdf'), res.data.filename);
  },
};

export default product;
