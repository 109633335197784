<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="products"
    :search="search"
    :enableColumns="enableColumns"
    @load="currency"
    :items-per-page="itemsPerPage"
    :footer-props="{
      itemsPerPageOptions: [10, 20, 50, 100, -1],
      itemsPerPageText: $t('items-per-page'),
    }"
    @pagination="paginationChanged"
    mobile-breakpoint="0"
    class="product-table"
  >
    <template slot="top">
      <v-row>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="search"
            :prepend-inner-icon="$svg('mdi-magnify')"
            :append-outer-icon="$svg('mdi-format-list-bulleted-square')"
            @click:append-outer="selectColumns"
            :label="$t('search')"
          ></v-text-field>
          <template>
            <v-dialog v-model="columnsDialog" max-width="400">
              <v-card flat>
                <v-card-title
                  >{{ $t('product-columns-dialog-title') }}
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="4" v-if="enableColumns.cost">
                        <v-checkbox
                          v-model="dialogColumns.cost"
                          :label="$t('product-price-cost')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="4" v-if="enableColumns.subsidiary">
                        <v-checkbox
                          v-model="dialogColumns.subsidiary"
                          :label="$t('product-price-subsidiary')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="4" v-if="enableColumns.branch">
                        <v-checkbox
                          v-model="dialogColumns.branch"
                          :label="$t('product-price-branch')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="4" v-if="enableColumns.oem">
                        <v-checkbox
                          v-model="dialogColumns.oem"
                          :label="$t('product-price-oem')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="4" v-if="enableColumns.agency">
                        <v-checkbox
                          v-model="dialogColumns.agency"
                          :label="$t('product-price-agency')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="4" v-if="enableColumns.company">
                        <v-checkbox
                          v-model="dialogColumns.company"
                          :label="$t('product-price-company')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="4" v-if="enableColumns.consumer">
                        <v-checkbox
                          v-model="dialogColumns.consumer"
                          :label="$t('product-price-consumer')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" v-if="isAdmin">
                        <v-checkbox
                          v-model="dialogColumns.showHiddenItems"
                          :label="$t('product-show-hidden-items')"
                          hide-details
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="columnsDialog = false">{{
                      $t('cancel')
                    }}</v-btn>
                    <v-btn color="primary" @click="saveColumns">{{
                      $t('ok')
                    }}</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          v-if="updateTime"
          class="text-right mb-4 font-italic"
          align-self="center"
        >
          {{ $t('product-update-time') }}: {{ updateTime | readableDate }}
        </v-col>
      </v-row>
    </template>
    <template slot="item" slot-scope="props">
      <tr :class="props.item.rowClass">
        <td
          class="hidden-xs-only"
          :class="{
            'red--text': props.item.changes.category || props.item.changes.add,
          }"
        >
          {{ props.item.category }}
        </td>
        <td
          class="hidden-xs-only"
          :class="{
            'red--text': props.item.changes.group || props.item.changes.add,
          }"
        >
          {{ props.item.group }}
        </td>
        <td
          nowrap
          class="font-weight-medium"
          :class="{
            'red--text':
              props.item.changes.model ||
              props.item.changes.eol ||
              props.item.changes.add,
            'text-decoration-line-through red--text': props.item.eol,
          }"
        >
          {{ props.item.model }}
          <span color="text--purple" v-if="props.item.option">◇</span>
        </td>
        <td
          class="hidden-xs-only"
          :class="{
            'red--text': props.item.changes.standard || props.item.changes.add,
            'text-decoration-line-through red--text': props.item.eol,
          }"
        >
          {{ props.item.standard }}
        </td>
        <td
          nowrap
          class="text-right"
          :class="{
            'red--text': props.item.changes.cost || props.item.changes.add,
          }"
          v-if="visibleColumns.cost"
        >
          {{ props.item.prices.cost | transNumber(currency) }}
        </td>
        <td
          nowrap
          class="text-right"
          :class="{
            'red--text':
              props.item.changes.subsidiary || props.item.changes.add,
          }"
          v-if="visibleColumns.subsidiary"
        >
          {{ props.item.prices.subsidiary | transNumber(currency) }}
        </td>
        <td
          nowrap
          class="text-right product-price-branch"
          :class="{
            'red--text': props.item.changes.branch || props.item.changes.add,
          }"
          v-if="visibleColumns.branch"
        >
          {{ props.item.prices.branch | transNumber(currency) }}
        </td>
        <td
          nowrap
          class="text-right"
          :class="{
            'red--text': props.item.changes.oem || props.item.changes.add,
          }"
          v-if="visibleColumns.oem"
        >
          {{ props.item.prices.oem | transNumber(currency) }}
        </td>
        <td
          nowrap
          class="text-right"
          :class="{
            'red--text': props.item.changes.agency || props.item.changes.add,
          }"
          v-if="visibleColumns.agency"
        >
          {{ props.item.prices.agency | transNumber(currency) }}
        </td>
        <td
          nowrap
          class="text-right product-price-company"
          :class="{
            'red--text': props.item.changes.company || props.item.changes.add,
          }"
          v-if="visibleColumns.company"
        >
          {{ props.item.prices.company | transNumber(currency) }}
        </td>
        <td
          nowrap
          class="text-right"
          :class="{
            'red--text': props.item.changes.consumer || props.item.changes.add,
          }"
          v-if="visibleColumns.consumer"
        >
          {{ props.item.prices.consumer | transNumber(currency) }}
        </td>
        <td nowrap v-if="isEdit">
          <v-btn icon @click="rowClick(props.item)">
            <v-icon>{{ $svg('mdi-pencil') }}</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n';
import datefmt from '@/datefmt';

export default {
  name: 'product-table',
  props: {
    items: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enableColumns: {
      type: Object,
      default: () => {
        return {
          cost: true,
          subsidiary: true,
          branch: true,
          oem: true,
          agency: true,
          company: true,
          consumer: true,
          showHiddenItems: false,
        };
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    updateTime: {
      type: Date,
      default: null,
    },
    showEOL: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: 'KRW',
    },
  },
  mounted() {
    this.visibleColumns = Object.assign({}, this.enableColumns, {
      subsidiary: false,
    });
  },
  methods: {
    paginationChanged(p) {
      if (p.itemsPerPage != this.itemsPerPage)
        localStorage?.setItem('ProductTable.itemsPerPage', p.itemsPerPage);
    },
    selectColumns: function () {
      this.dialogColumns = Object.assign({}, this.visibleColumns);
      this.columnsDialog = true;
    },
    saveColumns: function () {
      this.visibleColumns = this.dialogColumns;
      this.$emit('changeColumns', this.visibleColumns);
      this.columnsDialog = false;
    },
    rowClick: function (val) {
      if (this.$props.isEdit) this.$emit('rowClick', val);
    },
  },
  computed: {
    isAdmin: function () {
      return this.$store.state.user.admin;
    },
    products: function () {
      let products = [];

      const groupClass =
        'product-group--' + (this.$vuetify.theme.dark ? 'dark' : 'light');
      let group_n = 0;
      let group = null;
      this.items.forEach((item) => {
        if (!this.showEOL && item.eol) return;
        let product = Object.assign({}, item);
        if (!product.changes) product.changes = {};
        if (!group || group != product.group) {
          group = product.group;
          group_n++;
        }
        if (group_n % 2 == 1) {
          product.rowClass = groupClass;
        }
        products.push(product);
      });
      return products;
    },
    headers: function () {
      return [
        {
          text: i18n.t('product-category'),
          value: 'category',
          class: 'text-no-wrap hidden-xs-only',
          align: 'start',
        },
        {
          text: i18n.t('product-group'),
          value: 'group',
          class: 'text-no-wrap hidden-xs-only',
          align: 'start',
        },
        {
          text: i18n.t('product-model'),
          value: 'model',
          class: 'text-no-wrap',
          align: 'start',
        },
        {
          text: i18n.t('product-standard'),
          value: 'standard',
          class: 'text-no-wrap hidden-xs-only',
          align: 'start',
        },
        {
          text: i18n.t('product-price-cost'),
          value: 'cost',
          class: this.visibleColumns.cost ? 'text-no-wrap' : 'd-none',
          align: 'start',
        },
        {
          text: i18n.t('product-price-subsidiary'),
          value: 'price_subsidiary',
          class: this.visibleColumns.subsidiary ? 'text-no-wrap' : 'd-none',
          align: 'end',
        },
        {
          text: i18n.t('product-price-branch'),
          value: 'price_branch',
          class: this.visibleColumns.branch
            ? 'text-no-wrap product-price-branch'
            : 'd-none',
          align: 'end',
        },
        {
          text: i18n.t('product-price-oem'),
          value: 'price_oem',
          class: this.visibleColumns.oem ? 'text-no-wrap' : 'd-none',
          align: 'end',
        },
        {
          text: i18n.t('product-price-agency'),
          value: 'price_agency',
          class: this.visibleColumns.agency ? 'text-no-wrap' : 'd-none',
          align: 'end',
        },
        {
          text: i18n.t('product-price-company'),
          value: 'price_company',
          class: this.visibleColumns.company
            ? 'text-no-wrap product-price-company'
            : 'd-none',
          align: 'end',
        },
        {
          text: i18n.t('product-price-consumer'),
          value: 'price_consumer',
          class: this.visibleColumns.consumer ? 'text-no-wrap' : 'd-none',
          align: 'end',
        },
        {
          text: '',
          value: '',
          class: this.isEdit ? '' : 'd-none',
          sortable: false,
        },
      ];
    },
  },
  data: () => ({
    search: '',
    columnsDialog: false,
    visibleColumns: {},
    dialogColumns: {},
    itemsPerPage: Number(
      localStorage?.getItem('ProductTable.itemsPerPage') ?? '10'
    ),
  }),
  filters: {
    readableDate: function (date) {
      return datefmt.readable(date);
    },
    transNumber: function (cost, currency) {
      if (currency == '') return cost;

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(cost);
    },
  },
};
</script>

<style>
.product-group--light {
  background-color: rgba(200, 200, 200, 0.2);
}

.product-group--dark {
  background-color: rgba(200, 200, 200, 0.2);
}

.product-price-branch {
  background-color: rgba(241, 181, 0, 0.1);
}

.product-price-company {
  background-color: rgba(241, 181, 0, 0.1);
}

.product-table th,
.product-table td {
  word-break: keep-all;
  word-wrap: break-word;
}
</style>
