<template>
  <v-card tile>
    <v-snackbar :timeout="-1" v-model="printing">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      {{ $t('product-printing-text') }}
    </v-snackbar>
    <loading :value="printing"></loading>
    <v-col>
        <!-- test code -->
        <v-btn-toggle
          v-if="currencies.length > 1"
          v-model="currency"
          mandatory
        >
          <v-btn 
            v-for="item in currencies"
            :key="item"
            :value="item"
          >{{ item }}
          </v-btn>
        </v-btn-toggle>
    </v-col>
    <v-card-text>
      <product-table
        :loading="loading"
        :items="products"
        :enableColumns="enableColumns"
        :updateTime="updateTime"
        :showEOL="showEOL"
        :isEdit="isAdmin"
        :currency="currency"
        @changeColumns="changeColumns"
        @rowClick="openEditDialog"
        ref="productTable"
      >
      </product-table>
      <template>
        <v-dialog v-model="columnsDialog" max-width="400">
          <v-card flat>
            <v-card-title>{{ $t('product-print-dialog-title') }} </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="4" v-if="enableColumns.cost">
                    <v-checkbox
                      v-model="printColumns.cost"
                      :label="$t('product-price-cost')"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="enableColumns.subsidiary">
                    <v-checkbox
                      v-model="printColumns.subsidiary"
                      :label="$t('product-price-subsidiary')"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="enableColumns.branch">
                    <v-checkbox
                      v-model="printColumns.branch"
                      :label="$t('product-price-branch')"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="enableColumns.oem">
                    <v-checkbox
                      v-model="printColumns.oem"
                      :label="$t('product-price-oem')"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="enableColumns.agency">
                    <v-checkbox
                      v-model="printColumns.agency"
                      :label="$t('product-price-agency')"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="enableColumns.company">
                    <v-checkbox
                      v-model="printColumns.company"
                      :label="$t('product-price-company')"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="enableColumns.consumer">
                    <v-checkbox
                      v-model="printColumns.consumer"
                      :label="$t('product-price-consumer')"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="columnsDialog = false">{{ $t('cancel') }}</v-btn>
                <v-btn color="primary" @click="print">{{
                  $t('product-print')
                }}</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog v-model="editDialog" width="600">
        <template v-slot:activator="{ on }" v-if="isAdmin">
          <v-btn
            color="primary"
            fab
            fixed
            bottom
            right
            exact
            v-on="on"
            @click="openEditDialog(null)"
          >
            <v-icon>{{ $svg('mdi-plus') }}</v-icon>
          </v-btn>
        </template>
        <v-form v-model="valid">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-combobox
                      v-model="product.category"
                      :label="$t('product-category')"
                      :items="categoryItems"
                      @change="categoryChanged"
                      :rules="[
                        function (v) {
                          return (
                            v.trim().length > 0 || $t('product-hint-category')
                          );
                        },
                      ]"
                      required
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-combobox
                      v-model="product.group"
                      :label="$t('product-group')"
                      :items="groupItems"
                      @change="groupChanged"
                      :rules="[
                        function (v) {
                          return (
                            v.trim().length > 0 || $t('product-hint-group')
                          );
                        },
                      ]"
                      required
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="product.model"
                      :label="$t('product-model')"
                      :readonly="dialogState == 'edit'"
                      :rules="[
                        function (v) {
                          return (
                            v.trim().length > 0 || $t('product-hint-model')
                          );
                        },
                        checkDuplicateModel,
                      ]"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="false" sm="2">
                    <v-checkbox
                      v-model="product.option"
                      :label="$t('product-option')"
                      @click="optionClicked"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <v-checkbox
                      v-model="product.eol"
                      :label="$t('product-eol')"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      v-model="product.hidden"
                      :label="$t('product-item-hidden')"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="product.standard"
                      :label="$t('product-standard')"
                      :rules="[
                        function (v) {
                          return (
                            v.trim().length > 0 || $t('product-hint-standard')
                          );
                        },
                      ]"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div class="text-subtitle-1">
                      {{ $t('product-price') }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-currency-field
                      v-model="product.prices.cost"
                      :label="$t('product-price-cost')"
                      :readonly="false"
                      :currency="currency"
                    >
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-currency-field
                      v-model="product.prices.subsidiary"
                      :label="$t('product-price-subsidiary')"
                      :readonly="false"
                      :currency="currency"
                    >
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-currency-field
                      v-model="product.prices.branch"
                      :label="$t('product-price-branch')"
                      :readonly="false"
                      :currency="currency"
                    >
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-currency-field
                      v-model="product.prices.oem"
                      :label="$t('product-price-oem')"
                      :readonly="false"
                      :currency="currency"
                    >
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-currency-field
                      v-model="product.prices.agency"
                      :label="$t('product-price-agency')"
                      :readonly="false"
                      :currency="currency"
                    >
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-currency-field
                      v-model="product.prices.company"
                      :label="$t('product-price-company')"
                      :readonly="false"
                      :currency="currency"
                    >
                    </v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-currency-field
                      v-model="product.prices.consumer"
                      :label="$t('product-price-consumer')"
                      :readonly="false"
                      :currency="currency"
                    >
                    </v-currency-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    v-if="dialogState=='edit'"
                    color="error"
                    @click="remove"
                    :loading="updating"
                    >{{ $t ('delete') }}</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn @click="closeEditDialog">{{ $t('cancel') }}</v-btn>
                  <v-btn
                    color="primary"
                    @click="update"
                    :disabled="!valid"
                    :loading="updating"
                    >{{ editDialogButtonText }}</v-btn
                  >
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import product from '@/product';
import ProductTable from './ProductTable';
import Loading from './Loading';
import i18n from '@/i18n';
import VCurrencyField from './VCurrencyField.vue';

export default {
  name: 'products',
  components: {
    'loading': Loading,
    'product-table': ProductTable,
    'v-currency-field': VCurrencyField,
  },
  data: () => ({
    loading: false,
    updating: false,
    printing: false,
    products: [],
    updateTime: null,
    printColumns: {},
    categoryItems: [],
    groupItems: [],
    modelItems: [],
    columnsDialog: false,
    editDialog: false,
    product: {},
    defaultProduct: {
      category: '',
      group: '',
      model: '',
      standard: '',
      prices: {
        cost: 0,
        subsidiary: 0,
        branch: 0,
        oem: 0,
        agency: 0,
        company: 0,
        consumer: 0,
      },
      option: false,
      eol: false,
      hidden: false,
      changes: {},
    },
    initEditProduct: {
      category: '',
      group: '',
      model: '',
      standard: '',
      prices: {
        cost: -1,
        subsidiary: -1,
        branch: -1,
        oem: -1,
        agency: -1,
        company: -1,
        consumer: -1,
      },
      option: false,
      eol: false,
      hidden: false,
      changes: {},
    },
    valid: false,
    dialogState: '',
    showHiddenItems: false,
    currency: '',
    currencies: [],
  }),
  computed: {
    level: function () {
      return this.$store.state.vendors[this.$store.state.user.vendor].level;
    },
    enableColumns: function () {
      let columns = {
        admin: {
          cost: true,
          subsidiary: true,
          branch: true,
          oem: true,
          agency: true,
          company: true,
          consumer: true,
        },
        subsidiary: {
          subsidiary: true,
          branch: true,
          oem: true,
          agency: true,
          company: true,
          consumer: true,
        },
        branch: {
          branch: true,
          oem: true,
          agency: true,
          company: true,
          consumer: true,
        },
        oem: {
          oem: true,
          agency: true,
          company: true,
          consumer: true,
        },
        agency: {
          agency: true,
          company: true,
          consumer: true,
        },
        company: {
          company: true,
          consumer: true,
        },
        consumer: {
          consumer: true,
        },
      };
      return columns[this.admin ? 'admin' : this.level] || {};
    },
    showEOL: function () {
      return this.$store.state.settings.product.showEOL;
    },
    editDialogButtonText() {
      return this.dialogState == 'edit'
        ? i18n.t('product-update')
        : i18n.t('product-add');
    },
    isAdmin: function () {
      return this.$store.state.user.admin;
    },
    exportXLSXVisible: function () {
      let settingLevel = this.$store.state.settings.product.exportXLSXLevel;
      const levels = {
        admin: 1,
        subsidiary: 2,
        branch: 3,
        oem: 4,
        agency: 5,
        company: 6,
      };
      return this.isAdmin
        ? true
        : (levels[this.level] || 99) <= (levels[settingLevel] || 2);
    },
  },
  created() {
    this.product = JSON.parse(JSON.stringify(this.defaultProduct));
  },
  async mounted() {
    this.isMounted = true;
    this.loading = true;
    this.updateTime = await product.getUpdateTime();
    this.load();
    this.product = JSON.parse(JSON.stringify(this.defaultProduct));
    this.modelItems = await this.getModels();
    this.currencies = this.vendorCurrencies();
    this.currency = this.currencies[0] || 'KRW';
    this.loading = false;
    this.changeColumns(this.enableColumns);
    this.categoryChanged();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('setButtons', [
        {
          icon: 'mdi-download',
          text: vm.$t('product-xlsx-export'),
          click: vm.exportXLSX,
          visible: vm.exportXLSXVisible,
        },
        {
          icon: 'mdi-printer',
          text: vm.$t('product-print'),
          click: vm.selectColumns,
        },
      ]);
    });
  },

  beforeRouteLeave(to, from, next) {
    next();
    this.$store.commit('clearButtons');
  },

  watch: {
    currency: async function () {
      this.updateTime = await product.getUpdateTime(this.currency);
      this.load();
    },
  },

  methods: {
    load: function () {
      const products = product.list(this.currency);
      if (!this.showHiddenItems)
        this.products = products.filter((item) => item.hidden == false);
      else this.products = products;
    },
    exportXLSX: function () {
      product.exportXLSX(this.products, this.enableColumns, this.currency);
    },
    selectColumns: function () {
      this.columnsDialog = true;
    },
    print: async function () {
      let products = undefined;
      if (this.$refs.productTable.$children[0].$children[0]) {
        const v = this.$refs.productTable.$children[0].$children[0];
        products = v.computedItems;
      }
      if (!products) products = this.products;
      this.columnsDialog = false;

      this.printing = true;
      await product.print(products, this.printColumns, this.currency);                                    
      this.printing = false;
    },
    changeColumns: async function (changeColumns) {
      this.printColumns = Object.assign({}, changeColumns, {
        cost: false,
      });
      this.printColumns[this.level] = false;
      this.showHiddenItems = changeColumns.showHiddenItems;
      this.load();
    },
    openEditDialog: function (value) {
      let self = this;
      self.categoryItems = self.getCategories();
      if (value) {
        self.dialogState = 'edit';
        self.product = JSON.parse(JSON.stringify(value));
      } else {
        self.dialogState = 'add';
        self.product = JSON.parse(JSON.stringify(self.defaultProduct));
      }
      self.groupItems = self.getGroupsForCategory(
        this.products,
        this.product.category
      );
      self.editDialog = true;
    },
    closeEditDialog: function () {
      let self = this;
      self.editDialog = false;
      self.dialogState = '';
      self.product = JSON.parse(JSON.stringify(self.initEditProduct));
    },
    getGroups: function () {
      if (this.product.category == '') {
        if (this.product.group != '')
          this.product.category = this.getCategoryForGroup(
            this.products,
            this.product.group
          );
      } else {
        this.groupItems = this.getGroupsForCategory(
          this.products,
          this.product.category
        );
      }
    },
    categoryChanged: function () {
      this.categoryItems = this.getCategories();
      this.getGroups();
      this.product.group = '';
    },
    groupChanged: function () {
      this.getGroups();
      this.product.model = '';
    },
    remove: async function () {
      let self = this;
      let res = confirm('[' + self.product.model +']' + i18n.t('product-remove-confirm'));
      if (!res) return;

      self.updating = true;
      await product.remove(self.product.model, self.currency);
      this.updateTime = await product.getUpdateTime(self.currency);
      this.load();
      self.updating = false;
      this.closeEditDialog();

    },
    update: async function () {
      let self = this;
      self.updating = true;
      if (self.product.category.length <= 0) return;
      if (!self.categoryItems.includes(self.product.category)) {
        let settings = product.loadSettings();
        let maxValue = Object.values(settings['categories'][self.currency]).sort(
          (v1, v2) => v1 - v2
        )[Object.values(settings['categories'][self.currency]).length - 1];
        settings['categories'][self.currency][self.product.category] = maxValue + 10;

        await product.saveSettings(settings);
      }

      if (self.dialogState == 'add') self.product.changes.add = true;
      else self.product.changes.add = false;

      await product.saveOne(self.product, self.currency);
      this.updateTime = await product.getUpdateTime(self.currency);
      this.load();
      self.updating = false;
      this.closeEditDialog();
    },
    checkDuplicateModel: function (v) {
      if (this.dialogState == 'edit') return true;
      if (this.product.option) return true;
      return (
        !this.modelItems.includes(v.toUpperCase()) ||
        i18n.t('product-hint-model-same')
      );
    },
    optionClicked: function () {
      let tmp = this.product.model;
      setTimeout(() => {
        this.product.model = '';
      }, 100);
      setTimeout(() => {
        this.product.model = tmp;
      }, 100);
    },
    getCategories: function () {
      return Object.entries(product.loadSettings().categories[this.currency])
        .sort(([, a], [, b]) => a - b)
        .map((product) => product[0]);
    },
    getGroupsForCategory: function (products, category) {
      let groups = [];
      if (category != '')
        groups = products
          .filter((product) => product.category == category)
          .sort()
          .map((p) => p.group);
      else groups = products.map((product) => product.group).sort();

      return groups.sort();
    },
    getCategoryForGroup: function (products, group) {
      return products.find((product) => product.group == group).category;
    },
    getModels: function () {
      let models = [];
      this.products.forEach((product) => {
        if (!models.includes(product.model)) {
          models.push(product.model);
        }
      });
      return models;
    },
    vendorCurrencies: function () {
      return this
            .$store
            .getters
            .getVendor(this.$store.state.user.vendor)
            .currencies || ['KRW'];
    },
  },
};
</script>
