<template>
  <v-container>
    <v-snackbar v-model="alerting">
      {{ alertText }}
    </v-snackbar>
    <v-snackbar :timeout="-1" v-model="printing">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      {{ $t('estimate-printing-text') }}
    </v-snackbar>
    <loading :value="updating"></loading>
    <v-form v-model="valid">
      <v-card>
        <v-card-title>
          <v-chip v-if="acceptedTime" label outlined color="success">
            {{ readableTime(acceptedTime) }}
          </v-chip>
          <estimate-state :state="state"></estimate-state>
          <v-avatar size="32" v-if="false">
            <v-img :src="authorPhotoURL" v-if="authorPhotoURL"></v-img>
          </v-avatar>
          <v-chip v-if="false" label outlined>
            {{ authorName }}
          </v-chip>
          <v-chip label outlined v-show="!vendorEditing" @click="editVendor">
            {{ vendorName }}
          </v-chip>
          <v-autocomplete
            v-model="vendor"
            :items="$store.state.vendorItems"
            hide-no-data
            hide-details
            auto-select-first
            :label="$t('vendor')"
            solo
            v-show="canEditVendor && vendorEditing"
            @blur="vendorEditing = false"
            ref="vendorEntry"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.label }}
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="hitDialog"
            scrollable
            max-width="400px"
            :disabled="!permission('estimate-edit')"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-if="permission('estimate-edit') && !isNew"
                small
                v-on="on"
              >
                {{ hitLogs.length }}
              </v-chip>
            </template>
            <v-card>
              <v-card-title primary-title class="text-h5">
                {{ $t('estimate-hits') }}
              </v-card-title>
              <v-card-text>
                <p v-for="(hit, index) in hitLogs" :key="index" class="mb-0">
                  <span class="text-caption">{{ hit.time }}</span>
                  <span class="separator px-2">|</span>
                  <span class="text-body-2">{{ hit.user }}</span>
                  <span class="separator px-2">|</span>
                  <span class="text-caption">{{ hit.vendor }}</span>
                </p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="hitDialog = false">
                  {{ $t('close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-show="canRestore"
                :disabled="updating"
                @click.prevent="restore"
                v-on="on"
                class="mx-0"
              >
                <v-icon>{{ $svg('mdi-restore') }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('estimate-restore-draft') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-show="canRemove"
                :disabled="updating"
                @click.prevent="remove"
                v-on="on"
                class="mx-0"
              >
                <v-icon>{{ $svg('mdi-delete') }} </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="business"
                :label="$t('estimate-business')"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('estimate-business-hint');
                  },
                ]"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                v-model="sender"
                :label="$t('estimate-sender')"
                :items="$store.state.userItems"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('estimate-sender-hint');
                  },
                ]"
                required
              >
                <template v-slot:selection="data">
                  {{ data.item.label }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                :label="$t('estimate-estimator')"
                v-model="estimator"
                :items="estimatorItems"
                required
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="customer"
                :label="$t('estimate-customer')"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('estimate-customer-hint');
                  },
                ]"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="manager"
                :label="$t('estimate-manager')"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('estimate-manager-hint');
                  },
                ]"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                v-model="customerLevel"
                :items="levelItems"
                :label="$t('estimate-customer-level')"
                @input="selectCustomerLevel()"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                v-model="decision"
                :items="decisionItems"
                :label="$t('estimate-decision')"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-show="showDecisionOther"
                v-model="decisionOther"
                :label="$t('estimate-decision-other')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="text-subtitle-1">
                {{ $t('estimate-products') }}
              </div>
              <v-btn-toggle
                class="mt-5"
                v-if="this.currencies.length > 1"
                v-model="currency"
                mandatory
              >
                <v-btn v-for="item in currencies" :key="item" :value="item"
                  >{{ item }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <template v-for="(p, index) in products">
              <v-col cols="3" sm="2" :key="'name' + index">
                <product-select
                  v-model="p.model"
                  :label="$t('estimate-product-model')"
                  :editable="canEdit"
                  :detail="false"
                  :currency="currency"
                  @remove="removeProduct(index)"
                  @input="modelSelected(p)"
                  hide-details
                >
                  <template v-slot:selection="data">
                    {{ data.item.model }}
                  </template>
                </product-select>
              </v-col>
              <v-col cols="9" sm="3" :key="'standard' + index">
                <v-text-field
                  v-model="p.standard"
                  :label="$t('estimate-product-standard')"
                  hide-details
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="2" :key="'unit' + index">
                <v-currency-field
                  v-model="p.unit"
                  :label="$t('estimate-product-unit')"
                  :readonly="!permission('estimate-edit')"
                  :currency="currency"
                  hide-details
                >
                </v-currency-field>
              </v-col>
              <v-col cols="2" sm="1" :key="'count' + index">
                <v-text-field
                  v-model="p.count"
                  :label="$t('estimate-product-count')"
                  type="number"
                  min="1"
                  hide-details
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="2" :key="'price' + index">
                <v-currency-field
                  v-model="p.price"
                  :label="$t('estimate-product-price')"
                  hide-details
                  :readonly="true"
                  :currency="currency"
                ></v-currency-field>
              </v-col>
              <v-col cols="3" sm="2" :key="'note' + index">
                <v-textarea
                  v-model="p.note"
                  :label="$t('estimate-note')"
                  auto-grow
                  rows="1"
                  :readonly="!canEdit"
                ></v-textarea>
              </v-col>
            </template>
            <v-col cols="12" sm="2">
              <v-btn
                ref="productAddButton"
                :disabled="!canEdit"
                small
                @click="addProduct"
              >
                {{ $t('estimate-product-add') }}
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              sm="2"
              offset="4"
              offset-sm="4"
              v-if="!isForeignCurrency"
            >
              <v-currency-field
                v-model="cost"
                :label="$t('estimate-product-cost')"
                hide-details
                :readonly="true"
                :currency="currency"
              >
              </v-currency-field>
            </v-col>
            <v-col
              cols="4"
              sm="2"
              offset="0"
              offset-sm="0"
              v-if="!isForeignCurrency"
            >
              <v-currency-field
                v-model="vat"
                :label="$t('estimate-vat')"
                hide-details
                :readonly="true"
              >
              </v-currency-field>
            </v-col>
            <v-col cols="4" sm="2" offset="8" :offset-sm="offsetCost">
              <v-currency-field
                v-model="sumCost"
                :label="$t('estimate-sum-cost')"
                hide-details
                :readonly="true"
                :currency="currency"
              >
              </v-currency-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="isForeignCurrency">
              <v-textarea
                v-model="phoneNumber"
                :label="$t('estimate-phone-number')"
                auto-grow
                rows="1"
                :readonly="!canEdit"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="8" v-if="isForeignCurrency">
              <v-textarea
                v-model="address"
                :label="$t('estimate-address')"
                auto-grow
                rows="1"
                :readonly="!canEdit"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="note"
                :label="$t('estimate-note')"
                auto-grow
                rows="1"
                :readonly="!canEdit"
              ></v-textarea>
            </v-col>
            <v-col cols="12" v-if="showPrivate">
              <v-textarea
                v-model="privateNote"
                :label="$t('estimate-private-note')"
                auto-grow
                rows="1"
                :readonly="!canEdit"
              ></v-textarea>
            </v-col>
            <v-col cols="12" v-show="!isNew">
              <v-divider class="my-4"></v-divider>
              <v-switch :label="$t('estimate-log')" v-model="showLog">
              </v-switch>
              <v-timeline v-show="showLog" dense align-top>
                <v-timeline-item
                  large
                  v-for="(log, index) in logs"
                  :key="index"
                  color=""
                >
                  <template v-slot:icon>
                    <v-avatar>
                      <v-img :src="log.photoURL" v-if="log.photoURL"></v-img>
                      <v-icon v-else>{{ $svg('mdi-account') }}</v-icon>
                    </v-avatar>
                  </template>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <div class="text-body-2">{{ log.user }}</div>
                      <div class="text-caption">{{ log.userVendor }}</div>
                      <div class="text-caption">{{ log.time }}</div>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <estimate-state
                        v-if="log.state != undefined"
                        :state="log.state"
                      ></estimate-state>
                      <v-chip v-if="log.vendor != undefined" label outlined>
                        {{ log.vendor }}
                      </v-chip>
                      <div v-html="log.text"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn v-if="canPrint" :disabled="updating" @click="print">
            {{ $t('estimate-print') }}
          </v-btn>
          <v-btn
            v-if="isForeignCurrency && canComplete"
            :disabled="updating"
            @click="printPI"
          >
            PI
          </v-btn>
          <v-btn v-show="!isNew" :disabled="updating" @click.prevent="clone">
            {{ $t('estimate-clone') }}
          </v-btn>
          <v-btn v-if="canOrder" :disabled="updating" @click.prevent="order">
            {{ $t('estimate-order') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-show="canAccept"
            :disabled="updating"
            @click.prevent="accept"
          >
            {{ $t('estimate-accept') }}
          </v-btn>
          <v-btn
            v-show="canRegister"
            :disabled="updating"
            @click.prevent="register"
          >
            {{ $t('estimate-register') }}
          </v-btn>
          <v-btn
            v-show="canComplete"
            color="indigo"
            class="white--text"
            :disabled="updating"
            @click.prevent="complete"
          >
            {{ $t('estimate-complete') }}
          </v-btn>
          <v-btn
            v-show="canCancel"
            color="error"
            :disabled="updating"
            @click.prevent="cancel"
          >
            {{ $t('estimate-cancel') }}
          </v-btn>
          <v-btn
            v-show="canEdit"
            color="primary"
            :disabled="!valid || updating"
            @click.prevent="save"
          >
            {{ saveLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>


<script>
import i18n from '@/i18n';
import Loading from './Loading';
import EstimateState from './EstimateState.vue';
import VCurrencyField from './VCurrencyField';
import ProductSelect from './ProductSelect';
import product from '@/product';
import datefmt from '@/datefmt';
import estimate from '@/estimate';
import firebase from '@/firebase';

export default {
  name: 'estimate',
  components: {
    'estimate-state': EstimateState,
    'loading': Loading,
    'product-select': ProductSelect,
    'v-currency-field': VCurrencyField,
  },
  data: () => ({
    id: '',
    sender: '',
    estimator: 1,
    vendor: '',
    customer: '',
    customerLevel: '',

    manager: '',
    business: '',
    decision: '',
    decisionOther: '',
    products: [],
    note: i18n.t('estimate-note-0') + '\n' + i18n.t('estimate-note-1'),
    privateNote: '',
    cost: 0,
    vat: 0,
    sumCost: 0,
    estimatorItems: [
      {
        value: 0,
        text: i18n.t('estimate-estimator-0'),
      },
      {
        value: 1,
        text: i18n.t('estimate-estimator-1'),
      },
    ],
    levelItems: [
      { text: i18n.t('vendor-level-subsidiary'), value: 'subsidiary' },
      { text: i18n.t('vendor-level-branch'), value: 'branch' },
      { text: i18n.t('vendor-level-oem'), value: 'oem' },
      { text: i18n.t('vendor-level-agency'), value: 'agency' },
      { text: i18n.t('vendor-level-company'), value: 'company' },
      { text: i18n.t('vendor-level-consumer'), value: 'consumer' },
    ],
    decisionItems: [
      { text: i18n.t('estimate-decision-0'), value: 0 },
      { text: i18n.t('estimate-decision-1'), value: 1 },
      { text: i18n.t('estimate-decision-2'), value: -1 },
    ],

    isNew: true,

    showDecisionOther: false,

    item: {},
    productItems: [],
    updating: false,
    vendorEditing: false,
    valid: false,
    state: null,

    printing: false,

    acceptedTime: '',

    hitDialog: false,
    hitLogs: [],
    logs: [],
    showLog: localStorage
      ? localStorage.getItem('Estimate.showLog') === 'true'
      : false,
    alerting: false,
    alertText: '',
    listener: null,
    currencies: [],
    currency: 'KRW',
    phoneNumber: '',
    address: '',

    isForeignCurrency: false,
  }),
  watch: {
    pageTitle: function () {
      this.$store.commit('setTitle', this.pageTitle || '');
    },
    showLog: function (val) {
      if (localStorage) localStorage.setItem('Estimate.showLog', val);
    },
    decision: function () {
      if (this.decision == -1) {
        this.showDecisionOther = true;
      } else {
        this.showDecisionOther = false;
      }
    },
    manager: function () {
      this.manager = this.manager ? this.manager : '';
    },
    products: {
      handler: function (val) {
        this.cost = 0;
        val.forEach(async (v) => {
          v.price = v.unit * v.count;
          this.cost += v.price;
        });
        this.vat = this.isForeignCurrency ? 0 : parseInt(this.cost * 0.1);
        this.sumCost = this.vat + this.cost;
      },
      deep: true,
    },
    currency: function () {
      this.isForeignCurrency = this.currency != 'KRW';
      this.productItems = product.loadItems(this.currency);
      this.products = [];
      this.phoneNumber = '';
      this.address = '';

      if (this.isForeignCurrency) this.note = '';
      else
        this.note =
          i18n.t('estimate-note-0') + '\n' + i18n.t('estimate-note-1');
    },
  },
  computed: {
    pageTitle() {
      return `${this.business}`;
    },
    user() {
      return this.$store.state.user;
    },
    saveLabel() {
      if (this.isNew || this.state == estimate.DRAFT)
        return i18n.t('estimate-save-draft');
      return i18n.t('save');
    },
    canView() {
      return (
        this.permission('estimate-edit') ||
        (this.permission('estimate') && this.vendor == this.user.vendor)
      );
    },
    vendorName() {
      return this.$store.getters.getVendor(this.vendor).name;
    },
    canAdd() {
      return this.permission('estimate-add');
    },
    canEdit() {
      return (
        this.permission('estimate-edit') ||
        (this.permission('estimate-add') &&
          (this.isNew ||
            (this.user.vendor == this.vendor && this.state == estimate.DRAFT)))
      );
    },
    canOrder() {
      return (
        this.state >= estimate.ACCEPTED &&
        this.state != estimate.CANCELED &&
        this.permission('order-add')
      );
    },
    canEditVendor() {
      return this.permission('estimate-edit');
    },
    canPrint: function () {
      return this.state >= estimate.ACCEPTED;
    },
    canRegister() {
      return (
        !this.isNew &&
        ((this.user.uid == this.author && this.permission('estimate-add')) ||
          this.permission('estimate-accept') ||
          this.permission('estimate-edit')) &&
        this.state == estimate.DRAFT
      );
    },
    canComplete() {
      return (
        !this.isNew &&
        ((this.user.uid == this.author && this.permission('estimate-add')) ||
          this.permission('estimate-accept') ||
          this.permission('estimate-edit')) &&
        this.state == estimate.ACCEPTED &&
        this.acceptedTime
      );
    },
    canCancel() {
      return (
        !this.isNew &&
        ((this.user.uid == this.author && this.permission('estimate-add')) ||
          this.permission('estimate-accept') ||
          this.permission('estimate-edit')) &&
        this.state == estimate.ACCEPTED &&
        this.acceptedTime
      );
    },
    canRemove() {
      return (
        !this.isNew &&
        ((this.user.vendor == this.vendor &&
          this.permission('estimate-add') &&
          this.state == estimate.DRAFT) ||
          (this.permission('estimate-edit') && this.state != estimate.DELETED))
      );
    },
    canRestore() {
      return (
        !this.isNew &&
        this.permission('estimate-accept') &&
        this.state != estimate.DRAFT
      );
    },
    canAccept() {
      return (
        this.permission('estimate-accept') && this.state == estimate.REGISTERED
      );
    },
    showPrivate() {
      return (
        this.$store.state.user.admin ||
        this.$store.getters.getVendor(this.user.vendor).level == 'subsidiary'
      );
    },
    offsetCost() {
      return this.isForeignCurrency ? 6 : 0;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  beforeDestroy() {
    estimate.unsubscribe(this.listener);
  },
  async mounted() {
    await this.init();
    if (!this.canView) {
      alert(i18n.t('estimate-view-denied'));
      this.$router.push({ path: '/estimates' });
      return;
    }
  },

  methods: {
    init: async function () {
      this.updating = true;
      this.id = '';
      this.sender = '';
      this.estimator = 1;
      this.customer = '';
      this.customerLevel = '';
      this.manager = '';
      this.business = '';
      this.decision = '';
      this.decisionOther = '';
      this.products = [];
      this.note = i18n.t('estimate-note-0') + '\n' + i18n.t('estimate-note-1');
      this.cost = 0;
      this.vat = 0;
      this.sumCost = 0;
      this.vendor = '';
      this.privateNote = '';
      this.currencies = this.vendorCurrencies();
      this.currency = this.currencies[0] || 'KRW';
      this.phoneNumber = '';
      this.address = '';

      this.id = this.$route.params.id;
      this.isNew = this.id == 'new';
      if (this.isNew) {
        if (!this.canAdd) {
          alert(i18n.t('estimate-add-denied'));
          return;
        }
        this.id = null;
        this.state = estimate.DRAFT;
        this.vendor = this.user.vendor;
        this.sender = this.user.uid;

        this.authorName = this.user.name;
        this.authorPhotoURL = this.user.photoURL;
        this.deadline = '';

        if (this.$route.query.src) {
          await this.load(this.$route.query.src);
        }
      } else {
        await this.load(this.id);
      }
      this.productItems = product.loadItems(this.currency);
      this.updating = false;
    },
    save: async function () {
      let doc = {};

      if (this.isNew) {
        doc.author = this.user.uid;
        doc.registerTime = firebase.firestore.FieldValue.serverTimestamp();
      }
      if (this.isNew || this.state != this.item.state) {
        doc.state = this.state;
        if (this.state == estimate.ACCEPTED || this.state == estimate.DRAFT) {
          doc.acceptedTime = this.acceptedTime;
        }
        if (this.state == estimate.ACCEPTED) {
          if (!this.piNumber && this.isForeignCurrency)
            doc.piNumber = await estimate.getPINumber();
        }
      }
      if (this.isNew || this.vendor != this.item.vendor) {
        doc.vendor = this.vendor;
      }
      if (this.isNew || this.sender.trim() !== this.item.sender) {
        doc.sender = this.sender.trim();
      }
      if (this.isNew || this.estimator !== this.item.estimator) {
        doc.estimator = this.estimator;
      }
      if (this.isNew || this.customer.trim() !== this.item.customer) {
        doc.customer = this.customer.trim();
      }
      if (this.isNew || this.customerLevel !== this.item.customerLevel) {
        doc.customerLevel = this.customerLevel;
      }
      if (this.isNew || this.manager !== this.item.manager) {
        doc.manager = this.manager;
      }
      if (this.isNew || this.business.trim() !== this.item.business) {
        doc.business = this.business.trim();
      }
      if (this.isNew || this.decision !== this.item.decision) {
        doc.decision = this.decision;
      }
      if (this.isNew || this.decisionOther.trim() !== this.item.decisionOther) {
        doc.decisionOther = this.decisionOther.trim();
      }
      if (this.isNew || this.note.trim() !== this.item.note) {
        doc.note = this.note.trim();
      }
      if (this.isNew || this.privateNote.trim() !== this.item.privateNote) {
        doc.privateNote = this.privateNote.trim();
      }
      if (this.isNew || this.address.trim() != this.item.address) {
        doc.address = this.address.trim();
      }
      if (this.isNew || this.phoneNumber.trim() != this.item.phoneNumber) {
        doc.phoneNumber = this.phoneNumber.trim();
      }
      if (this.isNew || this.currency.trim() != this.item.currency) {
        doc.currency = this.currency.trim();
      }

      this.products = this.products.filter((v) => v.model.length > 0);
      if (this.products.length <= 0) {
        this.$vuetify.goTo(this.$refs.productAddButton, { offset: 100 });
        this.ripple(this.$refs.productAddButton.$el);
        this.$refs.productAddButton.$el.click();
        this.alerting = true;
        this.alertText = this.$t('estimate-product-add-alert');
        return;
      }
      if (
        this.isNew ||
        JSON.stringify(this.products) !== JSON.stringify(this.item.products)
      ) {
        doc.products = JSON.parse(JSON.stringify(this.products));
      }

      if (Object.keys(doc).length === 0) {
        return;
      }
      this.updating = true;
      this.reloadEstimates();

      this.id = await estimate.save(doc, this.id, this.user.uid, this.currency);
      this.isNew = false;
      this.updating = false;
      await this.load(this.id);
    },
    load: async function (id) {
      this.updating = true;

      let doc = await estimate.load(id);
      this.item = doc;

      this.currency = doc.currency || 'KRW';
      this.vendor = doc.vendor || '';

      this.author = doc.author;
      this.sender = doc.sender;
      this.estimator = doc.estimator;
      this.customer = doc.customer;
      this.customerLevel = doc.customerLevel;
      this.manager = doc.manager;
      this.business = doc.business;
      this.decision = doc.decision;
      this.decisionOther = doc.decisionOther || '';
      this.note = doc.note;
      this.privateNote = doc.privateNote || '';

      if (this.isNew) {
        this.updating = false;
        return;
      }
      this.state = doc.state || 0;
      this.id = id;

      this.registerTime = doc.registerTime;
      this.acceptedTime = doc.acceptedTime ? doc.acceptedTime.toDate() : '';
      this.piNumber = doc.piNumber;

      this.$nextTick(() => {
        this.products = doc.products
          ? JSON.parse(JSON.stringify(doc.products))
          : [];
        this.updating = false;
      });

      await estimate.hit(this.id, this.user.uid);
      await this.loadLogs();
      await this.loadHits();

      estimate.unsubscribe(this.listener);

      this.phoneNumber = doc.phoneNumber || '';
      this.address = doc.address || '';

      this.listener = estimate.subscribe(id, async () => {
        await this.load(id);
      });
    },
    restore: function () {
      if (!this.canRestore) return;
      let res = confirm(i18n.t('estimate-restore-confirm'));
      if (!res) return;

      this.acceptedTime = '';
      this.state = estimate.DRAFT;
      this.save();
    },
    accept: async function () {
      if (!this.canAccept) return;
      this.state = estimate.ACCEPTED;
      this.acceptedTime = firebase.firestore.FieldValue.serverTimestamp();
      await this.save();
    },
    remove: async function () {
      if (!this.canRemove) return;
      let res = confirm(i18n.t('estimate-delete-confirm'));
      if (!res) return;

      this.state = estimate.DELETED;
      let self = this;
      await self.save();
      self.$router.replace('/estimates');
    },
    complete: async function () {
      if (!this.canComplete) return;

      this.state = estimate.COMPLETED;
      this.save();
    },
    cancel: async function () {
      if (!this.canCancel) return;

      this.state = estimate.CANCELED;
      this.save();
    },
    permission: function (perm) {
      return this.$store.state.permissions[perm] || false;
    },
    clone: async function () {
      if (this.isNew) return;
      this.$router.push({ path: '/estimates/new', query: { src: this.id } });
    },
    addProduct: function () {
      if (!this.canEdit) return;
      this.products.push({
        model: '',
        count: 1,
        unit: 0,
        price: 0,
        standard: '',
      });
    },
    removeProduct: function (index) {
      if (!this.canEdit) return;
      this.products.splice(index, 1);
    },
    register: async function () {
      if (!this.canRegister) return;
      let res = confirm(i18n.t('estimate-register-confirm'));
      if (!res) return;

      this.state = estimate.REGISTERED;
      await this.save();
    },
    ripple: function ($el) {
      let ev = new Event('mousedown');
      let offset = $el.getBoundingClientRect();
      ev.clientX = offset.left + offset.width / 2;
      ev.clientY = offset.top + offset.height / 2;
      $el.dispatchEvent(ev);
    },
    reloadEstimates: function () {
      this.$store.commit('reloadEstimates', true);
    },
    modelSelected: async function (v) {
      if (!this.customerLevel) {
        alert(i18n.t('estimate-model-hint'));
        this.removeProduct(v);
        return;
      }
      let model = await product.lookupModel(v.model, this.currency);
      v.unit = model.prices[this.customerLevel];
      v.standard = model.standard;
    },
    print: async function () {
      if (!this.canView || this.isNew) return;
      let self = this;
      await self.save();

      self.printing = true;
      self.updating = true;
      await estimate.print(self.id, self.user.uid, self.currency);
      await self.loadLogs();
      self.updating = false;
      self.printing = false;
    },
    loadHits: async function () {
      this.hitLogs = await estimate.loadHits(this.id);
    },
    loadLogs: async function () {
      let self = this;
      let logs = await estimate.loadLogs(self.id);
      logs.forEach((log) => {
        log.time = datefmt.full(log.time);
        log.text = log.texts
          .map((t) => {
            let colon = t.indexOf(':') + 1;
            if (colon <= 0) return t;
            return (
              '<span class="subtitle-2">' +
              t.slice(0, colon) +
              '</span>' +
              '<span class="text-body-2">' +
              t.slice(colon) +
              '</span>'
            );
          })
          .join('<br>');
      });
      this.logs = logs;
    },
    editVendor: function () {
      let self = this;
      if (!self.canEditVendor) return;
      self.vendorEditing = true;
      setTimeout(function () {
        self.$refs.vendorEntry.focus();
      }, 200);
    },

    order: async function () {
      let doc = {};

      doc.business = this.business.trim();
      doc.customer = this.customer.trim();
      doc.products = JSON.parse(JSON.stringify(this.products));
      doc.manager = this.manager.trim();
      doc.vendor = this.vendor;

      let self = this;
      self.$router.push({
        path: '/orders/new',
        query: { doc: JSON.stringify(doc) },
      });
    },
    selectCustomerLevel: function () {
      this.products.forEach((v) => this.modelSelected(v));
    },
    readableTime: function (v) {
      return datefmt.readable(new Date(v));
    },
    vendorCurrencies: function () {
      return (
        this.$store.getters.getVendor(this.$store.state.user.vendor)
          .currencies || ['KRW']
      );
    },
    printPI: async function () {
      if (!this.canView || this.isNew) return;
      let self = this;
      await self.save();

      self.printing = true;
      self.updating = true;
      await estimate.printPI(self.id, self.user.uid, self.currency);
      await self.loadLogs();
      self.updating = false;
      self.printing = false;
    },
  },
};
</script>

<style scoped>
.v-chip {
  margin-right: 4px;
}
.link {
  color: inherit;
  text-decoration: none;
}
.separator {
  color: gray;
}
.drag {
  background-color: gray;
}
</style>