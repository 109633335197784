import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import icon from './icon';

const darkTheme = window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false;

document
  .querySelector('meta[name="theme-color"]')
  .setAttribute('content', darkTheme ? '#121212' : '#ffffff');

Vue.use(Vuetify);
let vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: darkTheme,
  },
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  icon,
  render: (h) => h(App),
}).$mount('#app');
