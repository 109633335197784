<template>
  <v-chip label outlined :color="color || stateColor" :small="small">
    <v-icon v-if="icon">{{ $svg('mdi-label') }}</v-icon>
    {{ stateName }}
  </v-chip>
</template>

<script>
import estimate from '@/estimate';
export default {
  name: 'estimate-state',
  props: {
    state: {
      type: Number,
      default: estimate.DRAFT,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dummy: false,
  }),
  computed: {
    stateName: function () {
      return estimate.stateName(this.state);
    },
    stateColor: function () {
      return estimate.stateColor(this.state);
    },
    stateTextColor: function () {
      return estimate.stateTextColor(this.state);
    },
  },
};
</script>