let regions = [];
const region = {
  init: async function () {
    const module = await import(
      /* webpackPrefetch: true */ /* webpackChunkName: "regions" */ './regions'
    );
    regions = module.default;
  },

  list: function () {
    return regions;
  },

  search: function (keyword, limit, callback) {
    let text = (keyword || '').trim();
    let max = limit || 20;

    let matches = [];
    if (text.length > 0) {
      if (!regions) return matches;
      regions.some((v) => {
        if (v.n.indexOf(keyword) >= 0 && v.v) {
          matches.push({ value: v.c.toString(), text: v.n });
          if (matches.length >= max) return true;
        }
        return false;
      });
    }

    if (callback) callback(matches);

    return matches;
  },

  name: function (code) {
    if (!code) return '';
    if (!regions) return '';
    code = parseInt(code);
    let start = 0;
    let end = regions.length - 1;
    while (start <= end) {
      let mid = Math.floor((start + end) / 2);
      if (regions[mid].c === code) return regions[mid].n;
      else if (regions[mid].c < code) start = mid + 1;
      else end = mid - 1;
    }
    return '';
  },
};
export default region;
