import i18n from './i18n';

const self = {
  date: function (date) {
    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2),
    ].join('-');
  },
  clock: function (date) {
    return [
      ('0' + date.getHours()).slice(-2),
      ('0' + date.getMinutes()).slice(-2),
    ].join(':');
  },
  readable: function (date) {
    const day = self.date(date);
    if (day == self.today()) return i18n.t('today') + ' ' + self.clock(date);
    return day;
  },
  full: function (date) {
    return [self.date(date), self.clock(date)].join(' ');
  },
  timestamp: function (date) {
    return [self.date(date), self.clock(date)].join('').replace(/[\s-:]/g, '');
  },
  today: function () {
    const now = new Date();
    return self.date(now);
  },
};
export default self;
