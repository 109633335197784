<template>
  <v-app>
    <v-navigation-drawer v-if="authenticated" v-model="drawer" app dark>
      <v-list nav dense>
        <v-list-item :ripple="false" v-for="link in links" :key="link.name" :to="link.path">
          <v-list-item-icon>
            <v-icon>{{ $svg(link.meta.icon) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(link.name) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item :ripple="false" to="/profile">
          <v-list-item-icon>
            <v-avatar v-if="user.photoURL" size="24">
              <v-img :src="user.photoURL"></v-img>
            </v-avatar>
            <v-icon v-else>{{ $svg('mdi-account') }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.vendorName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app class="amber" :class="{ 'darken-1': darkTheme, 'lighten-1': !darkTheme }" light hide-on-scroll>
      <v-app-bar-nav-icon v-if="authenticated" @click.stop="sideIconClicked">
        <v-icon v-if="hasParent">{{ $svg('mdi-arrow-left') }}</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <span v-if="authenticated">{{ $store.state.title }}</span>
        <span v-else>{{ $t('emstone-hub') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip v-for="(button, index) in buttons" :key="index" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-show="button.visible == undefined || button.visible" v-on="on" icon @click="button.click">
            <v-icon>{{ $svg(button.icon) }}</v-icon>
          </v-btn>
        </template>
        <span>{{ button.text }}</span>
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <keep-alive include="projects,services,agreements,orders,estimates,issues,licenses">
        <router-view :key="$route.path" />
      </keep-alive>
    </v-main>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        <v-btn text :ripple="false" href="https://www.emstone.com/">
          <v-avatar size="24" tile class="mr-2">
            <v-img :src="require('./assets/logo.png')"></v-img>
          </v-avatar>
          &copy; EMSTONE {{ copyrightHolders }}
        </v-btn>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import './firebase';

export default {
  name: 'app',
  data: () => {
    return {
      drawer: null,
      copyrightHolders: (function () {
        var year = new Date().getFullYear();
        return year === 2019 ? '2019' : '2019-' + year;
      })(),
    };
  },
  computed: {
    darkTheme() {
      return this.$store.state.user.preferTheme == 'dark' ||
        (!this.$store.state.user.preferTheme &&
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
        ? true
        : false;
    },
    authenticated() {
      return this.$store.state.authenticated && !this.$store.state.initialPath;
    },
    hasParent() {
      return this.$route.meta.parent;
    },
    buttons() {
      return this.$store.state.buttons || [];
    },
    user() {
      return this.$store.state.user;
    },
    links() {
      return this.$router.options.routes.filter((route) => {
        if (this.user.disabled) return false;
        if (!route.meta) return false;
        if (!route.meta.icon) return false;
        if (!route.meta.ignoreAuth && !this.$store.state.authenticated)
          return false;
        if (
          route.meta.permission &&
          !this.$store.state.permissions[route.meta.permission]
        )
          return false;
        return true;
      });
    },
  },
  watch: {
    darkTheme(val) {
      // Set Vuetify.js dark theme.
      this.$vuetify.theme.dark = val;

      // Set HTML background theme color.
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute('content', val ? '#121212' : '#ffffff');

      // Select hightlight.js theme.
      let newStyle = 'hljs-light';
      let oldStyle = 'hljs-dark';
      if (val) {
        newStyle = 'hljs-dark';
        oldStyle = 'hljs-light';
      }
      document
        .querySelector(`link[title="${newStyle}"]`)
        .removeAttribute('disabled');
      document
        .querySelector(`link[title="${oldStyle}"]`)
        .setAttribute('disabled', 'disabled');
    },
  },
  props: {},
  methods: {
    sideIconClicked: function () {
      if (!this.$route.meta.parent) {
        this.drawer = !this.drawer;
        return;
      }
      if (this.$route.meta.parent == this.$store.state.fromRoute.name)
        this.$router.go(-1);
      else this.$router.push({ name: this.$route.meta.parent });
    },
  },
};
</script>

<style>
.theme--light.v-input--is-disabled .v-label,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.65);
  opacity: 1;
}

.theme--dark.v-input--is-disabled .v-label,
.theme--dark.v-input--is-disabled input,
.theme--dark.v-input--is-disabled textarea {
  color: rgba(255, 255, 255, 0.65);
  opacity: 1;
}

@media print {
  .v-navigation-drawer {
    display: none !important;
  }

  header {
    display: none !important;
  }

  main {
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
}

.theme--light.v-application .markdown-body {
  color: rgba(0, 0, 0, 0.9);
}

.theme--dark.v-application .markdown-body {
  color: rgba(255, 255, 255, 0.9);
}

.markdown-body video,
.markdown-body audio,
.markdown-body img {
  max-width: 100%;
  height: auto;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  line-height: 1.2em;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 90%;
  line-height: 1.45em;
  border-radius: 6px;
}

.markdown-body pre code,
.markdown-body pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

.theme--light.v-application .markdown-body pre {
  background-color: rgba(0, 0, 0, 0.05);
}

.theme--dark.v-application .markdown-body pre {
  background-color: rgba(255, 255, 255, 0.1);
}

.theme--light.v-application .markdown-body code,
.theme--light.v-application .markdown-body tt {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(175, 184, 193, 0.2);
  border-radius: 6px;
}

.theme--dark.v-application .markdown-body code,
.theme--dark.v-application .markdown-body tt {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(110, 118, 129, 0.4);
  border-radius: 6px;
}

.theme--light.v-application .markdown-body pre code,
.theme--light.v-application .markdown-body pre tt {
  padding: 0;
  background-color: transparent;
}

.theme--dark.v-application .markdown-body pre code,
.theme--dark.v-application .markdown-body pre tt {
  padding: 0;
  background-color: transparent;
}

.markdown-body hr {
  margin-top: 2em;
  margin-bottom: 2em;
}

.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}

.markdown-body td,
.markdown-body th {
  padding: 0;
  font-size: 90%;
}

.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body ul,
.markdown-body ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body table,
.markdown-body pre,
.markdown-body details {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}

.markdown-body blockquote {
  margin: 0;
  padding: 0 1em;
}

.theme--light.v-application .markdown-body blockquote {
  color: #57606a;
  border-left: 0.25em solid #d0d7de;
}

.theme--dark.v-application .markdown-body blockquote {
  color: #8b949e;
  border-left: 0.25em solid #30363d;
}

.markdown-body ul ul ol,
.markdown-body ul ol ol,
.markdown-body ol ul ol,
.markdown-body ol ol ol {
  list-style-type: lower-alpha;
}

.markdown-body table th {
  font-weight: 500;
}

.markdown-body table img {
  background-color: transparent;
}

.theme--light.v-application .markdown-body table th,
.theme--light.v-application .markdown-body table td {
  padding: 6px 13px;
  border: 1px solid #d0d7de;
}

.theme--light.v-application .markdown-body table tr {
  background-color: #ffffff;
  border-top: 1px solid hsla(210, 18%, 87%, 1);
}

.theme--light.v-application .markdown-body table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

.theme--dark.v-application .markdown-body table th,
.theme--dark.v-application .markdown-body table td {
  padding: 6px 13px;
  border: 1px solid #30363d;
}

.theme--dark.v-application .markdown-body table tr {
  background-color: #0d1117;
  border-top: 1px solid #21262d;
}

.theme--dark.v-application .markdown-body table tr:nth-child(2n) {
  background-color: #161b22;
}
</style>
