import i18n from './i18n';
import firebase from './firebase';
import datefmt from '@/datefmt';
import store from './store';
import sanitize from 'sanitize-filename';
import { saveAs } from 'file-saver';
import product from './product';

const db = firebase.firestore();
const functions = firebase.functions();

const ALL = -999;
const DELETED = -1;
const DRAFT = 0;
const REGISTERED = 1;
const ACCEPTED = 2;
const DENIED = 3;
const COMPLETED = 4;

const stateNames = {};
stateNames[ALL] = i18n.t('agreement-state-all');
stateNames[DELETED] = i18n.t('agreement-state-deleted');
stateNames[DRAFT] = i18n.t('agreement-state-draft');
stateNames[REGISTERED] = i18n.t('agreement-state-registered');
stateNames[ACCEPTED] = i18n.t('agreement-state-accepted');
stateNames[DENIED] = i18n.t('agreement-state-denied');
stateNames[COMPLETED] = i18n.t('agreement-state-completed');

const stateCodes = {};
stateCodes[ALL] = 'all';
stateCodes[DELETED] = 'deleted';
stateCodes[DRAFT] = 'draft';
stateCodes[REGISTERED] = 'registered';
stateCodes[ACCEPTED] = 'accepted';
stateCodes[DENIED] = 'denied';
stateCodes[COMPLETED] = 'completed';

const stateForCodes = {};
stateForCodes['all'] = ALL;
stateForCodes['deleted'] = DELETED;
stateForCodes['draft'] = DRAFT;
stateForCodes['registered'] = REGISTERED;
stateForCodes['accepted'] = ACCEPTED;
stateForCodes['denied'] = DENIED;
stateForCodes['completed'] = COMPLETED;


const stateColors = {};
stateColors[ALL] = '';
stateColors[DELETED] = '';
stateColors[DRAFT] = '';
stateColors[REGISTERED] = '';
stateColors[ACCEPTED] = 'info';
stateColors[DENIED] = 'warning';
stateColors[COMPLETED] = 'success';

const stateTextColors = {};
stateTextColors[ALL] = '';
stateTextColors[DELETED] = 'white';
stateTextColors[DRAFT] = '';
stateTextColors[REGISTERED] = 'white';
stateTextColors[ACCEPTED] = 'white';
stateTextColors[DENIED] = 'white';
stateTextColors[COMPLETED] = 'white';

const states = [];
states.push('ALL');
states.push('DELETED');
states.push('DRAFT');
states.push('REGISTERED');
states.push('ACCEPTED');
states.push('DENIED');
states.push('COMPLETED');

const agreement = {
  ALL: ALL,
  DELETED: DELETED,
  DRAFT: DRAFT,
  REGISTERED: REGISTERED,
  ACCEPTED: ACCEPTED,
  DENIED: DENIED,
  COMPLETED: COMPLETED,

  stateCode: function (state) {
    return stateCodes[state] || '';
  },
  stateForCode: function (code) {
    return stateForCodes[code];
  },
  stateName: function (state) {
    return stateNames[state] || '';
  },
  stateColor: function (state) {
    return stateColors[state] || '';
  },
  stateTextColor: function (state) {
    return stateTextColors[state] || '';
  },
  hit: async function (id, uid) {
    let ref = db.collection('agreements').doc(id);
    let hitRef = ref.collection('hits').doc(uid);
    let doc = await hitRef.get()
    if (doc.exists) {
      hitRef.update({
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
      return;
    }
    await hitRef.set({
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: uid,
    });
  },
  save: async function (doc, id, uid) {
    let log = [];
    if (doc.state != null) {
      log.push('state:' + doc.state);
      doc.registerTime = firebase.firestore.FieldValue.serverTimestamp();
    }
    if (doc.vendor != null) {
      log.push('vendor:' + doc.vendor);
    }
    if (doc.title != null) {
      log.push(i18n.t('agreement-title') + ': ' + doc.title);
    }
    if (doc.customer != null) {
      log.push(i18n.t('agreement-customer') + ': ' + doc.customer);
    }
    if (doc.business != null) {
      log.push(i18n.t('agreement-business') + ': ' + doc.business);
    }
    if (doc.executor != null) {
      log.push(i18n.t('agreement-executor') + ': ' + doc.executor);
    }
    if (doc.executorNumber != null) {
      log.push(i18n.t('agreement-executor-number') + ': ' + doc.executorNumber);
    }
    if (doc.term != null) {
      log.push(i18n.t('agreement-term') + ': ' + doc.term);
    }
    if (doc.publishDate != null) {
      log.push(i18n.t('agreement-publish-date') + ': ' + doc.publishDate);
    }
    if (doc.products != null) {
      log.push(i18n.t('agreement-products') + ': ' + doc.products.map(function (v) {
        return [`${v.model} x ${v.count}`].join(', ');
      }).join(' | '));
    }
    if (doc.note != null) {
      log.push(i18n.t('agreement-note') + ': ' + doc.note);
    }

    if (log.length <= 0) return id;

    let logText = log.join('\n');
    if (id) {
      await db.collection('agreements').doc(id).update(doc);
    } else {
      let newRef = await db.collection('agreements').add(doc);
      id = newRef.id;
    }
    await this.log(id, uid, logText);

    return id;
  },
  list: async function (condition) {
    if (condition.search.length > 0) {
      const res = await functions.httpsCallable('agreementSearch')(condition);
      return res.data;
    }

    let docs = [];
    let last = null;

    let ref = await this.dbRef(condition);
    if (condition.last) {
      const docRef = await db.collection('agreements').doc(condition.last).get();
      if (docRef.exists) {
        ref = ref.startAfter(docRef);
      }
    }
    const limit = condition.limit != undefined ? condition.limit : 20;
    if (limit > 0)
      ref = ref.limit(limit);

    const snapshot = await ref.get();
    snapshot.docs.forEach((doc) => {
      let data = doc.data();

      data.id = doc.id;
      data.registerTime = data.registerTime.toDate();
      docs.push(data);
    });

    if (docs.length > 0 && docs.length == limit) {
      last = docs[docs.length - 1].id;
    }

    return { docs: docs, last: last };
  },
  loadHits: async function (id) {
    let hitLogs = [];
    let docRef = db.collection('agreements').doc(id).collection('hits');
    let snapshot = await docRef.orderBy('time', 'asc').get();

    snapshot.forEach(function (doc) {
      let data = doc.data();
      let user = store.getters.getUser(data.uid);
      let hit = {
        time: data.time ? datefmt.full(data.time.toDate()) : '',
        user: user.name,
        vendor: store.getters.getVendor(user.vendor).name,
      };

      hitLogs.push(hit);
    });
    return hitLogs;
  },
  load: async function (id) {
    let doc = await db.collection('agreements').doc(id).get();
    return doc.data();
  },
  subscribe: function (id, callback) {
    let first = true;
    return db.collection('agreements').doc(id).onSnapshot((doc) => {
      // Only server change.
      if (!doc.metadata.hasPendingWrites) {
        // The first snapshot change is always called.
        if (first)
          first = false;
        else
          callback(doc.data());
      }
    });
  },
  unsubscribe: function (listener) {
    typeof (listener) === 'function' && listener();
  },
  loadLogs: async function (id) {
    let logs = [];
    let snapshot = await db.collection('agreements').doc(id).collection('logs').orderBy('time').get();
    snapshot.forEach(function (doc) {
      let data = doc.data();
      let user = store.getters.getUser(data.uid);
      let log = {
        time: data.time.toDate(),
        user: user.name,
        userVendor: store.getters.getVendor(user.vendor).name,
        photoURL: user.photoURL,
      };
      let texts = data.text.split('\n');
      if (texts[0] && texts[0].startsWith('state:')) {
        log.state = parseInt(texts[0].split(':')[1]);
        texts.splice(0, 1);
      }
      if (texts[0] && texts[0].startsWith('vendor:')) {
        let vendor = texts[0].split(':')[1];
        log.vendor = store.getters.getVendor(vendor).name;
        texts.splice(0, 1);
      }
      log.texts = texts;
      logs.push(log);
    });
    return logs;
  },
  dbRef: async function (condition) {
    let ref = db.collection('agreements');
    if (condition.author) ref = ref.where('author', '==', condition.author);
    if (condition.vendor) ref = ref.where('vendor', '==', condition.vendor);
    if (condition.state != agreement.ALL) ref = ref.where('state', '==', condition.state);
    else ref = ref.where('state', 'in',
      [agreement.REGISTERED, agreement.ACCEPTED, agreement.DENIED, agreement.COMPLETED]);

    if (condition.duration > 0) {
      switch (condition.duration) {
        case 1: // day
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            until.setDate(until.getDate() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 2: // month
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            until.setDate(1);
            until.setMonth(until.getMonth() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 3: // quarter
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 3) * 3);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 3) + 1) * 3);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 4: // half-year
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 6) * 6);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 6) + 1) * 6);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 5: // year
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(0);
            until.setDate(1);
            until.setMonth(0);
            until.setFullYear(until.getFullYear() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 6: // custom
          {
            let since = new Date(condition.date);
            let until = new Date(condition.untilDate);
            if (since.getTime() > until.getTime()) {
              let tmp = since;
              since = until;
              until = tmp;
            }
            until.setDate(until.getDate() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
      }
    }
    return ref.orderBy('registerTime', 'desc');
  },
  log: async function (id, uid, text) {
    await db.collection('agreements')
      .doc(id)
      .collection('logs')
      .add({
        time: firebase.firestore.FieldValue.serverTimestamp(),
        uid: uid,
        text: text,
      })
  },
  exportXLSX: async function (condition) {
    let res = confirm(i18n.t('agreement-xlsx-export-confirm'));
    if (!res) return;

    let snapshot = await this.list(condition);
    let docs = snapshot.docs;

    const ExcelJS = await import(/* webpackChunkName: "exceljs" */ 'exceljs');
    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet('Sheet1');

    let startNum = 0;
    let endNum = 0;

    let header = [
      { header: i18n.t('agreement-title'), key: 'title', width: 20 },
      { header: i18n.t('agreement-customer'), key: 'customer', width: 20 },
      { header: i18n.t('agreement-business'), key: 'business', width: 20 },
      { header: i18n.t('agreement-executor'), key: 'executor', width: 20 },
      { header: i18n.t('agreement-executor-number'), key: 'executorNumber', width: 20 },
      { header: i18n.t('agreement-term'), key: 'term', width: 20 },
      { header: i18n.t('agreement-publish-date'), key: 'publishDate', width: 20 },
      { header: i18n.t('agreement-product-model'), key: 'productModel', width: 20 },
      { header: i18n.t('agreement-product-count'), key: 'productCount', width: 20 },

      { header: i18n.t('agreement-note'), key: 'note', width: 20 },
    ]

    workSheet.columns = header

    let init = true;
    docs.forEach(function (item) {
      let model = '';
      let count = 0;
      let term = agreement.termName(item.term);

      if (item.products.length > 0) {
        if (init) {
          startNum = 2;
          init = false;
        }
        else startNum = endNum + 1;

        item.products.forEach((v) => {
          model = v.model;
          count = v.count;

          workSheet.addRow({
            title: item.title,
            customer: item.customer,
            business: item.business,
            executor: item.executor,
            executorNumber: item.executorNumber,
            term: term,
            productModel: model,
            productCount: count,
            publishDate: item.publishDate,
            note: item.note,
          });
        })
        endNum = item.products.length + startNum - 1;
        if (item.products.length > 1) {
          workSheet.mergeCells(
            `A${startNum}:A${endNum}`
          );
          workSheet.mergeCells(
            `B${startNum}:B${endNum}`
          );
          workSheet.mergeCells(
            `C${startNum}:C${endNum}`
          );
          workSheet.mergeCells(
            `D${startNum}:D${endNum}`
          );
          workSheet.mergeCells(
            `E${startNum}:E${endNum}`
          );
          workSheet.mergeCells(
            `F${startNum}:F${endNum}`
          );
          workSheet.mergeCells(
            `G${startNum}:G${endNum}`
          );
          workSheet.mergeCells(
            `J${startNum}:J${endNum}`
          );
        }
      } else {
        workSheet.addRow({
          title: item.title,
          customer: item.customer,
          business: item.business,
          executor: item.executor,
          executorNumber: item.executorNumber,
          term: term,
          productModel: '',
          productCount: '',
          publishDate: item.publishDate,
          note: item.note,
        });
      }
    });
    workSheet.columns.forEach(function (column) {
      let maxLength = 0;
      column['eachCell']({ includeEmpty: true }, function (cell) {
        let columnLength = cell.value ? cell.value.toString().length + 10 : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;

      column.alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };
    });
    let buffer = await workbook.xlsx.writeBuffer();
    let blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let filename = sanitize(`${i18n.t('agreements')} ${datefmt.full(new Date())}.xlsx`);
    saveAs(blob, filename);
  },
  termName: function (num) {
    let term = '';
    switch (parseInt(num)) {
      case 0:
        term = i18n.t('agreement-term-none');
        break;
      case 1:
        term = i18n.t('agreement-term-one-year');
        break;
      case 2:
        term = i18n.t('agreement-term-two-year');
        break;
    }
    return term;
  },
  print: async function (id, uid) {
    await agreement.log(id, uid, `${i18n.t('action')}: ${i18n.t('agreement-print')}`);
    //functions.useEmulator('localhost', 5000);
    const res = await functions.httpsCallable('agreementPrint2')(id);

    saveAs(product.b64toBlob(res.data.data, 'application/pdf'), res.data.filename);
  },
  loadStateCounts: async function () {
    let doc = await db.collection('admin').doc('agreement').get();

    return doc.data();
  },
  getExecutors: async function (vendor) {
    let executors = {};
    let snapshot = await db.collection('agreements').where('vendor', '==', vendor).where('state', '!=', -1).get();
    snapshot.forEach((row) => {
      let data = row.data();
      let executor = {
        name: data.executor.trim(),
        number: data.executorNumber.trim()
      };
      executors[executor.name] = executor;
    });

    return executors;
  },
};
export default agreement;
