<template>
  <v-card tile>
    <loading :value="updating"></loading>
    <v-card-text>
      <div class="overflow-x-auto">
        <v-btn-toggle v-model="state" mandatory class="ml-2 mt-2">
          <v-btn
            v-for="state in states"
            :key="state"
            :value="state"
            v-show="state != order.DELETED || permission('order-edit')"
            :class="{ 'pa-0': xsOnly, 'pa-2': !xsOnly }"
            :disabled="loading"
            :ripple="false"
          >
            <span>{{ order.stateName(state) }}</span>
            <v-chip
              outlined
              v-show="!xsOnly && showstateCounts"
              small
              class="ml-1"
            >
              {{ stateCounts[state] }}
            </v-chip>
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-divider></v-divider>
            <template v-for="(item, index) in items">
              <v-list-item
                :key="index * 2 + 1"
                :to="`/orders/${item.id}`"
                exact
                :ripple="false"
              >
                <v-list-item-action
                  class="align-self-start hidden-xs-only mr-1"
                >
                  <v-list-item-action-text>
                    {{ index + 1 }}
                  </v-list-item-action-text>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.business }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="d-flex align-center mt-1">
                    <span>{{ item.customerName }}</span>
                    <span class="ml-1 hidden-xs-only" v-if="isManufacturer">
                      | {{ getVendor(item.vendor) }}
                    </span>
                    <span class="ml-1 hidden-xs-only" v-if="isAdmin">
                      | {{ currencyCost(item) }}
                    </span>
                    <v-spacer></v-spacer>
                    <v-icon
                      v-if="item.payCheck"
                      left
                      small
                      color="warning"
                      class="pulse"
                    >
                      {{ $svg('mdi-star') }}
                    </v-icon>
                    <v-icon
                      v-if="item.ecountCheck"
                      left
                      small
                      color="purple lighten-3"
                    >
                      {{ $svg('mdi-arrow-up-bold-circle-outline') }}
                    </v-icon>
                    <v-icon
                      v-if="item.manufactureCheck"
                      left
                      small
                      color="error"
                    >
                      {{ $svg('mdi-check') }}
                    </v-icon>
                    <v-chip
                      v-if="item.deliveryDay"
                      label
                      outlined
                      :color="item.color"
                      :small="smAndDown"
                      class="hidden-xs-only"
                    >
                      {{ showDeliveryDay(item) }}
                    </v-chip>
                    <order-state
                      :state="item.state"
                      :small="smAndDown"
                    ></order-state>
                    <span class="ml-1">
                      {{ registerTime(item.registerTime) }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index * 2 + 0"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <div v-show="loadMore">
        <v-progress-linear
          v-show="loading"
          :indeterminate="loading"
        ></v-progress-linear>
        <v-btn v-show="!loading" @click="load()" block text>
          {{ $t('load-more') }}
        </v-btn>
        <v-divider v-show="!loading" class="mt-2"></v-divider>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col cols="12" :sm="exactVisible ? 9 : 12">
            <v-text-field
              ref="search"
              v-model="search"
              :label="$t('search')"
              clearable
              hide-details
              @change="query()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" v-show="exactVisible">
            <v-checkbox
              ref="exact"
              v-model="exact"
              :label="$t('search-exact')"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-select
              v-model="duration"
              :items="durationItems"
              :label="$t('order-duration')"
              hide-details
              @change="onChangeDuration"
              :menu-props="{ maxHeight: 'auto' }"
            ></v-select>
            <v-dialog
              ref="dateDialog"
              v-model="dateModal"
              :return-value.sync="date"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="dateVisible"
                  v-model="date"
                  :label="dateLabel"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datePicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="dateModal = false">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.dateDialog.save(datePicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="untilDialog"
              v-model="untilModal"
              :return-value.sync="until"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="untilVisible"
                  v-model="until"
                  :label="$t('order-until')"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="untilPicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="untilModal = false">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.untilDialog.save(untilPicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
           <v-col cols="12" class="d-flex">
            <v-select
              v-model="shipmentDuration"
              :items="durationItems"
              :label="$t('order-shipment-day-duration')"
              @change="onChangeShipmentDurtaion"
              hide-details
              :menu-props="{ maxHeight: 'auto' }"
            ></v-select>
            <v-dialog
              ref="shipmentDateDialog"
              v-model="shipmentDateModal"
              :return-value.sync="shipmentDate"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="shipmentDateVisible"
                  v-model="shipmentDate"
                  :label="shipmentDateLabel"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="shipmentDatePicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="shipmentDateModal = false">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.shipmentDateDialog.save(shipmentDatePicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="shipmentUntilDialog"
              v-model="shipmentUntilModal"
              :return-value.sync="shipmentUntil"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="shipmentUntilVisible"
                  v-model="shipmentUntil"
                  :label="$t('order-until')"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="shipmentUntilPicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="shipmentUntilModal = false">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.shipmentUntilDialog.save(shipmentUntilPicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="4" v-if="isManufacturer">
            <v-autocomplete
              v-model="vendor"
              :items="$store.state.vendorItems"
              hide-no-data
              hide-details
              auto-select-first
              clearable
              :label="$t('vendor')"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.label }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2" v-if="isManufacturer">
            <v-autocomplete
              v-model="director"
              :items="directorItems"
              hide-no-data
              hide-details
              auto-select-first
              clearable
              :label="$t('order-director')"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.label }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2">
            <v-select
              v-model="payCheck"
              row
              :label="$t('order-pay-check')"
              :items="payCheckItems"
            >
            </v-select>
          </v-col>
          <v-col cols="6" sm="2">
            <v-select
              v-model="manufactureCheck"
              row
              :label="$t('order-manufacture-check')"
              :items="manufactureCheckItems"
            >
            </v-select>
          </v-col>
          <v-col cols="6" sm="2">
            <v-select
              v-model="ecountCheck"
              row
              :label="$t('order-ecount-upload')"
              :items="ecountCheckItems"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          fab
          fixed
          bottom
          right
          to="/orders/new"
          exact
          v-if="canAdd"
        >
          <v-icon>{{ $svg('mdi-plus') }}</v-icon>
        </v-btn>
        <template>
          <v-dialog v-model="divideXlsxModel" max-width="400">
            <v-card flat>
              <v-card-title>
                {{ $t('order-xlsx-export-confirm') }}</v-card-title
              >
              <v-card-text>
                <v-container class="pb-5">
                  <v-checkbox v-model="xlsxDivide"
                      :label="$t('order-xlsx-divide-option')"
                      hide-details>
                  </v-checkbox>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="divideXlsxModel = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn color="primary" @click="exportXLSX">
                    {{ $t('order-xlsx-download') }}
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import OrderState from './OrderState.vue';
import order from '@/order';
import i18n from '@/i18n';
import Loading from './Loading';
import datefmt from '@/datefmt';
import customer from '@/customer';

export default {
  name: 'orders',
  components: {
    'order-state': OrderState,
    'loading': Loading,
  },

  data: () => ({
    q: '',
    order: order,
    shipmentDuration: 0,
    duration: 0,
    durationItems: [
      { value: 0, text: i18n.t('order-duration-0') },
      { value: 1, text: i18n.t('order-duration-1') },
      { value: 2, text: i18n.t('order-duration-2') },
      { value: 3, text: i18n.t('order-duration-3') },
      { value: 4, text: i18n.t('order-duration-4') },
      { value: 5, text: i18n.t('order-duration-5') },
      { value: 6, text: i18n.t('order-duration-6') },
    ],
    payCheckItems: [
      { value: 0, text: i18n.t('order-pay-item-0') },
      { value: 1, text: i18n.t('order-pay-item-1') },
      { value: 2, text: i18n.t('order-pay-item-2') },
    ],
    manufactureCheckItems: [
      { value: 0, text: i18n.t('order-manufacture-item-0') },
      { value: 1, text: i18n.t('order-manufacture-item-1') },
      { value: 2, text: i18n.t('order-manufacture-item-2') },
    ],
    ecountCheckItems: [
      { value: 0, text: i18n.t('order-ecount-item-0') },
      { value: 1, text: i18n.t('order-ecount-item-1') },
      { value: 2, text: i18n.t('order-ecount-item-2') },
    ],
    director: '',
    payCheck: '',
    ecountCheck: '',
    manufactureCheck: '',
    date: datefmt.today(),
    dateModal: false,
    until: datefmt.today(),
    untilModal: false,
    untilPicker: '',
    datePicker: '',
    shipmentDate: datefmt.today(),
    shipmentDateModal: false,
    shipmentUntil: datefmt.today(),
    shipmentUntilModal: false,
    shipmentUntilPicker: '',
    shipmentDatePicker: '',
    search: '',
    vendor: '',
    items: [],
    state: order.ALL,
    states: [
      order.ALL,
      order.REGISTERED,
      order.ACCEPTED,
      order.COMPLETED,
      order.SHIPMENT,
      order.DRAFT,
      order.DELETED,
    ],
    showstateCounts: true,
    stateCounts: {},

    loading: false,
    updating: false,
    exact: false,

    divideXlsxModel:false,
    xlsxDivide: 0,
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    dateVisible() {
      return this.duration > 0;
    },
    dateLabel() {
      return this.duration < 6
        ? i18n.t('order-register-time')
        : i18n.t('order-since');
    },
    untilVisible() {
      return this.duration == 6;
    },
    shipmentDateVisible() {
      return this.shipmentDuration > 0;
    },
    shipmentDateLabel() {
      return this.shipmentDuration < 6
        ? i18n.t('order-shipment-day')
        : i18n.t('order-since');
    },
    shipmentUntilVisible() {
      return this.shipmentDuration == 6;
    },
    canAdd: function () {
      return this.permission('order-add');
    },
    loadMore() {
      return this.loading || (this.items.length > 0 && this.loadStart);
    },
    isManufacturer: function () {
      return this.permission('order-manufacture');
    },
    isAdmin: function () {
      return this.permission('order-edit');
    },
    directorItems: function () {
      return this.$store.state.userItems.filter((user) => user.code);
    },
    exactVisible() {
      return (this.search || '').trim().indexOf(' ') > 0;
    },
    loadCount() {
      return this.$vuetify.breakpoint.xsOnly ? 20 : 20;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.orderReload) vm.init();
      vm.$store.commit('setButtons', [
        {
          icon: 'mdi-download',
          text: vm.$t('order-xlsx-export'),
          click: vm.exportXLSXOption,
        },
        {
          icon: 'mdi-magnify',
          text: vm.$t('search'),
          click: vm.gotoSearch,
        },
      ]);
    });
  },

  beforeRouteLeave(to, from, next) {
    next();
    this.$store.commit('reloadOrders', to.name != 'order');
    this.$store.commit('clearButtons');
    this.loading = false;
  },

  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },

  created() {
    this.init();
  },

  watch: {
    state: function (v) {
      if (!order.stateCode(v)) return;
      this.query();
    },
    vendor: function () {
      this.query();
    },
    duration: function () {
      this.query();
    },
    shipmentDate: function () {
      this.query();
    },
    shipmentDateModal: function (v) {
      if (v) this.shipmentDatePicker = this.shipmentDate;
    },
    shipmentDuration: function () {
      this.query();
    },
    shipmentUntil: function () {
      this.query();
    },
    shipmentUntilModal: function (v) {
      if (v) this.shipmentUntilPicker = this.shipmentUntil;
    },
    date: function () {
      this.query();
    },
    director: function () {
      this.query();
    },
    payCheck: function () {
      this.query();
    },
    manufactureCheck: function () {
      this.query();
    },
    ecountCheck: function () {
      this.query();
    },
    dateModal: function (v) {
      if (v) this.datePicker = this.date;
    },
    until: function () {
      this.query();
    },
    untilModal: function (v) {
      if (v) this.untilPicker = this.until;
    },
    exact: function () {
      this.query();
    },
  },
  methods: {
    init: function () {
      let self = this;
      self.items = [];

      self.state = order.ALL;
      self.loadStart = undefined;
      self.showstateCounts = true;
      self.vendor = '';
      self.search = '';
      self.manufactureCheck = 0;
      self.payCheck = 0;
      self.ecountCheck = 0;
      self.duration = 0;
      self.shipmentDuration = 0;
      self.date = datefmt.today();
      self.until = datefmt.today();
      self.shipmentDate = datefmt.today();
      self.shipmentUntil = datefmt.today();
      self.q = self.$route.query.q;
      if (self.q) {
        let q = self.q.split('+');
        q.forEach((v) => {
          let k = v.split(':');
          if (k[0] == 'state') {
            self.state = parseInt(k[1]);
          } else if (k[0] == 'vendor') {
            self.vendor = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'search') {
            self.search = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'duration') {
            self.duration = parseInt(k[1]);
            self.showstateCounts = false;
          } else if (k[0] == 'date') {
            self.date = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'until') {
            self.until = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'director') {
            self.director = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'manufactureCheck') {
            self.manufactureCheck = parseInt(k[1]);
            self.showstateCounts = false;
          } else if (k[0] == 'payCheck') {
            self.payCheck = parseInt(k[1]);
            self.showstateCounts = false;
          } else if (k[0] == 'exact') {
            self.exact = parseInt(k[1]) ? true : false;
            self.showstateCounts = false;
          } else if (k[0] == 'ecountCheck') {
            self.ecountCheck = parseInt(k[1]);
            self.showstateCounts = false;
          } else if (k[0] == 'shipmentDuration') {
            self.shipmentDuration = parseInt(k[1]);
            self.showstateCounts = false;
          } else if (k[0] == 'shipmentDate') {
            self.shipmentDate = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'shipmentUntil') {
            self.shipmentUntil = k[1];
            self.showstateCounts = false;
          }
        });
      }
      self.loadStateCounts();
      self.load();
    },
    load: async function () {
      let self = this;
      if (self.loading) return;
      self.loading = true;

      let vendor = self.vendor;
      if (!self.isManufacturer) vendor = self.user.vendor;

      if (!self.loadStart) self.items = [];

      let limit = self.loadCount;

      while (limit > 0 && self.loading) {
        const result = await order.list({
          state: self.state,
          vendor: vendor,
          duration: self.duration,
          date: self.date,
          untilDate: self.until,
          shipmentDuration: self.shipmentDuration,
          shipmentDate: self.shipmentDate,
          shipmentUntilDate: self.shipmentUntil,
          director: self.director,
          manufactureCheck: self.manufactureCheck,
          payCheck: self.payCheck,
          search: self.search.trim(),
          last: self.loadStart,
          limit: limit,
          exact: self.exact,
          ecountCheck: self.ecountCheck,
        });

        self.loadStart = result.last;

        result.docs.forEach((v) => {
          let c = customer.lookupCustomer(v.customer);
          v.customerName = c.name || '';
          v.color = order.stateColor(v.state);
          self.items.push(v);
        });
        
        if (result.docs.length == limit || !result.last) break;

        limit = limit - result.docs.length;
        await this.$nextTick();
      }

      self.loading = false;
    },
    reloadOrders: function () {
      this.$store.commit('reloadOrders', true);
    },
    loadStateCounts: async function () {
      if (!this.showstateCounts) return;
      let self = this;
      let data = await order.loadStateCounts();
      self.stateCounts = {};
      self.states.forEach(function (state) {
        self.stateCounts[state] = data['count_' + order.stateCode(state)] || 0;
      });
    },
    getVendor: function (vendor) {
      return this.$store.getters.getVendor(vendor).name;
    },
    registerTime(v) {
      return datefmt.readable(new Date(v));
    },
    permission: function (perm) {
      return this.$store.state.permissions[perm] || false;
    },
    dayFormat: function (d) {
      return parseInt(d.substr(8, 10));
    },
    showDeliveryDay: function (item) {
      return item.state == order.SHIPMENT ?
        datefmt.date(new Date(item.shipmentDay)) : item.deliveryDay;
    },
    query: function () {
      let q = [];
      if (this.state != order.ALL) q.push('state:' + this.state);
      if (this.vendor) q.push('vendor:' + this.vendor);
      if (this.search) q.push('search:' + this.search.trim());
      if (this.duration > 0) q.push('duration:' + this.duration);
      if (this.date != datefmt.today()) q.push('date:' + this.date);
      if (this.until != datefmt.today()) q.push('until:' + this.until);
      if (this.shipmentDuration > 0)
        q.push ('shipmentDuration:' + this.shipmentDuration);
      if (this.shipmentDate != datefmt.today()) q.push('shipmentDate:' + this.shipmentDate);
      if (this.shipmentUntil != datefmt.today()) q.push('shipmentUntil:' + this.shipmentUntil);
      if (this.director) q.push('director:' + this.director);
      if (this.payCheck) q.push('payCheck:' + this.payCheck);
      if (this.manufactureCheck)
        q.push('manufactureCheck:' + this.manufactureCheck);
      if (this.exact) q.push('exact:' + (this.exact ? '1' : '0'));
      if (this.ecountCheck)
        q.push('ecountCheck:' + this.ecountCheck);

      let query = {};
      if (q.length > 0) query.q = q.join('+');

      if (this.q && this.q === query.q) return;
      this.$router
        .push({ path: this.$route.path, query: query })
        .catch(function () {});
    },
    gotoSearch: function () {
      this.$vuetify.goTo(this.$refs.search);
      this.$refs.search.focus();
    },
    exportXLSXOption: function () {
      this.divideXlsxModel = true;
    },
    exportXLSX: async function () {
      this.updating = true;
      let self = this;
      await order.exportXLSX({
        state: self.state,
        vendor: self.vendor,
        duration: self.duration,
        date: self.date,
        untilDate: self.until,
        shipmentDuration: self.shipmentDuration,
        shipmentDate: self.shipmentDate,
        shipmentUntilDate: self.shipmentUntil,
        director: self.director,
        manufactureCheck: self.manufactureCheck,
        payCheck: self.payCheck,
        search: self.search.trim(),
        limit: 0,
        exact: self.exact,
        ecountCheck: self.ecountCheck,
      }, self.xlsxDivide == 1);
      this.divideXlsxModel = false;
      this.updating = false;
    },
    onChangeDuration: function (event) {
      if (event > 0)
        this.shipmentDuration = 0;
    },
    onChangeShipmentDurtaion: function (event){
      if (event > 0)
        this.duration = 0;
    },
    currencyCost: function (item) {
      return order.currencySymbol(item.currency, item.cost);
    }
  },
};
</script>

<style scoped>
.v-chip {
  margin-right: 4px;
}

.v-icon {
  margin-right: 4px;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
</style>
