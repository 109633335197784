<template>
  <v-card tile>
    <loading :value="updating"></loading>
    <v-card-text>
      <div class="overflow-x-auto">
        <v-btn-toggle v-model="state" mandatory class="ml-2 mt-2">
          <v-btn
            v-for="state in states"
            :key="state"
            :value="state"
            v-show="state != estimate.DELETED || permission('estimate-edit')"
            :class="{ 'pa-0': xsOnly, 'pa-2': !xsOnly }"
            :disabled="loading"
            :ripple="false"
          >
            <span>{{ estimate.stateName(state) }}</span>
            <v-chip
              outlined
              v-show="!xsOnly && showstateCounts"
              small
              class="ml-1"
            >
              {{ stateCounts[state] }}
            </v-chip>
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-divider></v-divider>
            <template v-for="(item, index) in items">
              <v-list-item
                :key="index * 2 + 1"
                :to="`/estimates/${item.id}`"
                exact
                :ripple="false"
              >
                <v-list-item-action
                  class="align-self-start hidden-xs-only mr-1"
                >
                  <v-list-item-action-text>
                    {{ index + 1 }}
                  </v-list-item-action-text>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.business }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="d-flex align-center mt-1">
                    <span>{{ item.customer }}</span>
                    <span class="ml-1 hidden-xs-only">
                      | {{ item.sender }}
                    </span>
                    <span class="ml-1 hidden-xs-only" v-if="isAdmin">
                      | {{ currencyCost(item) }}
                    </span>
                    <v-spacer></v-spacer>
                    <estimate-state
                      :state="item.state"
                      :small="smAndDown"
                    ></estimate-state>
                    <v-chip
                      v-if="item.acceptedTime"
                      label
                      outlined
                      :color="item.color"
                      :small="smAndDown"
                    >
                      {{ readableTime(item.acceptedTime) }}
                    </v-chip>
                    <span class="ml-1">
                      {{ readableTime(item.registerTime) }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index * 2 + 0"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <div v-show="loadMore">
        <v-progress-linear
          v-show="loading"
          :indeterminate="loading"
        ></v-progress-linear>
        <v-btn v-show="!loading" @click="load()" block text>
          {{ $t('load-more') }}
        </v-btn>
        <v-divider v-show="!loading" class="mt-2"></v-divider>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col cols="12" :sm="exactVisible ? 9 : 12">
            <v-text-field
              ref="search"
              v-model="search"
              :label="$t('search')"
              clearable
              hide-details
              @change="query()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" v-show="exactVisible">
            <v-checkbox
              ref="exact"
              v-model="exact"
              :label="$t('search-exact')"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-select
              v-model="duration"
              :items="durationItems"
              :label="$t('estimate-duration')"
              hide-details
              :menu-props="{ maxHeight: 'auto' }"
            ></v-select>
            <v-dialog
              ref="dateDialog"
              v-model="dateModal"
              :return-value.sync="date"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="dateVisible"
                  v-model="date"
                  :label="dateLabel"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datePicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="dateModal = false">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.dateDialog.save(datePicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="untilDialog"
              v-model="untilModal"
              :return-value.sync="until"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-show="untilVisible"
                  v-model="until"
                  :label="$t('estimate-until')"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="untilPicker"
                :locale="this.$i18n.locale"
                :day-format="dayFormat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn @click="untilModal = false">{{ $t('cancel') }} </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.untilDialog.save(untilPicker)"
                >
                  {{ $t('ok') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="6" sm="4" v-if="permission('estimate-edit')">
            <v-autocomplete
              v-model="sender"
              :items="$store.state.userItems"
              hide-no-data
              hide-details
              auto-select-first
              clearable
              :label="$t('estimate-sender')"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.label }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          fab
          fixed
          bottom
          right
          to="/estimates/new"
          exact
          v-if="canAdd"
        >
          <v-icon>{{ $svg('mdi-plus') }}</v-icon>
        </v-btn>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import i18n from '@/i18n';
import EstimateState from './EstimateState.vue';
import estimate from '@/estimate';
import Loading from './Loading';
import datefmt from '@/datefmt';

export default {
  name: 'estimates',
  components: {
    'estimate-state': EstimateState,
    'loading': Loading,
  },

  data: () => ({
    items: [],

    updating: false,
    loading: false,
    estimate: estimate,
    duration: 0,
    durationItems: [
      { value: 0, text: i18n.t('estimate-duration-0') },
      { value: 1, text: i18n.t('estimate-duration-1') },
      { value: 2, text: i18n.t('estimate-duration-2') },
      { value: 3, text: i18n.t('estimate-duration-3') },
      { value: 4, text: i18n.t('estimate-duration-4') },
      { value: 5, text: i18n.t('estimate-duration-5') },
      { value: 6, text: i18n.t('estimate-duration-6') },
    ],

    date: datefmt.today(),
    dateModal: false,
    until: datefmt.today(),
    untilModal: false,
    untilPicker: '',
    datePicker: '',
    search: '',
    sender: '',
    showstateCounts: false,
    stateCounts: {},
    exact: false,

    state: estimate.ALL,
    states: [
      estimate.ALL,
      estimate.ACCEPTED,
      estimate.REGISTERED,
      estimate.COMPLETED,
      estimate.CANCELED,
      estimate.DRAFT,
      estimate.DELETED,
    ],
  }),

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.estimateReload) vm.init();
      vm.$store.commit('setButtons', [
        {
          icon: 'mdi-magnify',
          text: vm.$t('search'),
          click: vm.gotoSearch,
        },
      ]);
    });
  },

  beforeRouteLeave(to, from, next) {
    next();
    this.$store.commit('reloadEstimates', to.name != 'estimate');
    this.$store.commit('clearButtons');
    this.loading = false;
  },

  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },

  created() {
    this.init();
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    canAdd: function () {
      return this.permission('estimate-add');
    },
    dateVisible() {
      return this.duration > 0;
    },
    dateLabel() {
      return this.duration < 6
        ? i18n.t('estimate-register-time')
        : i18n.t('estimate-since');
    },
    untilVisible() {
      return this.duration == 6;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    loadMore() {
      return this.loading || (this.items.length > 0 && this.loadStart);
    },
    isAdmin: function () {
      return this.permission('estimate-edit');
    },
    exactVisible() {
      return (this.search || '').trim().indexOf(' ') > 0;
    },
    loadCount() {
      return this.$vuetify.breakpoint.xsOnly ? 20 : 20;
    }, 
  },

  watch: {
    state: function (v) {
      if (!estimate.stateCode(v)) return;
      this.query();
    },
    sender: function () {
      this.query();
    },
    duration: function () {
      this.query();
    },
    date: function () {
      this.query();
    },
    dateModal: function (v) {
      if (v) this.datePicker = this.date;
    },
    until: function () {
      this.query();
    },
    untilModal: function (v) {
      if (v) this.untilPicker = this.until;
    },
    exact: function () {
      this.query();
    },
  },

  methods: {
    init: function () {
      let self = this;
      self.items = [];
      self.state = estimate.ALL;
      self.showstateCounts = true;
      self.loadStart = undefined;
      self.search = '';
      self.sender = '';
      self.duration = 0;
      self.date = datefmt.today();
      self.until = datefmt.today();

      self.q = self.$route.query.q;
      if (self.q) {
        let q = self.q.split('+');
        q.forEach((v) => {
          let k = v.split(':');
          if (k[0] == 'state') {
            self.state = parseInt(k[1]);
          } else if (k[0] == 'sender') {
            self.sender = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'search') {
            self.search = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'duration') {
            self.duration = parseInt(k[1]);
            self.showstateCounts = false;
          } else if (k[0] == 'date') {
            self.date = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'until') {
            self.until = k[1];
            self.showstateCounts = false;
          } else if (k[0] == 'exact') {
            self.exact = parseInt(k[1]) ? true : false;
            self.showstateCounts = false;
          }
        });
      }
      if (!self.permission('estimate-edit')) {
        self.vendor = self.user.vendor;
      }

      self.loadStateCounts();
      self.load();
    },
    add: function (id, data) {
      let doc = {
        id: id,
        state: data.state,
        sender: this.$store.getters.getUser(data.sender).name,
        cost: data.cost,
        business: data.business,
        customer: data.customer,
        acceptedTime: data.acceptedTime ? data.acceptedTime : '',
        registerTime: data.registerTime,
        currency: data.currency || 'KRW',
      };

      doc.cost = 0;
      data.products.forEach((p) => {
        doc.cost += p.price;
      });

      doc.routeName = '/estimates/' + doc.id;

      doc.color = estimate.stateColor(doc.state);
      if (doc.state == estimate.ACCEPTED) {
        if (new Date(doc.acceptedTime) <= estimate.urgentDay()) {
          doc.color = 'error';
        }
      }

      this.items.push(doc);
    },
    load: async function () {
      let self = this;
      if (self.loading) return;
      self.loading = true;

      let limit = self.loadCount;
      if (!self.loadStart) {
        self.items = [];
        if (!self.q) {
          let result = await estimate.listUrgent({
            vendor: self.vendor,
            date: self.date,
          });
          result.docs.forEach((v) => {
            self.add(v.id, v);
          });
        }
      }

      while (limit > 0 && self.loading) {
        let res = await estimate.list({
          state: self.state,
          duration: self.duration,
          date: self.date,
          untilDate: self.until,
          query: self.search.trim(),
          sender: self.sender,
          vendor: self.vendor,
          last: self.loadStart,
          limit: limit,
          exact: self.exact,
        });
        self.loadStart = res.last;

        res.docs.forEach((v) => {
          self.add(v.id, v);
        });

        if (res.docs.length == limit || !res.last) break;

        limit = limit - res.docs.length;
        await this.$nextTick();
      }

      self.loading = false;
    },
    loadStateCounts: async function () {
      if (!this.showstateCounts) return;
      let self = this;
      let data = await estimate.loadStateCounts();
      self.stateCounts = {};
      self.states.forEach(function (state) {
        self.stateCounts[state] =
          data['count_' + estimate.stateCode(state)] || 0;
      });
    },
    dayFormat: function (d) {
      return parseInt(d.substr(8, 10));
    },
    readableTime(v) {
      return datefmt.readable(new Date(v));
    },
    permission: function (perm) {
      return this.$store.state.permissions[perm] || false;
    },
    gotoSearch: function () {
      this.$vuetify.goTo(this.$refs.search);
      this.$refs.search.focus();
    },
    query: function () {
      let q = [];
      if (this.state != estimate.ALL) q.push('state:' + this.state);
      if (this.sender) q.push('sender:' + this.sender);
      if (this.search) q.push('search:' + this.search.trim());
      if (this.duration > 0) q.push('duration:' + this.duration);
      if (this.date != datefmt.today()) q.push('date:' + this.date);
      if (this.until != datefmt.today()) q.push('until:' + this.until);
      if (this.exact) q.push('exact:' + (this.exact ? '1' : '0'));

      let query = {};
      if (q.length > 0) query.q = q.join('+');

      if (this.q && this.q === query.q) return;

      this.$router
        .push({ path: this.$route.path, query: query })
        .catch(function () {});
    },
    currencyCost: function (item) {
      let cost = item.cost;

      if (item.currency == 'KRW')
        cost += cost * 0.1;

      return estimate.currencySymbol(item.currency, cost);
    }
  },
};
</script>

<style scoped>
.v-chip {
  margin-right: 4px;
}
</style>
