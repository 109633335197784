<template>
  <v-text-field v-model="model" v-bind="$attrs"> </v-text-field>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'v-number-field',

  props: {
    value: {
      type: String,
      default: undefined,
    },
    kind: {
      type: String,
      default: 'company-number',
    },
  },

  data() {
    return {
      model: this.value,
    };
  },

  watch: {
    value(v) {
      this.model = v;
    },
    model(v) {
      v = v.replace(/\s/g, '').replace(/[-]+/g, '-');
      let num;
      switch (this.kind) {
        case 'company-number':
          num = this.formatCompanyNumber(v);
          break;
        case 'mobile-number':
          //num = this.formatMobileNumber(v);
          num = v;
          break;
        case 'phone-number':
          //num = this.formatPhoneNumber(v);
          num = v;
          break;
        default:
          num = v;
          break;
      }
      Vue.nextTick(() => {
        this.model = num;
        this.$emit('input', num);
      });
    },
  },

  methods: {
    formatCompanyNumber(v) {
      let val = v.replace(/\D/g, '');
      let num = '';
      if (val.length > 5) {
        num += val.substr(0, 3);
        num += '-';
        num += val.substr(3, 2);
        num += '-';
        num += val.substr(5, 5);
      } else if (val.length > 3) {
        num += val.substr(0, 3);
        num += '-';
        num += val.substr(3);
        if (val.length == 5 && v[v.length - 1] == '-') {
          num += '-';
        }
      } else {
        num = v;
      }
      return num;
    },
    formatMobileNumber(v) {
      let val = v.replace(/\D/g, '');
      let num = '';
      if (val.length > 7) {
        num += val.substr(0, 3);
        num += '-';
        num += val.substr(3, 4);
        num += '-';
        num += val.substr(7, 4);
      } else if (val.length > 3) {
        num += val.substr(0, 3);
        num += '-';
        num += val.substr(3);
        if (val.length == 5 && v[v.length - 1] == '-') {
          num += '-';
        }
      } else {
        num = v;
      }
      return num;
    },
    formatPhoneNumber(v) {
      let val = v.replace(/\D/g, '');
      let num = '';
      if (val.length > 7) {
        if (val.substr(0, 2) == '02') {
          num += val.substr(0, 2);
          num += '-';
          if (val.length > 9) {
            num += val.substr(2, 4);
            num += '-';
            num += val.substr(6, 4);
          } else {
            num += val.substr(2, 3);
            num += '-';
            num += val.substr(5, 4);
          }
        } else {
          num += val.substr(0, 3);
          num += '-';
          if (val.length > 10) {
            num += val.substr(3, 4);
            num += '-';
            num += val.substr(7, 4);
          } else {
            num += val.substr(3, 3);
            num += '-';
            num += val.substr(6, 4);
          }
        }
      } else if (val.length > 3) {
        if (val.substr(0, 2) == '02') {
          num += val.substr(0, 2);
          num += '-';
          num += val.substr(2);
          if (val.length == 5 && v[v.length - 1] == '-') {
            num += '-';
          }
        } else {
          num += val.substr(0, 3);
          num += '-';
          num += val.substr(3);
          if (val.length == 5 && v[v.length - 1] == '-') {
            num += '-';
          }
        }
      } else {
        num = v;
      }
      return num;
    },
  },
};
</script>
