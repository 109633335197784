<template>
  <v-container>
    <v-snackbar v-model="alerting">
      {{ alertText }}
    </v-snackbar>
    <v-snackbar :timeout="-1" v-model="printing">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      {{ $t('order-printing-text') }}
    </v-snackbar>
    <v-snackbar :timeout="-1" v-model="ecountUploading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      {{ $t('order-ecount-uploading-text') }}
    </v-snackbar>
    <loading :value="updating"></loading>
    <v-form v-model="valid">
      <v-card>
        <v-card-title>
          <order-state :state="state"></order-state>
          <v-avatar size="32" v-if="false">
            <v-img :src="authorPhotoURL" v-if="authorPhotoURL"></v-img>
          </v-avatar>
          <v-chip v-if="false" label outlined>
            {{ authorName }}
          </v-chip>
          <v-chip label outlined v-show="!vendorEditing" @click="editVendor">
            {{ vendorName }}
          </v-chip>
          <v-autocomplete
            v-model="vendor"
            :items="$store.state.vendorItems"
            hide-no-data
            hide-details
            auto-select-first
            :label="$t('vendor')"
            solo
            v-show="canEditVendor && vendorEditing"
            @blur="vendorEditing = false"
            ref="vendorEntry"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.label }}
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="hitDialog"
            scrollable
            max-width="400px"
            :disabled="!permission('order-edit')"
          >
            <template v-slot:activator="{ on }">
              <v-chip v-if="permission('order-edit') && !isNew" small v-on="on">
                {{ hitLogs.length }}
              </v-chip>
            </template>
            <v-card>
              <v-card-title primary-title class="text-h5">
                {{ $t('order-hits') }}
              </v-card-title>
              <v-card-text>
                <p v-for="(hit, index) in hitLogs" :key="index" class="mb-0">
                  <span class="text-caption">{{ hit.time }}</span>
                  <span class="separator px-2">|</span>
                  <span class="text-body-2">{{ hit.user }}</span>
                  <span class="separator px-2">|</span>
                  <span class="text-caption">{{ hit.vendor }}</span>
                </p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="hitDialog = false">
                  {{ $t('close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-show="canRestore"
                :disabled="updating"
                @click.prevent="restore"
                v-on="on"
                class="mx-0"
              >
                <v-icon>{{ $svg('mdi-restore') }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('order-restore-draft') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-show="canRemove"
                :disabled="updating"
                @click.prevent="remove"
                v-on="on"
                class="mx-0"
              >
                <v-icon>{{ $svg('mdi-delete') }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="5">
              <v-select
                :items="customerItems"
                v-model="customer"
                :label="$t('customers')"
                item-text="name"
                item-value="id"
                required
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('order-customer-hint');
                  },
                ]"
                :readonly="!canEdit"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field
                v-model="business"
                :label="$t('order-business')"
                required
                :rules="[
                  function (v) {
                    if (!v) v = '';
                    return v.trim().length > 0 || $t('order-business-hint');
                  },
                ]"
                :readonly="!canEdit"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-combobox
                v-model="company"
                :items="companyItems"
                :label="$t('order-company')"
              ></v-combobox>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="manager"
                :label="$t('order-manager')"
                :readonly="!canEdit"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="managerPhone"
                :label="$t('order-manager-phone')"
                :readonly="!canEdit"
                :append-icon="
                  isMobile && !!managerPhone ? $svg('mdi-phone') : null
                "
                @click:append="callPhone(managerPhone)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete
                v-model="director"
                v-bind="$attrs"
                :items="directorItems"
                hide-no-data
                hide-details
                auto-select-first
                :label="$t('order-director')"
                ref="directorEntry"
              >
                <template v-slot:selection="data">
                  {{ data.item.label }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <div class="text-subtitle-1">
                {{ $t('order-products') }}
              </div>
              <v-btn-toggle
                class="mt-5"
                v-if="currencies.length > 1"
                v-model="currency"
                mandatory
              >
                <v-btn v-for="item in currencies" :key="item" :value="item"
                  >{{ item }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <template v-for="(p, index) in products">
              <v-col cols="7" sm="4" :key="'name' + index">
                <product-select
                  v-model="p.model"
                  :label="$t('order-product-model')"
                  :editable="canEdit"
                  :currency="currency"
                  @remove="removeProduct(index)"
                  @input="modelSelected(p)"
                  hide-details
                >
                </product-select>
              </v-col>
              <v-col cols="3" sm="2" :key="'unit' + index">
                <v-currency-field
                  v-model="p.unit"
                  :label="$t('order-product-unit')"
                  :readonly="!permission('order-edit')"
                  :currency="currency"
                  hide-details
                >
                </v-currency-field>
              </v-col>
              <v-col cols="2" sm="1" :key="'count' + index">
                <v-text-field
                  v-model.number="p.count"
                  :label="$t('order-product-count')"
                  type="number"
                  min="1"
                  hide-details
                  :readonly="!canEdit"
                  :currency="currency"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                offset="1"
                offset-sm="0"
                sm="2"
                :key="'deliveryMethod' + index"
              >
                <v-currency-field
                  v-model="p.price"
                  :label="$t('order-product-price')"
                  hide-details
                  :readonly="true"
                  :currency="currency"
                ></v-currency-field>
              </v-col>
              <v-col cols="7" sm="3" :key="'note' + index">
                <v-text-field
                  v-model="p.note"
                  :label="$t('order-product-note')"
                  hide-details
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="8">
              <v-btn
                ref="productAddButton"
                small
                @click="addProduct"
                v-show="canEdit"
              >
                {{ $t('order-product-add') }}
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-spacer></v-spacer>
              <v-currency-field
                v-model="cost"
                :label="costLabel"
                hide-details
                :readonly="true"
                :currency="currency"
              >
              </v-currency-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="request"
                :label="$t('order-request')"
                auto-grow
                rows="1"
                :readonly="!canEdit"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              v-show="!isNew"
              :class="{ drag: invoiceFileDragging }"
              @drop.prevent="onInvoiceFileDropped"
              @dragenter.prevent="invoiceFileDragging = true"
              @dragleave.prevent="invoiceFileDragging = false"
              @dragover.prevent="invoiceFileDragging = true"
            >
              <v-row align="center" class="text-center">
                <v-col
                  v-for="(file, index) in invoiceFiles"
                  :key="'invoice' + index"
                  class="d-flex child-flex"
                  cols="6"
                  sm="4"
                  lg="3"
                >
                  <v-hover v-slot:default="{ hover }" open-delay="1000">
                    <v-card>
                      <a :href="file.url" target="_blank">
                        <v-img
                          v-if="
                            /\.(jpg|jpeg|gif|webp|tiff|png|svg)$/i.test(
                              file.name
                            )
                          "
                          :src="file.url"
                          :title="`${file.name}`"
                          eager
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                          <v-btn
                            v-show="canEdit && hover"
                            @click.prevent="deleteFile(file)"
                            class="float-right ma-2"
                            fab
                            x-small
                            color="secondary"
                          >
                            <v-icon>{{ $svg('mdi-close') }}</v-icon>
                          </v-btn>
                        </v-img>
                        <div v-else class="text-caption ma-2">
                          <v-btn
                            v-show="canEdit && hover"
                            @click.prevent="deleteFile(file)"
                            class="float-right"
                            fab
                            x-small
                            color="secondary"
                          >
                            <v-icon>{{ $svg('mdi-close') }}</v-icon>
                          </v-btn>
                          {{ file.name }}
                        </div>
                      </a>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-list dense :class="{ drag: invoiceFileDragging }">
                <v-list-item
                  v-for="(file, index) in uploadingInvoiceFiles"
                  :key="'uploadingInvoice' + index"
                >
                  <span class="text-body-2 text-no-wrap">{{ file.name }}</span>
                  <span class="text-caption ml-1 text-no-wrap"
                    >({{ file.readableSize }})</span
                  >
                  <v-progress-linear class="ml-2" :value="file.progress">
                  </v-progress-linear>
                </v-list-item>
                <v-list-item v-if="canEdit">
                  <v-tooltip :disabled="isMobile" open-delay="500" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        small
                        :disabled="updating"
                        @click.native="$refs.selectInvoiceFile.click()"
                      >
                        {{ $t('order-file-add') }}
                      </v-btn>
                    </template>
                    <span>{{ $t('order-file-drag-hint') }}</span>
                  </v-tooltip>
                  <input
                    type="file"
                    ref="selectInvoiceFile"
                    style="display: none"
                    accept="image/*,*/*"
                    multiple
                    @change="onInvoiceFilePicked"
                  />
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="address"
                :label="$t('order-address')"
                :prepend-inner-icon="$svg('mdi-magnify')"
                :readonly="!canEdit"
                :append-icon="!!address ? $svg('mdi-map-marker') : null"
                :hint="$t('order-address-hint')"
                ref="addressTextField"
                @click:prepend-inner="openAddress"
                @click:append="openMap(address)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-autocomplete
                v-model="region"
                :loading="regionLoading"
                :items="regionItems"
                :search-input.sync="regionText"
                required
                :rules="[
                  function (v) {
                    if (!v) v = '';
                    return v.trim().length > 0 || $t('order-region-hint');
                  },
                ]"
                hide-no-data
                auto-select-first
                :readonly="!canEdit"
                :label="$t('order-region') + ': ' + $t('order-region-hint')"
                :append-icon="!!region ? $svg('mdi-map-marker') : null"
                @click:append="openMap(regionText)"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3">
              <v-select
                :items="deliveryMethodItems"
                v-model="deliveryMethod"
                :label="$t('order-delivery-method')"
                item-text="text"
                item-value="value"
                required
                :rules="[
                  function (v) {
                    return v >= 0 || $t('order-delivery-method-hint');
                  },
                ]"
                :readonly="!canEdit"
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="3">
              <v-dialog
                ref="orderDateDialog"
                v-model="orderDateModal"
                :return-value.sync="deliveryDay"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="deliveryDay"
                    :label="$t('order-delivery-day')"
                    :persistent-hint="canEdit"
                    required
                    :rules="[
                      function (v) {
                        if (!v) v = '';
                        return (
                          v.trim().length > 0 || $t('order-delivery-day-hint')
                        );
                      },
                    ]"
                    v-on="on"
                    :readonly="true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deliveryDay"
                  :locale="this.$i18n.locale"
                  :day-format="dayFormat"
                  :readonly="!canEdit"
                  :min="minDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn @click="orderDateModal = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.orderDateDialog.save(deliveryDay)"
                    :disabled="!canEdit"
                  >
                    {{ $t('ok') }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="receiver"
                :label="$t('order-receiver')"
                required
                :rules="[
                  function (v) {
                    if (!v) v = '';
                    return v.trim().length > 0 || $t('order-receiver-hint');
                  },
                ]"
                auto-grow
                rows="1"
                :readonly="!canEdit"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="receiverPhone"
                :label="$t('order-receiver-phone')"
                required
                :rules="[
                  function (v) {
                    if (!v) v = '';
                    return (
                      v.trim().length > 0 || $t('order-receiver-phone-hint')
                    );
                  },
                ]"
                auto-grow
                rows="1"
                :readonly="!canEdit"
                :append-icon="
                  isMobile && !!receiverPhone ? $svg('mdi-phone') : null
                "
                @click:append="callPhone(receiverPhone)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="canManufacture && isRegistered">
              <v-textarea
                v-model="salesNote"
                :label="$t('order-sales-note')"
                auto-grow
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col cols="6" v-if="isAccepted">
              <v-checkbox
                v-model="manufactureCheck"
                :label="$t('order-manufacture-check')"
                :readonly="!permission('order-edit')"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" v-if="isShipment">
              <v-dialog
                ref="shipmentDateDialog"
                v-model="orderShipmentDateModel"
                :return-value.sync="shipmentDay"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="shipmentDay"
                    :label="$t('order-shipment-day')"
                    :persistent-hint="canEdit"
                    required
                    :rules="[
                      function (v) {
                        if (!v) v = '';
                        return (
                          v.trim().length > 0 || $t('order-shipment-day-hint')
                        );
                      },
                    ]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="shipmentDay"
                  :locale="this.$i18n.locale"
                  :day-format="dayFormat"
                  :readonly="!canEdit"
                >
                  <v-spacer></v-spacer>
                  <v-btn @click="orderShipmentDateModel = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.shipmentDateDialog.save(shipmentDay)"
                    :disabled="!canEdit"
                  >
                    {{ $t('ok') }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" v-if="canManufacture && isAccepted">
              <v-textarea
                v-model="manufactureNote"
                :label="$t('order-manufacture-note')"
                auto-grow
                rows="1"
                :readonly="!permission('order-edit')"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              v-if="isAccepted"
              :class="{ drag: productPhotoDragging }"
              @drop.prevent="onProductPhotoDropped"
              @dragenter.prevent="
                canManufacture
                  ? (productPhotoDragging = true)
                  : (productPhotoDragging = false)
              "
              @dragleave.prevent="productPhotoDragging = false"
              @dragover.prevent="
                canManufacture
                  ? (productPhotoDragging = true)
                  : (productPhotoDragging = false)
              "
            >
              <v-row align="center" class="text-center">
                <v-col
                  v-for="(file, index) in productPhotos"
                  :key="'product' + index"
                  class="d-flex child-flex"
                  cols="6"
                  sm="4"
                  lg="3"
                >
                  <v-hover v-slot:default="{ hover }" open-delay="1000">
                    <v-card>
                      <a :href="file.url" target="_blank">
                        <v-img
                          v-if="
                            /\.(jpg|jpeg|gif|webp|tiff|png|svg)$/i.test(
                              file.name
                            )
                          "
                          :src="file.url"
                          :title="`${file.name}`"
                          eager
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                          <v-btn
                            v-show="canEdit && hover"
                            @click.prevent="deleteFile(file)"
                            class="float-right ma-2"
                            fab
                            x-small
                            color="secondary"
                          >
                            <v-icon>{{ $svg('mdi-close') }}</v-icon>
                          </v-btn>
                        </v-img>
                        <div v-else class="text-caption ma-2">
                          <v-btn
                            v-show="canEdit && hover"
                            @click.prevent="deleteFile(file)"
                            class="float-right"
                            fab
                            x-small
                            color="secondary"
                          >
                            <v-icon>{{ $svg('mdi-close') }}</v-icon>
                          </v-btn>
                          {{ file.name }}
                        </div>
                      </a>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-list dense :class="{ drag: productPhotoDragging }">
                <v-list-item
                  v-for="(file, index) in uploadingProductPhotos"
                  :key="'uploadingProduct' + index"
                >
                  <span class="text-body-2 text-no-wrap">{{ file.name }}</span>
                  <span class="text-caption ml-1 text-no-wrap"
                    >({{ file.readableSize }})</span
                  >
                  <v-progress-linear class="ml-2" :value="file.progress">
                  </v-progress-linear>
                </v-list-item>
                <v-list-item v-if="canManufacture">
                  <v-tooltip :disabled="isMobile" open-delay="500" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        small
                        :disabled="updating"
                        @click.native="$refs.selectProductPhoto.click()"
                      >
                        {{ $t('order-product-photo-add') }}
                      </v-btn>
                    </template>
                    <span>{{ $t('order-file-drag-hint') }}</span>
                  </v-tooltip>
                  <input
                    type="file"
                    ref="selectProductPhoto"
                    style="display: none"
                    accept="image/*,*/*"
                    multiple
                    @change="onProductPhotoPicked"
                  />
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="3" v-if="isForeignCurrency">
              <v-text-field
                v-model="credit"
                :label="$t('order-credit')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="isForeignCurrency">
              <v-text-field
                v-model="buyer"
                :label="$t('order-buyer')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="isForeignCurrency">
              <v-text-field
                v-model="shippingPort"
                :label="$t('order-shipping-port')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="isForeignCurrency">
              <v-text-field
                v-model="destination"
                :label="$t('order-destination')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" v-show="isCompleted && deliveryMethod == 0">
              <v-text-field
                v-model="waybillNumber"
                :label="$t('order-waybill-number')"
                :append-icon="$svg('mdi-magnify')"
                @click:append="lookupWaybill"
                :readonly="!canManufacture"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="note || permission('order-edit')">
              <v-textarea
                v-model="note"
                :label="$t('order-note')"
                auto-grow
                rows="1"
                :readonly="!permission('order-edit')"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="3" v-if="isAccepted">
              <v-checkbox
                v-model="payCheck"
                :label="$t('order-pay-check')"
                :readonly="!permission('order-edit')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="9" v-if="isAccepted">
              <v-textarea
                v-model="payNote"
                :label="$t('order-pay-note')"
                :readonly="!permission('order-edit')"
                auto-grow
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="3" v-if="canUploadEcount">
              <v-checkbox
                v-model="ecountCheck"
                :label="$t('order-ecount-success')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-show="!isNew">
              <v-divider class="my-4"></v-divider>
              <v-switch :label="$t('order-log')" v-model="showLog"> </v-switch>
              <v-timeline v-show="showLog" dense align-top>
                <v-timeline-item
                  large
                  v-for="(log, index) in logs"
                  :key="index"
                  color=""
                >
                  <template v-slot:icon>
                    <v-avatar>
                      <v-img :src="log.photoURL" v-if="log.photoURL"></v-img>
                      <v-icon v-else>{{ $svg('mdi-account') }}</v-icon>
                    </v-avatar>
                  </template>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <div class="text-body-2">{{ log.user }}</div>
                      <div class="text-caption">{{ log.userVendor }}</div>
                      <div class="text-caption">{{ log.time }}</div>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <order-state
                        v-if="log.state != undefined"
                        :state="log.state"
                      ></order-state>
                      <v-chip v-if="log.vendor != undefined" label outlined>
                        {{ log.vendor }}
                      </v-chip>
                      <div v-html="log.text"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn
            v-if="canPrint"
            :disabled="updating"
            @click="print"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-print') }}
          </v-btn>
          <v-btn
            v-if="isAccepted && isForeignCurrency"
            :disabled="updating"
            @click.prevent="openForeignDocument"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-foreign-document-print') }}
          </v-btn>
          <template>
            <v-dialog v-model="foreignDocumentDialog" max-width="800">
              <v-card flat>
                <v-card-title>
                  {{ $t('order-foreign-document-info') }}</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <template v-for="(p, index) in foreignProducts">
                        <v-col cols="7" sm="3" :key="'name' + index">
                          <v-text-field
                            v-model="p.model"
                            :label="$t('order-product-model')"
                            readonly
                            hide-details
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3" sm="2" :key="'unit' + index">
                          <v-text-field
                            v-model="p.hsCode"
                            :label="$t('order-product-hs-code')"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" :key="'weight' + index">
                          <v-text-field
                            v-model.number="p.weight"
                            :label="$t('order-product-unit-weight')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="1" :key="'count' + index">
                          <v-text-field
                            v-model.number="p.count"
                            :label="$t('order-product-count')"
                            type="number"
                            min="1"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" :key="'total_weight' + index">
                          <v-text-field
                            v-model.number="p.totalWeight"
                            :label="$t('order-product-total-weight')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" :key="'measurement' + index">
                          <v-text-field
                            v-model="p.measurement"
                            :label="$t('order-product-measurement')"
                          ></v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-btn @click="printCI"> CI </v-btn>
                    <v-btn @click="printPL"> PL </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="foreignDocumentDialog = false">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="primary" @click="saveForeignDocumentDialog">
                      {{ $t('save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <v-btn
            v-if="canClone"
            :disabled="updating"
            @click.prevent="clone"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-clone') }}
          </v-btn>
          <v-btn
            v-if="canRegisterProject"
            :disabled="updating"
            @click.prevent="registerProject"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-project-register') }}
          </v-btn>
          <v-btn
            v-if="canUploadEcount"
            :disabled="updating"
            @click.prevent="ecount"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-ecount-upload') }}
          </v-btn>
          <template>
            <v-dialog v-model="ecountDialog" max-width="400">
              <v-card flat>
                <v-card-title>
                  {{ $t('order-ecount-upload-dialog-title') }}</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-radio-group v-model="ecountVat" mandatory row>
                        <template v-slot:label>
                          <div>{{ $t('order-ecount-vat') }}</div>
                        </template>
                        <v-radio label="0%" value="0"></v-radio>
                        <v-radio label="10%" value="0.1"></v-radio>
                      </v-radio-group>
                    </v-row>
                    <v-row>
                      <v-radio-group v-model="ecountMethod" mandatory row>
                        <template v-slot:label>
                          <div>{{ $t('order-ecount-method') }}</div>
                        </template>
                        <v-radio
                          :label="$t('order-ecount-method-0')"
                          :value="0"
                        ></v-radio>
                        <v-radio
                          :label="$t('order-ecount-method-1')"
                          :value="1"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-dialog
                          ref="ecountDateDialog"
                          v-model="ecountDateModal"
                          :return-value.sync="ecountDate"
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="ecountDate"
                              :label="$t('order-ecount-date')"
                              :persistent-hint="canEdit"
                              v-on="on"
                              :readonly="true"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="ecountDate"
                            :locale="this.$i18n.locale"
                            :day-format="dayFormat"
                            :readonly="!canEdit"
                          >
                            <v-spacer></v-spacer>
                            <v-btn @click="ecountDateModal = false">
                              {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="$refs.ecountDateDialog.save(ecountDate)"
                              :disabled="!canEdit"
                            >
                              {{ $t('ok') }}
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="ecountDialog = false">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="primary" @click="uploadEcount">
                      {{ $t('upload') }}
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          <v-spacer></v-spacer>
          <v-btn
            v-if="canComplete"
            :disabled="updating"
            @click.prevent="complete"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-completed') }}
          </v-btn>
          <v-btn
            v-if="canEdit && canShipment"
            :disabled="updating"
            @click.prevent="shipment"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-state-shipment') }}
          </v-btn>
          <v-btn
            v-if="canAccept"
            :disabled="updating"
            @click.prevent="accept"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-accept') }}
          </v-btn>
          <v-btn
            v-if="canDeny"
            :disabled="updating"
            @click.prevent="deny"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-deny') }}
          </v-btn>
          <v-btn
            v-if="canRegister"
            :disabled="updating"
            @click.prevent="register"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ $t('order-register') }}
          </v-btn>
          <v-btn
            v-if="canRegister || canEdit"
            color="primary"
            :disabled="!valid || updating"
            @click.prevent="save"
            :small="$vuetify.breakpoint.mobile"
          >
            {{ saveLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
/* global daum */
import i18n from '@/i18n';
import Loading from './Loading';
import order from '@/order';
import orderState from './OrderState.vue';
import VCurrencyField from './VCurrencyField';
import ProductSelect from './ProductSelect';
import region from '@/region';
import customer from '@/customer';
import datefmt from '@/datefmt';
import product from '@/product';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'order',
  components: {
    'order-state': orderState,
    'loading': Loading,
    'v-currency-field': VCurrencyField,
    'product-select': ProductSelect,
  },
  data: () => ({
    id: '',
    state: order.DRAFT,
    author: '',
    vendor: '',
    customer: '',
    business: '',
    company: '',
    manager: '',
    managerPhone: '',
    products: [],
    cost: 0,
    request: '',
    deliveryDay: '',
    shipmentDay: '',
    address: '',
    region: '',
    deliveryMethod: 0,
    receiver: '',
    receiverPhone: '',
    note: '',
    director: '',
    salesNote: '',
    manufactureCheck: false,
    manufactureNote: '',
    waybillNumber: '',
    payCheck: false,
    payNote: '',
    registerTime: '',
    ecountCheck: false,

    isNew: false,
    item: {},

    hits: 0,
    hitDialog: false,
    hitLogs: [],
    logs: [],
    showLog: localStorage
      ? localStorage.getItem('order.showLog') === 'true'
      : false,

    ecountDialog: false,
    ecountVat: '0.1',
    ecountMethod: 1,
    ecountDate: datefmt.today(),
    ecountDateModal: false,
    ecountUploading: false,

    regionText: '',

    updating: false,
    valid: false,
    regionLoading: false,

    alerting: false,
    alertText: '',

    vendorEditing: false,

    customerItems: [],
    regionItems: [],
    companyItems: [],

    companies: {},

    orderDateModal: false,
    orderShipmentDateModel: false,

    deliveryMethodItems: [
      { value: 0, text: order.deliveryMethodName('KRW', 0) },
      { value: 1, text: order.deliveryMethodName('KRW', 1) },
      { value: 2, text: order.deliveryMethodName('KRW', 2) },
      { value: 3, text: order.deliveryMethodName('KRW', 3) },
    ],

    listener: null,
    invoiceFileDragging: false,
    productPhotoDragging: false,
    uploadingInvoiceFiles: [],
    uploadingProductPhotos: [],
    files: [],
    isMobile: isMobile,
    printing: false,
    currency: 'KRW',
    currencies: [],
    costLabel: i18n.t('order-product-cost'),
    isForeignCurrency: false,
    foreignDocumentDialog: false,
    foreignProducts: [],

    credit: 'T/T in advance',
    buyer: 'SAME AS CONSIGNEE',
    destination: '',
    shippingPort: 'ICN, KOREA',
  }),
  watch: {
    business: function () {
      this.$store.commit('setTitle', this.business || '');
    },
    regionText: function (val) {
      if (!val) return;
      this.queryRegions(val);
    },
    showLog: function (val) {
      if (localStorage) localStorage.setItem('order.showLog', val);
    },
    vendor: async function () {
      let customers = this.$store.getters.getVendor(this.vendor).customers;
      if (!customers || customers.length <= 0) {
        this.customerItems = [];
        this.customer = '';
        return;
      }
      const items = customers.map((v) => {
        let doc = customer.lookupCustomer(v);
        doc.id = v;
        return doc;
      });
      this.customerItems = items;
      if (items.length >= 1) {
        if (!this.customer) this.customer = items[0].id;
        else {
          if (items.find((v) => v.id == this.customer) == undefined) {
            this.customer = items[0].id;
          }
        }
      }
      this.companies = await order.getCompanies(this.vendor);
      this.companyItems = Object.values(this.companies)
        .map((v) => v.name)
        .sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
    },
    company: function () {
      let doc = this.companies[this.company];
      if (doc) {
        this.manager = doc.manager;
        this.managerPhone = doc.managerPhone;
      }
    },
    director: function () {
      this.director = this.director ? this.director : '';
    },
    products: {
      handler: function (val) {
        this.cost = 0;
        val.forEach(async (v) => {
          v.price = v.unit * v.count;
          this.cost += v.price;
        });
        this.vat = this.isForeignCurrency ? 0 : parseInt(this.cost * 0.1);
        this.sumCost = this.vat + this.cost;
      },
      deep: true,
    },
    currency: function (currency) {
      this.isForeignCurrency = this.currency != 'KRW';
      this.productItems = product.loadItems(this.currency);
      this.products = [];
      this.costLabel = this.isForeignCurrency
        ? i18n.t('order-product-cost')
        : i18n.t('order-product-cost-vat');

      if (this.isForeignCurrency) {
        this.note = 'FOB ICN, KOREA\nCountry of Origin : MADE IN KOREA';
        this.deliveryMethodItems = [
          { value: 0, text: order.deliveryMethodName(currency, 0) },
          { value: 1, text: order.deliveryMethodName(currency, 1) },
        ];
      } else {
        this.deliveryMethodItems = [
          { value: 0, text: order.deliveryMethodName(currency, 0) },
          { value: 1, text: order.deliveryMethodName(currency, 1) },
          { value: 2, text: order.deliveryMethodName(currency, 2) },
          { value: 3, text: order.deliveryMethodName(currency, 3) },
        ];
        this.note = '';
      }
    },
    foreignProducts: {
      handler: function (val) {
        this.cost = 0;
        val.forEach((v) => {
          v.totalWeight = v.weight ? v.weight : 0 * v.count;
        });
      },
      deep: true,
    },
  },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
    vendorName: function () {
      return this.$store.getters.getVendor(this.vendor).name;
    },
    directorItems: function () {
      return this.$store.state.userItems.filter((user) => user.code);
    },
    canRegisterProject: function () {
      return this.permission('order-edit') && this.state == order.SHIPMENT;
    },
    canUploadEcount: function () {
      return this.permission('order-edit') && this.state >= order.ACCEPTED;
    },
    canAdd: function () {
      return this.permission('order-add');
    },
    canEdit: function () {
      return (
        this.permission('order-edit') ||
        (this.permission('order-add') &&
          (this.isNew ||
            (this.user.vendor == this.vendor && this.state == order.DRAFT)))
      );
    },
    canClone: function () {
      return (
        !this.isNew &&
        (this.permission('order-edit') ||
          (this.permission('order-add') && this.user.vendor == this.vendor))
      );
    },
    canPrint: function () {
      return this.state != order.DRAFT;
    },
    canView: function () {
      return (
        this.permission('order-manufacture') ||
        (this.permission('order') && this.vendor == this.user.vendor)
      );
    },
    canEditVendor: function () {
      return this.permission('order-edit');
    },
    canRestore: function () {
      return (
        !this.isNew &&
        this.permission('order-edit') &&
        this.state != order.DRAFT
      );
    },
    canRegister: function () {
      return (
        !this.isNew &&
        ((this.user.vendor == this.vendor && this.permission('order-add')) ||
          this.permission('order-edit')) &&
        this.state == order.DRAFT
      );
    },
    canAccept: function () {
      return this.canEdit && this.state == order.REGISTERED;
    },
    canDeny: function () {
      return this.permission('order-edit') && this.state == order.REGISTERED;
    },
    canManufacture: function () {
      return this.permission('order-manufacture');
    },
    canComplete: function () {
      return (
        this.permission('order-manufacture') && this.state == order.ACCEPTED
      );
    },
    canShipment: function () {
      return (
        this.permission('order-manufacture') && this.state == order.COMPLETED
      );
    },
    canRemove: function () {
      return (
        !this.isNew &&
        ((this.user.vendor == this.vendor &&
          this.permission('order-add') &&
          this.state == order.DRAFT) ||
          (this.permission('order-edit') && this.state != order.DELETED))
      );
    },
    isCompleted: function () {
      return (
        (this.permission('order-manufacture') &&
          this.state >= order.COMPLETED) ||
        (this.state == order.SHIPMENT && !this.permission('order-edit'))
      );
    },
    isAccepted: function () {
      return this.state >= order.ACCEPTED;
    },
    isRegistered: function () {
      return this.state >= order.REGISTERED;
    },
    isShipment: function () {
      return (
        (this.permission('order-manufacture') ||
          this.user.vendor == this.vendor) &&
        this.state == order.SHIPMENT
      );
    },
    saveLabel: function () {
      if (this.isNew || this.state == order.DRAFT)
        return i18n.t('order-save-draft');
      return i18n.t('save');
    },
    minDate: function () {
      return new Date(new Date().setDate(new Date().getDate())).toISOString();
    },
    vendorLevel: function () {
      return this.$store.getters.getVendor(this.vendor).level;
    },
    invoiceFiles: function () {
      return this.files.filter((v) => v.tags.indexOf('invoice') >= 0);
    },
    productPhotos: function () {
      return this.files.filter((v) => v.tags.indexOf('product') >= 0);
    },
  },
  mounted: async function () {
    await this.init();
    if (!this.canView) {
      alert(i18n.t('order-view-denied'));
      this.$router.push({ path: '/orders' });
      return;
    }
  },
  beforeRouteUpdate: function (to, from, next) {
    next();
    this.init();
  },
  beforeDestroy: function () {
    order.unsubscribe(this.listener);
  },
  methods: {
    init: async function () {
      this.id = null;
      this.author = '';
      this.state = null;
      this.vendor = '';
      this.customer = '';
      this.business = '';
      this.company = '';
      this.manager = '';
      this.managerPhone = '';
      this.products = [];
      this.cost = 0;
      this.request = '';
      this.deliveryDay = '';
      this.shipmentDay = null;
      this.address = '';
      this.region = '';
      this.deliveryMethod = 0;
      this.receiver = '';
      this.receiverPhone = '';
      this.note = '';
      this.director = '';
      this.salesNote = '';
      this.manufactureCheck = false;
      this.payCheck = false;
      this.payNote = '';
      this.manufactureNote = '';
      this.waybillNumber = '';
      this.registerTime = '';
      this.ecountCheck = false;
      this.currencies = this.vendorCurrencies();
      this.currency = this.currencies[0] || 'KRW';
      this.foreignProducts = [];
      this.id = this.$route.params.id;
      this.isNew = this.id == 'new';
      this.uid = this.user.id;
      if (this.isNew) {
        if (!this.canAdd) {
          alert(i18n.t('order-add-denied'));
          return;
        }

        this.state = order.DRAFT;
        this.author = this.user.uid;

        if (this.$route.query.doc) {
          let doc = JSON.parse(this.$route.query.doc);
          this.business = doc.business;
          this.customer = doc.customer;
          this.products = doc.products;
          this.vendor = doc.vendor;
          this.receiver = this.manager = doc.manager;
        }
        if (this.$route.query.src) {
          await this.load(this.$route.query.src);
        } else {
          this.id = null;
          this.vendor = this.user.vendor;
        }
      } else {
        await this.load(this.id);
      }
    },
    load: async function (id) {
      this.updating = true;
      let doc = await order.load(id);
      this.item = doc;
      this.vendor = doc.vendor || '';

      if (!this.canView) {
        this.updating = true;
        return;
      }

      this.currency = doc.currency || 'KRW';
      this.customer = doc.customer || '';
      this.business = doc.business || '';
      this.company = doc.company || '';
      this.manager = doc.manager || '';
      this.managerPhone = doc.managerPhone || '';
      this.request = doc.request || '';
      this.deliveryDay = doc.deliveryDay || '';
      this.shipmentDay = doc.shipmentDay
        ? doc.shipmentDay.toDate
          ? datefmt.date(doc.shipmentDay.toDate())
          : datefmt.date(new Date(doc.shipmentDay))
        : null;

      this.address = doc.address || '';
      this.region = doc.region || '';
      this.deliveryMethod = doc.deliveryMethod || 0;
      this.regionText = region.name(doc.region) || '';
      this.receiver = doc.receiver || '';
      this.receiverPhone = doc.receiverPhone || '';
      this.cost = doc.cost;

      this.author = doc.author || '';
      this.director = doc.director || '';

      if (this.isNew) {
        this.updating = false;
        this.shipmentDay = null;
        this.products.map((v) => {
          this.modelSelected(v);
        });
        return;
      }

      this.state = doc.state || 0;
      this.note = doc.note || '';
      this.director = doc.director || '';
      this.salesNote = doc.salesNote || '';
      this.manufactureCheck = doc.manufactureCheck || false;
      this.manufactureNote = doc.manufactureNote || '';
      this.payCheck = doc.payCheck || false;
      this.payNote = doc.payNote || '';
      this.waybillNumber = doc.waybillNumber || '';
      this.registerTime = doc.registerTime || '';
      this.ecountCheck = doc.ecountCheck || false;
      this.credit = doc.credit || 'T/T in advance';
      this.buyer = doc.buyer || 'SAME AS CONSIGNEE';
      this.destination = doc.destination || '';
      this.shippingPort = doc.shippingPort || 'ICN, KOREA';

      this.$nextTick(() => {
        this.products = doc.products
          ? JSON.parse(JSON.stringify(doc.products))
          : [];
        this.updating = false;
      });

      await order.hit(this.id, this.user.uid);
      await this.loadFiles();
      await this.loadLogs();
      await this.loadHits();

      order.unsubscribe(this.listener);

      this.products = doc.products
        ? JSON.parse(JSON.stringify(doc.products))
        : [];

      this.listener = order.subscribe(this.id, async () => {
        await this.load(this.id);
      });
    },
    save: async function () {
      let doc = {};
      if (this.isNew) {
        this.id = undefined;
        doc.author = this.user.uid;
        doc.ecountCheck = false;
      }
      if (this.isNew || this.state != this.item.state) {
        doc.state = this.state;
      }
      if (this.isNew || this.author != this.item.author) {
        doc.author = this.author;
      }
      if (this.isNew || this.vendor !== this.item.vendor) {
        doc.vendor = this.vendor;
      }
      if (this.isNew || this.customer !== this.item.customer) {
        doc.customer = this.customer;
        let customerForCode = customer.lookupCustomer(this.customer);
        doc.customerNumber = customerForCode.code;
      }
      if (this.isNew || this.business.trim() !== this.item.business) {
        doc.business = this.business.trim();
      }
      if (this.isNew || this.company.trim() !== this.item.company) {
        doc.company = this.company.trim();
      }
      if (this.isNew || this.manager.trim() !== this.item.manager) {
        doc.manager = this.manager.trim();
      }
      if (this.isNew || this.managerPhone.trim() !== this.item.managerPhone) {
        doc.managerPhone = this.managerPhone.trim();
      }
      if (this.isNew || this.request.trim() !== this.item.request) {
        doc.request = this.request.trim();
      }
      if (this.isNew || this.deliveryDay !== this.item.deliveryDay) {
        doc.deliveryDay = this.deliveryDay;
      }
      if (this.isNew || this.address.trim() !== this.item.address) {
        doc.address = this.address.trim();
      }
      if (this.isNew || this.deliveryMethod !== this.item.deliveryMethod) {
        doc.deliveryMethod = this.deliveryMethod;
      }
      if (this.isNew || this.region.trim() !== this.item.region) {
        doc.region = this.region.trim();
      }
      if (this.isNew || this.receiver.trim() !== this.item.receiver) {
        doc.receiver = this.receiver.trim();
      }
      if (this.isNew || this.receiverPhone.trim() !== this.item.receiverPhone) {
        doc.receiverPhone = this.receiverPhone.trim();
      }
      if (this.isNew || this.note !== this.item.note) {
        doc.note = this.note;
      }
      if (this.isNew || this.director !== this.item.director) {
        doc.director = this.director;
        doc.directorCode = doc.director
          ? this.$store.getters.getUser(this.director).code
          : '';
      }
      if (this.isNew || this.salesNote.trim() !== this.item.salesNote) {
        doc.salesNote = this.salesNote.trim();
      }
      if (this.isNew || this.manufactureCheck !== this.item.manufactureCheck) {
        doc.manufactureCheck = this.manufactureCheck;
      }
      if (
        this.isNew ||
        this.manufactureNote.trim() !== this.item.manufactureNote
      ) {
        doc.manufactureNote = this.manufactureNote.trim();
      }
      if (this.isNew || this.payCheck !== this.item.payCheck) {
        doc.payCheck = this.payCheck;
      }
      if (this.isNew || this.payNote !== this.item.payNote) {
        doc.payNote = this.payNote;
      }
      if (this.isNew || this.waybillNumber.trim() !== this.item.waybillNumber) {
        doc.waybillNumber = this.waybillNumber.trim();
      }
      if (this.isNew || this.cost !== this.item.cost) {
        doc.cost = this.cost;
      }
      if (this.isNew || this.ecountCheck !== this.item.ecountCheck) {
        doc.ecountCheck = this.ecountCheck;
      }
      if (this.isNew || this.shipmentDay !== this.item.shipmentDay) {
        doc.shipmentDay = this.shipmentDay;
      }
      if (this.isNew || this.credit !== this.item.credit) {
        doc.credit = this.credit;
      }
      if (this.isNew || this.buyer !== this.item.buyer) {
        doc.buyer = this.buyer;
      }
      if (this.isNew || this.destination !== this.item.destination) {
        doc.destination = this.destination;
      }
      if (this.isNew || this.shippingPort !== this.item.shippingPort) {
        doc.shippingPort = this.shippingPort;
      }

      this.products = this.products.filter((v) => v.model.length > 0);
      if (this.products.length <= 0) {
        this.$vuetify.goTo(this.$refs.productAddButton, { offset: 100 });
        this.ripple(this.$refs.productAddButton.$el);
        this.$refs.productAddButton.$el.click();
        this.alerting = true;
        this.alertText = this.$t('order-product-add-alert');
        return;
      }
      if (
        this.isNew ||
        JSON.stringify(this.products) !== JSON.stringify(this.item.products)
      ) {
        doc.products = JSON.parse(JSON.stringify(this.products));
      }
      if (this.isNew || this.currency.trim() != this.item.currency) {
        doc.currency = this.currency.trim();
      }
      if (Object.keys(doc).length === 0) return;
      this.updating = true;
      this.reloadOrders();
      this.id = await order.save(doc, this.id, this.user.uid, this.currency);
      this.isNew = false;
      this.updating = false;
      await this.load(this.id);
    },
    permission: function (perm) {
      return this.$store.state.permissions[perm] || false;
    },
    editVendor: function () {
      let self = this;
      if (!self.canEditVendor) return;
      self.vendorEditing = true;
      setTimeout(function () {
        self.$refs.vendorEntry.focus();
      }, 200);
    },
    queryRegions: function (v) {
      this.regionLoading = true;
      region.search(v, 20, (result) => {
        this.regionItems = result;
        this.regionLoading = false;
      });
    },
    loadHits: async function () {
      this.hitLogs = await order.loadHits(this.id);
    },
    loadLogs: async function () {
      let self = this;
      if (!self.id) return;
      let logs = await order.loadLogs(self.id);
      logs.forEach((log) => {
        log.time = datefmt.full(log.time);
        log.text = log.texts
          .map((t) => {
            let colon = t.indexOf(':') + 1;
            if (colon <= 0) return t;
            return (
              '<span class="subtitle-2">' +
              t.slice(0, colon) +
              '</span>' +
              '<span class="text-body-2">' +
              t.slice(colon) +
              '</span>'
            );
          })
          .join('<br>');
      });
      this.logs = logs;
    },
    openMap: function (query) {
      if (!query) return;
      let url = 'https://map.naver.com/v5/search/' + encodeURI(query);
      window.open(url, '_blank');
    },
    openAddress: function () {
      if (!this.canEdit) return;
      let self = this;
      daum.postcode.load(function () {
        new daum.Postcode({
          oncomplete: function (data) {
            let address;
            if (data.userSelectedType === 'J')
              address = data.jibunAddress || data.address;
            else address = data.roadAddress || data.address;
            if (data.buildingName) {
              address += ' ' + data.buildingName;
              self.location = data.buildingName;
            }
            self.address = address;
            self.region = data.bcode;
            self.regionText = region.name(data.bcode);
          },
        }).open({ q: self.address });
      });
      this.$refs.addressTextField.focus();
    },
    callPhone(query) {
      if (!isMobile) return;
      if (!query) return;
      window.location.href = 'tel:' + query;
    },
    addProduct: function () {
      if (!this.canEdit) return;
      this.products.push({
        model: '',
        count: 1,
        unit: 0,
        price: 0,
        note: '',
      });
    },
    removeProduct: function (index) {
      if (!this.canEdit) return;
      this.products.splice(index, 1);
    },
    modelSelected: function (v) {
      let model = product.lookupModel(v.model, this.currency);
      v.unit = model.prices[this.vendorLevel] || 0;
      v.category = model.category || '';
    },
    ripple: function ($el) {
      let ev = new Event('mousedown');
      let offset = $el.getBoundingClientRect();
      ev.clientX = offset.left + offset.width / 2;
      ev.clientY = offset.top + offset.height / 2;
      $el.dispatchEvent(ev);
      setTimeout(function () {
        $el.dispatchEvent(new Event('mouseup'));
      }, 200);
    },
    dayFormat: function (d) {
      return parseInt(d.substr(8, 10));
    },
    getCustomer: async function (id) {
      return await customer.lookupCustomer(id);
    },
    accept: async function () {
      if (!this.canAccept) return;
      this.state = order.ACCEPTED;
      if (this.director == '') this.director = this.user.uid;
      await this.save();
    },
    register: async function () {
      if (!this.canRegister) return;
      let res = confirm(i18n.t('order-register-confirm'));
      if (!res) return;
      this.state = order.REGISTERED;
      await this.save();
    },
    shipment: async function () {
      this.shipmentDay = datefmt.today();
      this.state = order.SHIPMENT;
      await this.save();
    },
    restore: async function () {
      if (!this.canRestore) return;
      let res = confirm(i18n.t('order-restore-confirm'));
      if (!res) return;

      this.shipmentDay = null;
      this.state = order.DRAFT;
      await this.save();
    },
    remove: async function () {
      if (!this.canRemove) return;
      let res = confirm(i18n.t('order-delete-confirm'));
      if (!res) return;

      this.state = order.DELETED;
      let self = this;
      await self.save();
      self.$router.replace('/orders');
    },
    deny: async function () {
      if (!this.canDeny) return;
      let res = prompt(i18n.t('order-deny-reason'));
      if (!res) return;

      this.state = order.DRAFT;
      if (this.note.trim().length == 0)
        this.note += i18n.t('order-deny-reason') + ': ' + res.trim();
      else this.note += '\n' + i18n.t('order-deny-reason') + ': ' + res.trim();
      await this.save();
    },
    complete: async function () {
      if (!this.canComplete) return;

      this.state = order.COMPLETED;
      await this.save();
    },
    reloadOrders: function () {
      this.$store.commit('reloadOrders', true);
    },
    clone: async function () {
      if (this.isNew) return;
      this.$router.push({ path: '/orders/new', query: { src: this.id } });
    },
    lookupWaybill: function () {
      let address = `https://www.ilogen.com/web/personal/trace/${this.waybillNumber.replaceAll(
        '-',
        ''
      )}`;
      window.open(address, '_blank');
    },
    readableSize: function (bytes, si) {
      if (si === undefined) si = true;
      var thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
      var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      var u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + ' ' + units[u];
    },
    onInvoiceFileDropped: function (e) {
      this.invoiceFileDragging = false;

      let files = e.dataTransfer.files;
      if (!files || files.length <= 0) {
        const dragUrl = e.dataTransfer.getData('text/uri-list');
        let self = this;
        this.files.forEach(async (file) => {
          if (file.url == dragUrl) {
            if (file.tags != 'invoice') {
              file.tags = 'invoice';
              await order.updateFileTags(
                self.id,
                self.user.uid,
                file.id,
                file.name,
                file.tags
              );
              self.loadLogs();
            }
          }
        });
        return;
      }
      // this tip, convert FileList to array,
      // credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...files].forEach(async (file) => {
        await this.uploadFile(file, 'invoice');
      });
    },
    onInvoiceFilePicked: function (e) {
      const files = e.target.files;
      if (!files || files.length <= 0) return;

      [...files].forEach(async (file) => {
        await this.uploadFile(file, 'invoice');
      });
    },

    onProductPhotoDropped: function (e) {
      this.productPhotoDragging = false;
      if (!this.canManufacture) return;

      let files = e.dataTransfer.files;
      if (!files || files.length <= 0) {
        const dragUrl = e.dataTransfer.getData('text/uri-list');
        let self = this;
        this.files.forEach(async (file) => {
          if (file.url == dragUrl) {
            if (file.tags != 'product') {
              file.tags = 'product';
              await order.updateFileTags(
                self.id,
                self.user.uid,
                file.id,
                file.name,
                file.tags
              );
              self.loadLogs();
            }
          }
        });
        return;
      }
      // this tip, convert FileList to array,
      // credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...files].forEach(async (file) => {
        await this.uploadFile(file, 'product');
      });
    },
    onProductPhotoPicked: function (e) {
      const files = e.target.files;
      if (!files || files.length <= 0) return;

      [...files].forEach(async (file) => {
        await this.uploadFile(file, 'product');
      });
    },

    uploadFile: async function (file, tags) {
      if (!this.canEdit) return;

      if (file === undefined || file.size <= 0 || !file.name) return;

      // Check duplicate file.
      if (this.files.some((v) => v.name == file.name)) return;

      // Check file types.
      if (
        !/\.(jpg|jpeg|gif|webp|tiff|png|svg|pdf|txt|doc|docx|ppt|pptx|xls|xlsx|hwp|mov|mp4|mkv)$/i.test(
          file.name.toLowerCase()
        )
      ) {
        alert(i18n.t('order-files-hint-type'));
        return;
      }

      // Check file size.
      let total = 0;
      this.files.forEach((v) => (total += v.size));
      if (total + file.size > 100 * 1000 * 1000) {
        alert(i18n.t('order-files-hint-size'));
        return;
      }

      let self = this;
      let uploadFiles = [];
      if (tags == 'product') uploadFiles = self.uploadingProductPhotos;
      else if (tags == 'invoice') uploadFiles = self.uploadingInvoiceFiles;

      uploadFiles.push({
        name: file.name,
        readableSize: self.readableSize(file.size),
        progress: 0,
      });

      await order.uploadFile(
        uploadFiles,
        self.id,
        self.user.uid,
        tags,
        file,
        (uploadFiles) => {
          if (uploadFiles.length == 0) {
            self.loadFiles();
            self.loadLogs();
          }
        }
      );
    },
    deleteFile: async function (file) {
      let res = confirm(i18n.t('order-file-delete-confirm'));
      if (!res) return;

      let self = this;
      self.updating = true;
      await order.deleteFile(self.id, self.user.uid, file);

      self.updating = false;
      self.loadFiles();
      self.loadLogs();
    },
    loadFiles: async function () {
      let files = await order.loadFiles(this.id);
      let self = this;
      self.files = files.map((file) =>
        Object.assign(file, {
          readableSize: self.readableSize(file.size),
        })
      );
    },
    print: async function () {
      if (!this.canView || this.isNew) return;
      let self = this;
      await self.save();

      self.printing = true;
      self.updating = true;
      await order.print(
        self.id,
        self.user.uid,
        this.canManufacture,
        this.currency
      );
      await self.loadLogs();
      self.updating = false;
      self.printing = false;
    },
    registerProject: async function () {
      let doc = {};

      doc.author = this.author;
      doc.vendor = this.vendor;
      doc.address = this.address.trim();
      doc.region = this.region;
      doc.business = this.business.trim();
      doc.products = JSON.parse(JSON.stringify(this.products));
      doc.deadline = datefmt.date(this.registerTime.toDate());
      let notes = [];
      function addNote(v) {
        if (v) notes.push(v);
      }
      addNote(this.company);
      addNote(this.manager);
      addNote(this.managerPhone);
      addNote(this.receiver);
      addNote(this.receiverPhone);
      addNote(datefmt.date(this.registerTime.toDate()));

      doc.note = notes.join('  |  ');

      let self = this;
      self.$router.push({
        path: '/projects/new',
        query: { doc: JSON.stringify(doc) },
      });
    },
    ecount: async function () {
      this.ecountDate = datefmt.today();
      this.ecountDialog = true;
    },
    uploadEcount: async function () {
      let self = this;
      self.ecountDialog = false;

      if (self.ecountUploading || self.updating) return;

      if (self.ecountCheck) {
        self.alertText = i18n.t('order-ecount-upload-complete-check');
        self.alerting = true;
        return;
      }
      self.ecountUploading = true;
      self.updating = true;

      let result = await order.uploadEcount(
        self.id,
        self.ecountVat,
        self.ecountMethod,
        self.ecountDate
      );

      self.updating = false;
      self.ecountUploading = false;
      if (result.data.status == 200) {
        self.alertText = i18n.t('order-ecount-upload-complete');
      } else {
        self.alertText = `Error ${result.data.status} ${result.data.message}`;
      }
      self.alerting = true;
      self.loadLogs();
    },
    vendorCurrencies: function () {
      return (
        this.$store.getters.getVendor(this.$store.state.user.vendor)
          .currencies || ['KRW']
      );
    },
    openForeignDocument: function () {
      this.foreignProducts = JSON.parse(JSON.stringify(this.products));
      this.foreignDocumentDialog = true;
    },
    saveForeignDocumentDialog: function () {
      Object.assign(this.products, this.foreignProducts);
      this.save();
      this.foreignDocumentDialog = false;
    },
    printPL: async function () {
      if (!this.canView || this.isNew) return;
      let self = this;
      await self.save();

      self.printing = true;
      self.updating = true;
      await order.printPL(self.id, self.user.uid, self.currency);
      await self.loadLogs();
      self.updating = false;
      self.printing = false;
    },
    printCI: async function () {
      if (!this.canView || this.isNew) return;
      let self = this;
      await self.save();

      self.printing = true;
      self.updating = true;
      await order.printCI(self.id, self.user.uid, self.currency);
      await self.loadLogs();
      self.updating = false;
      self.printing = false;
    },
  },
};
</script>

<style scoped>
.drag {
  background-color: gray;
}

.separator {
  color: gray;
}
</style>