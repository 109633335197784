import Vue from 'vue';
import Vuex from 'vuex';
import router from './router';
import firebase from './firebase';
import i18n from './i18n';
import region from './region';
import product from './product';
import customer from './customer';
import getUserLocale from 'get-user-locale';

const db = firebase.firestore();
const storage = firebase.storage();
const currencyNames = ['KRW', 'JPY', 'USD'];
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authenticated: false,
    title: i18n.t('emstone-hub'),
    user: {},
    permissions: {
      'project': false,
      'project-add': false,
      'project-view': false,
      'project-edit': false,
      'service': false,
      'service-add': false,
      'service-view': false,
      'service-edit': false,
      'product': false,
      'issue': false,
      'agreement': false,
      'agreement-add': false,
      'agreement-view': false,
      'agreement-edit': false,
      'order': false,
      'order-add': false,
      'order-edit': false,
      'order-manufacture': false,
      'estimate': false,
      'estimate-add': false,
      'estimate-accept': false,
      'estimate-edit': false,
      'license': false,
      'license-edit': false,
      'edns': false,
      'edns-edit': false,
      'user': false,
      'user-edit': false,
      'vendor': false,
      'vendor-edit': false,
      'setting': false,
    },
    settings: {
      email: {},
      product: {},
      ecount: {},
      gchat: {},
    },
    vendors: {},
    vendorItems: [],
    users: {},
    userItems: [],
    initialPath: null,
    initialRouted: false,
    fromRoute: null,
    projectReload: true,
    serviceReload: true,
    agreementReload: true,
    orderReload: true,
    estimateReload: true,
    issueReload: true,
    licenseReload: true,
    licenseIssueReload: true,
    buttons: [],
  },
  getters: {
    getVendor: (state) => (vid) => {
      return state.vendors[vid] || { name: '' };
    },
    lookupVendorId: (state) => (id) => {
      return Object.values(state.vendors).find((v) => v.id == id) || {};
    },
    getUser: (state) => (uid) => {
      return state.users[uid] || { name: '' };
    },
  },
  mutations: {
    gotoInitialPage(state) {
      // Go to initial page after vendors and users are loaded because
      // the vendors and the users has permissions to access route pages.
      if (state.userItems.length <= 0 || state.vendorItems.length <= 0) return;
      if (!state.authenticated) return;
      if (state.initialRouted) return;
      state.initialRouted = true;
      if (state.initialPath == '/login') state.initialPath = '/';
      if (state.initialPath)
        router.replace(state.initialPath).catch(function () { });
    },
    reloadOrders(state, reload) {
      state.orderReload = reload;
    },
    reloadAgreements(state, reload) {
      state.agreementReload = reload;
    },
    reloadProjects(state, reload) {
      state.projectReload = reload;
    },
    reloadServices(state, reload) {
      state.serviceReload = reload;
    },
    reloadEstimates(state, reload) {
      state.estimateReload = reload;
    },
    reloadIssues(state, reload) {
      state.issueReload = reload;
    },
    reloadLicenses(state, reload) {
      state.licenseReload = reload;
    },
    reloadLicenseIssue(state, reload) {
      state.licenseIssueReload = reload;
    },
    setFromRoute(state, route) {
      state.fromRoute = route;
    },
    setTitle(state, title) {
      state.title = title;
      let hub = i18n.t('emstone-hub');
      if (title == hub) document.title = hub;
      else document.title = title + ' | ' + hub;
    },
    setButtons(state, buttons) {
      state.buttons = buttons;
    },
    clearButtons(state) {
      state.buttons = [];
    },
    updatePermission(state) {
      // Initialize all permissions with admin state.
      Object.keys(state.permissions).forEach(function (permission) {
        state.permissions[permission] = state.user.admin || false;
      });

      // Merge vendor permissions.
      let vendor = state.vendors[state.user.vendor] || {};
      if (!vendor.permissions) vendor.permissions = [];
      vendor.permissions.forEach(function (permission) {
        state.permissions[permission] = true;
      });

      // Merge user permissions.
      if (!state.user.permissions) state.user.permissions = [];
      state.user.permissions.forEach(function (permission) {
        state.permissions[permission] = true;
      });

      if (state.permissions['project-add']) state.permissions['project'] = true;
      if (state.permissions['project-view'])
        state.permissions['project'] = true;
      if (state.permissions['project-edit']) {
        state.permissions['project'] = true;
        state.permissions['project-add'] = true;
        state.permissions['project-view'] = true;
      }


      if (state.permissions['service-view']) state.permissions['service'] = true;
      if (state.permissions['service-add']) {
        state.permissions['service'] = true;
        state.permissions['service-view'] = true;
      }
      if (state.permissions['service-edit']) {
        state.permissions['service'] = true;
        state.permissions['service-add'] = true;
        state.permissions['service-view'] = true;
      }

      if (state.permissions['agreement-add']) state.permissions['agreement'] = true;
      if (state.permissions['agreement-view'])
        state.permissions['agreement'] = true;
      if (state.permissions['agreement-edit']) {
        state.permissions['agreement'] = true;
        state.permissions['agreement-add'] = true;
        state.permissions['agreement-view'] = true;
      }

      if (state.permissions['order-add']) state.permissions['order'] = true;
      if (state.permissions['order-manufacture']) state.permissions['order'] = true;
      if (state.permissions['order-edit']) {
        state.permissions['order'] = true;
        state.permissions['order-add'] = true;
        state.permissions['order-manufacture'] = true;
      }

      if (state.permissions['estimate-add']) state.permissions['estimate'] = true;
      if (state.permissions['estimate-accept']) {
        state.permissions['estimate'] = true;
        state.permissions['estimate-add'] = true;
      }
      if (state.permissions['estimate-edit']) {
        state.permissions['estimate'] = true;
        state.permissions['estimate-add'] = true;
        state.permissions['estimate-accept'] = true;
      }

      if (state.permissions['license-edit']) state.permissions['license'] = true;
      if (state.permissions['user-edit']) state.permissions['user'] = true;
      if (state.permissions['vendor-edit']) state.permissions['vendor'] = true;
      if (state.permissions['edns-edit']) state.permissions['edns'] = true;
    },
    setUser(state, user) {
      if (user) {
        if (state.users)
          state.users[user.uid] = user;

        let vendor = state.vendors[user.vendor];
        user.vendorName = vendor ? vendor.name : '';

        state.user = user;
        state.authenticated = true;

        if (!user.locale || user.locale == '') {
          i18n.locale = getUserLocale().slice(0, 2);
        } else if (user.locale != i18n.locale) {
          i18n.locale = user.locale;
        }
      } else {
        state.user = {};
        state.authenticated = false;
      }
    },
    setVendors(state, vendors) {
      state.vendors = vendors || {};

      let vendor = state.vendors[state.user.vendor];
      state.user.vendorName = vendor ? vendor.name : '';

      state.vendorItems = Object.values(vendors)
        .map((vendor) => {
          return {
            value: vendor.vid,
            text: vendor.name + ' | ' + vendor.id,
            label: vendor.name,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
    },
    setUsers(state, users) {
      state.users = users || {};

      state.userItems = Object.values(users)
        .filter((user) => !user.disabled)
        .map((user) => {
          return {
            value: user.uid,
            text: user.name + ' | ' + user.email,
            label: user.name,
            vendor: user.vendor,
            code: user.code || '',
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
    },
    setSettings(state, settings) {
      state.settings = Object.assign({
        email: {},
        product: {},
        ecount: {},
        gchat: {},
      }, settings);
    },
  },
  actions: {
    logout({ }) {
      firebase.auth().signOut();
    },
    updateProfilePhoto({ }, profile) {
      return new Promise((resolve, reject) => {
        if (!profile.photoFile) {
          resolve(profile.photoURL);
          return;
        }
        const filename = '/photo.' + profile.photoFile.name.split('.').pop();
        const storageRef = storage.ref();
        const ref = storageRef.child('users/' + profile.uid + filename);
        ref
          .put(profile.photoFile)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (url) {
              resolve(url);
            });
          })
          .catch(function (error) {
            alert('Error uploading photo: ' + error);
            reject(error);
          });
      });
    },
    updateProfile({ commit, dispatch }, profile) {
      return new Promise((resolve, reject) => {
        dispatch('updateProfilePhoto', profile).then((url) => {
          var docRef = db.collection('users').doc(profile.uid);
          docRef
            .update({
              name: profile.name,
              photoURL: url,
              receiveEmails: profile.receiveEmails,
              preferTheme: profile.preferTheme,
              locale: profile.locale || '',
            })
            .then(function () {
              docRef.get().then(function (doc) {
                commit('setUser', doc.data());
                resolve();
              });
            })
            .catch(function (error) {
              reject();
              alert('Error updating user: ' + error);
            });
        });
      });
    },

    async loadVendors({ commit }) {
      let vendors = {};
      const snapshot = await db.collection('vendors').get();
      snapshot.forEach(function (doc) {
        let vendor = doc.data();
        vendor.vid = doc.id;
        vendor.level = vendor.level || '';
        vendors[vendor.vid] = vendor;
      });
      commit('setVendors', vendors);
    },

    async loadUsers({ commit }) {
      let users = {};
      const snapshot = await db.collection('users').get();
      snapshot.forEach(function (doc) {
        let user = doc.data();
        let u = {
          uid: user.uid,
          name: user.name,
          email: user.email,
          vendor: user.vendor,
          disabled: user.disabled,
          photoURL: user.photoURL,
          admin: user.admin,
          code: user.code || '',
          loginTime: user.loginTime,
          account: user.account || '',
          preferTheme: user.preferTheme || '',
          locale: user.locale || '',
          receiveEmails: user.receiveEmails !== false,
          permissions: user.permissions || [],
        };
        users[u.uid] = u;
      });
      commit('setUsers', users);
    },

    log({ state }, params) {
      return new Promise((resolve, reject) => {
        db.collection('logs')
          .add({
            time: firebase.firestore.FieldValue.serverTimestamp(),
            uid: state.user.uid,
            module: params.module || '',
            id: params.id || '',
            text: params.text || '',
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async loadSettings({ commit }) {
      const doc = await db.collection('admin').doc('settings').get();
      let data = doc.data();

      await Promise.all(currencyNames.map(async (currency) => {
        const productDoc = await db
          .collection('products')
          .doc('settings-' + currency)
          .get();
        data['product'].categories[currency] = productDoc.data().categories;
      }));

      commit('setSettings', data);
    },

    async saveSettings({ commit }, settings) {
      await db.collection('admin').doc('settings').update(settings);

      await Promise.all(currencyNames.map(async (currency) => {
        await db
          .collection('products')
          .doc('settings-' + currency)
          .update({ categories: settings['product'].categories[currency] });
      }));
      const doc = await db.collection('admin').doc('settings').get();
      commit('setSettings', doc.data());
    }
  },
});
export default store;

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => !record.meta.ignoreAuth)) {
    next();
    return;
  }
  if (!store.state.authenticated) {
    if (!store.state.initialPath) store.state.initialPath = to.fullPath;
    next('/');
    return;
  }
  if (store.state.user.disabled) {
    store.state.initialPath = null;
    next('/denied');
    return;
  }
  let route = to.matched.find((record) => record.meta.permission);
  if (route && !store.state.permissions[route.meta.permission]) {
    store.state.initialPath = null;
    next('/denied');
    return;
  }
  next();
});

router.afterEach((to, from) => {
  store.commit('setFromRoute', from);
  store.commit('setTitle', i18n.t(to.name));

  if (to.fullPath == store.state.initialPath) store.state.initialPath = null;
});

firebase.authCallback = async function (user) {
  if (user) {
    await Promise.all([
      store.dispatch('loadSettings'),
      store.dispatch('loadVendors'),
      store.dispatch('loadUsers'),
      customer.init(),
      region.init(),
    ]);

    await product.init();

    let data = store.getters.getUser(user.uid);
    if (data.uid == user.uid) {
      db.collection('users').doc(user.uid).update({
        loginTime: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } else {
      data = {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        photoURL: user.photoURL,
        account:
          user.providerData[0].providerId +
          '-' +
          user.providerData[0].uid,
        disabled: false,
        admin: false,
        createTime: firebase.firestore.FieldValue.serverTimestamp(),
        loginTime: firebase.firestore.FieldValue.serverTimestamp(),
      };
      db.collection('users').doc(user.uid).set(data);
    }
    store.commit('setUser', data);
    store.commit('updatePermission');
    store.commit('gotoInitialPage');
  } else {
    store.commit('setUser', null);
    store.commit('updatePermission');
    store.commit('setSettings', null);
    router.push('/login').catch(function () { });
  }
};
