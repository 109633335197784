import i18n from './i18n';
import firebase from './firebase';
import store from './store';
import region from '@/region';
import customer from './customer';
import datefmt from '@/datefmt';
import sanitize from 'sanitize-filename';
import { saveAs } from 'file-saver';
import product from './product';

const db = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

const ALL = -999;
const DELETED = -1;
const DRAFT = 0;
const REGISTERED = 1;
const ACCEPTED = 2;
const COMPLETED = 3;
const SHIPMENT = 4;

const stateNames = {};
stateNames[ALL] = i18n.t('order-state-all');
stateNames[DELETED] = i18n.t('order-state-deleted');
stateNames[DRAFT] = i18n.t('order-state-draft');
stateNames[REGISTERED] = i18n.t('order-state-registered');
stateNames[ACCEPTED] = i18n.t('order-state-accepted');
stateNames[COMPLETED] = i18n.t('order-state-completed');
stateNames[SHIPMENT] = i18n.t('order-state-shipment');

const stateCodes = {};
stateCodes[ALL] = 'all';
stateCodes[DELETED] = 'deleted';
stateCodes[DRAFT] = 'draft';
stateCodes[REGISTERED] = 'registered';
stateCodes[ACCEPTED] = 'accepted';
stateCodes[COMPLETED] = 'completed';
stateCodes[SHIPMENT] = 'shipment';

const stateForCodes = {};
stateForCodes['all'] = ALL;
stateForCodes['deleted'] = DELETED;
stateForCodes['draft'] = DRAFT;
stateForCodes['registered'] = REGISTERED;
stateForCodes['accepted'] = ACCEPTED;
stateForCodes['completed'] = COMPLETED;
stateForCodes['shipment'] = SHIPMENT;


const stateColors = {};
stateColors[ALL] = '';
stateColors[DELETED] = '';
stateColors[DRAFT] = '';
stateColors[REGISTERED] = '';
stateColors[ACCEPTED] = 'info';
stateColors[COMPLETED] = 'success';
stateColors[SHIPMENT] = 'success';

const stateTextColors = {};
stateTextColors[ALL] = '';
stateTextColors[DELETED] = 'white';
stateTextColors[DRAFT] = '';
stateTextColors[REGISTERED] = 'white';
stateTextColors[ACCEPTED] = 'white';
stateTextColors[COMPLETED] = 'white';
stateTextColors[SHIPMENT] = 'white';

const states = [];
states.push('ALL');
states.push('DELETED');
states.push('DRAFT');
states.push('REGISTERED');
states.push('ACCEPTED');
states.push('COMPLETED');
states.push('SHIPMENT');

const order = {
  ALL: ALL,
  DELETED: DELETED,
  DRAFT: DRAFT,
  REGISTERED: REGISTERED,
  ACCEPTED: ACCEPTED,
  COMPLETED: COMPLETED,
  SHIPMENT: SHIPMENT,

  stateCode: function (state) {
    return stateCodes[state] || '';
  },
  stateForCode: function (code) {
    return stateForCodes[code];
  },
  stateName: function (state) {
    return stateNames[state] || '';
  },
  stateColor: function (state) {
    return stateColors[state] || '';
  },
  stateTextColor: function (state) {
    return stateTextColors[state] || '';
  },

  save: async function (item, id, uid, currency) {
    let log = [];
    if (item.state != null) {
      log.push('state:' + item.state);
      item.registerTime = firebase.firestore.FieldValue.serverTimestamp();

      if (item.state == ACCEPTED)
        item.acceptDate = firebase.firestore.FieldValue.serverTimestamp();
    }
    if (item.vendor != null) {
      log.push('vendor:' + item.vendor);
    }
    if (item.customer != null) {
      log.push(i18n.t('order-customer') + ': ' + (customer.lookupCustomer(item.customer)).name);
    }
    if (item.business != null) {
      log.push(i18n.t('order-business') + ': ' + item.business);
    }
    if (item.company != null) {
      log.push(i18n.t('order-company') + ': ' + item.company);
    }
    if (item.manager != null) {
      log.push(i18n.t('order-manager') + ': ' + item.manager);
    }
    if (item.managerPhone != null) {
      log.push(i18n.t('order-manager-phone') + ': ' + item.managerPhone);
    }
    if (item.products != null) {
      log.push(i18n.t('order-products') + ': ' + item.products.map(function (v) {
        let note = (v.note && v.note !== '') ? `(${v.note})` : '';
        let changeLog = `${v.model} ${v.unit} x ${v.count} ${order.currencySymbol(currency, v.price)} ${note}`;
        if (v.hsCode || v.weight || v.totalWeight || v.measurement)
          changeLog += `[ ${i18n.t('order-product-hs-code')}: ${v.hsCode}, ${i18n.t('order-product-total-weight')}: ${v.totalWeight},
                          ${i18n.t('order-product-measurement')}: ${v.measurement}]`
        changeLog = [changeLog];
        return changeLog.join(', ');
      }).join(' | '));
    }
    if (item.cost != null) {
      let costLog = currency == 'KRW' ?
        i18n.t('order-product-cost-vat') :
        i18n.t('order-product-cost');
      log.push(costLog + order.currencySymbol(currency, item.cost));
    }
    if (item.request != null) {
      log.push(i18n.t('order-request') + ': ' + item.request.split('\n').join(' '));
    }
    if (item.address != null) {
      log.push(i18n.t('order-address') + ': ' + item.address);
    }
    if (item.region != null) {
      log.push(i18n.t('order-region') + ': ' + region.name(item.region));
    }
    if (item.deliveryMethod != null) {
      log.push(i18n.t('order-delivery-method') + ': ' + order.deliveryMethodName(currency, item.deliveryMethod));
    }
    if (item.receiver != null) {
      log.push(i18n.t('order-receiver') + ': ' + item.receiver);
    }
    if (item.receiverPhone != null) {
      log.push(i18n.t('order-receiver-phone') + ': ' + item.receiverPhone);
    }
    if (item.deliveryDay != null) {
      log.push(i18n.t('order-delivery-day') + ': ' + item.deliveryDay);
    }
    if (item.director != null) {
      log.push(i18n.t('order-director') + ': ' + store.getters.getUser(item.director).name);
    }
    if (item.salesNote != null) {
      log.push(i18n.t('order-sales-note') + ': ' + item.salesNote.split('\n').join(' '));
    }
    if (item.manufactureCheck != null) {
      log.push(i18n.t('order-manufacture-check') + ': ' + item.manufactureCheck);
    }
    if (item.manufactureNote != null) {
      log.push(i18n.t('order-manufacture-note') + ': ' + item.manufactureNote.split('\n').join(' '));
    }
    if (item.waybillNumber != null) {
      log.push(i18n.t('order-waybill-number') + ': ' + item.waybillNumber);
    }
    if (item.payCheck != null) {
      log.push(i18n.t('order-pay-check') + ': ' + item.payCheck);
    }
    if (item.payNote != null) {
      log.push(i18n.t('order-pay-note') + ': ' + item.payNote);
    }
    if (item.note != null) {
      log.push(i18n.t('order-note') + ': ' + item.note.split('\n').join(' '));
    }
    if (item.ecountCheck != null) {
      log.push(i18n.t('order-ecount-upload') + ': ' + item.ecountCheck);
    }
    if (item.shipmentDay != null) {
      log.push(i18n.t('order-shipment-day') + ': ' + item.shipmentDay);
      item.shipmentDay = firebase.firestore.Timestamp.fromDate(new Date(item.shipmentDay));
    }
    if (item.currency != null) {
      log.push(i18n.t('order-currency') + ': ' + item.currency.split('\n').join(' '));
    }
    if (currency != 'KRW' && item.credit != null) {
      log.push(i18n.t('order-credit') + ': ' + item.credit.split('\n').join(' '));
    }
    if (currency != 'KRW' && item.buyer != null) {
      log.push(i18n.t('order-buyer') + ': ' + item.buyer.split('\n').join(' '));
    }
    if (currency != 'KRW' && item.destination != null) {
      log.push(i18n.t('order-destination') + ': ' + item.destination.split('\n').join(' '));
    }
    if (currency != 'KRW' && item.shippingPort != null) {
      log.push(i18n.t('order-shipping-port') + ': ' + item.shippingPort.split('\n').join(' '));
    }

    if (log.length <= 0) return;

    if (!id) {
      item.registerTime = firebase.firestore.FieldValue.serverTimestamp();
      let newRef = await db.collection('orders').add(item);
      id = newRef.id;
    }
    else
      await db.collection('orders').doc(id).update(item);

    let logText = log.join('\n');
    await this.log(id, uid, logText);
    return id;
  },
  load: async function (id) {
    let doc = await db.collection('orders').doc(id).get();
    let order = doc.data();
    order.id = id;
    return order;
  },
  list: async function (condition) {
    if (condition.search.length > 0) {
      const res = await functions.httpsCallable('orderSearch')(condition);
      return res.data;
    }

    let docs = [];
    let last = null;

    let ref = await this.dbRef(condition);

    if (condition.last) {
      const docRef = await db.collection('orders').doc(condition.last).get();
      if (docRef.exists) {
        ref = ref.startAfter(docRef);
      }
    }

    let limit = condition.limit != undefined ? condition.limit : 20;
    if (limit > 0)
      ref = ref.limit(limit);

    const snapshot = await ref.get();
    snapshot.docs.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      data.registerTime = data.registerTime.toDate();
      if (data.shipmentDay && data.shipmentDay.toDate)
        data.shipmentDay = data.shipmentDay.toDate();

      data.products = JSON.parse(JSON.stringify(data.products)) || [];
      docs.push(data);
    });

    if (docs.length > 0 && docs.length == limit)
      last = docs[docs.length - 1].id;

    return { docs: docs, last: last };
  },
  dbRef: async function (condition) {
    let ref = db.collection('orders');
    if (condition.author) ref = ref.where('author', '==', condition.author);
    if (condition.vendor) ref = ref.where('vendor', '==', condition.vendor);
    if (condition.state != order.ALL) ref = ref.where('state', '==', condition.state);
    else ref = ref.where('state', 'in',
      [order.REGISTERED, order.ACCEPTED, order.COMPLETED, order.SHIPMENT]);
    if (condition.payCheck != 0) ref = ref.where('payCheck', '==', condition.payCheck == 1 ? true : false);
    if (condition.manufactureCheck != 0) ref = ref.where('manufactureCheck', '==', condition.manufactureCheck == 1 ? true : false);
    if (condition.director) ref = ref.where('director', '==', condition.director);
    if (condition.ecountCheck) ref = ref.where('ecountCheck', '==', condition.ecountCheck == 1 ? true : false);

    if (condition.duration > 0) {
      switch (condition.duration) {
        case 1: // day
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            until.setDate(until.getDate() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 2: // month
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            until.setDate(1);
            until.setMonth(until.getMonth() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 3: // quarter
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 3) * 3);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 3) + 1) * 3);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 4: // half-year
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 6) * 6);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 6) + 1) * 6);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 5: // year
          {
            let since = new Date(condition.date);
            let until = new Date(condition.date);
            since.setDate(1);
            since.setMonth(0);
            until.setDate(1);
            until.setMonth(0);
            until.setFullYear(until.getFullYear() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
        case 6: // custom
          {
            let since = new Date(condition.date);
            let until = new Date(condition.untilDate);
            if (since.getTime() > until.getTime()) {
              let tmp = since;
              since = until;
              until = tmp;
            }
            until.setDate(until.getDate() + 1);
            ref = ref.where('registerTime', '>=', since);
            ref = ref.where('registerTime', '<', until);
          }
          break;
      }
      ref = ref.orderBy('registerTime', 'desc');
    } else if (condition.shipmentDuration > 0) {
      ref = ref.where('state', '==', order.SHIPMENT);
      switch (condition.shipmentDuration) {
        case 1: // day
          {
            let since = new Date(condition.shipmentDate);
            let until = new Date(condition.shipmentDate);
            until.setDate(until.getDate() + 1);
            ref = ref.where('shipmentDay', '>=', since);
            ref = ref.where('shipmentDay', '<', until);
          }
          break;
        case 2: // month
          {
            let since = new Date(condition.shipmentDate);
            let until = new Date(condition.shipmentDate);
            since.setDate(1);
            until.setDate(1);
            until.setMonth(until.getMonth() + 1);
            ref = ref.where('shipmentDay', '>=', since);
            ref = ref.where('shipmentDay', '<', until);
          }
          break;
        case 3: // quarter
          {
            let since = new Date(condition.shipmentDate);
            let until = new Date(condition.shipmentDate);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 3) * 3);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 3) + 1) * 3);
            ref = ref.where('shipmentDay', '>=', since);
            ref = ref.where('shipmentDay', '<', until);
          }
          break;
        case 4: // half-year
          {
            let since = new Date(condition.shipmentDate);
            let until = new Date(condition.shipmentDate);
            since.setDate(1);
            since.setMonth(Math.floor(since.getMonth() / 6) * 6);
            until.setDate(1);
            until.setMonth((Math.floor(until.getMonth() / 6) + 1) * 6);
            ref = ref.where('shipmentDay', '>=', since);
            ref = ref.where('shipmentDay', '<', until);
          }
          break;
        case 5: // year
          {
            let since = new Date(condition.shipmentDate);
            let until = new Date(condition.shipmentDate);
            since.setDate(1);
            since.setMonth(0);
            until.setDate(1);
            until.setMonth(0);
            until.setFullYear(until.getFullYear() + 1);
            ref = ref.where('shipmentDay', '>=', since);
            ref = ref.where('shipmentDay', '<', until);
          }
          break;
        case 6: // custom
          {
            let since = new Date(condition.shipmentDate);
            let until = new Date(condition.shipmentUntilDate);
            if (since.getTime() > until.getTime()) {
              let tmp = since;
              since = until;
              until = tmp;
            }
            until.setDate(until.getDate() + 1);
            ref = ref.where('shipmentDay', '>=', since);
            ref = ref.where('shipmentDay', '<', until);
          }
          break;
      }
      ref = ref.orderBy('shipmentDay', 'desc');
    } else
      ref = ref.orderBy('registerTime', 'desc');

    return ref;
  },
  log: async function (id, uid, text) {
    await db.collection('orders')
      .doc(id)
      .collection('logs')
      .add({
        time: firebase.firestore.FieldValue.serverTimestamp(),
        uid: uid,
        text: text,
      })
  },
  loadLogs: async function (id) {
    let logs = [];
    let snapshot = await db.collection('orders').doc(id).collection('logs').orderBy('time').get();
    snapshot.forEach(function (doc) {
      let data = doc.data();
      let user = store.getters.getUser(data.uid);
      let log = {
        time: data.time.toDate ? data.time.toDate() : data.time,
        user: user.name,
        userVendor: store.getters.getVendor(user.vendor).name,
        photoURL: user.photoURL,
      };
      let texts = data.text.split('\n');
      let manufacturePermission = store.state.permissions['order-manufacture'];

      if (texts[0] && texts[0].startsWith('state:')) {
        log.state = parseInt(texts[0].split(':')[1]);
        texts.splice(0, 1);
      }
      if (texts[0] && texts[0].startsWith('vendor:')) {
        let vendor = texts[0].split(':')[1];
        log.vendor = store.getters.getVendor(vendor).name;
        texts.splice(0, 1);
      }

      if (!manufacturePermission) {
        texts = texts.filter((text) =>
          !text.startsWith(i18n.t('order-sales-note') + ':')
          && !text.startsWith(i18n.t('order-manufacture-note') + ':')
          && !text.startsWith(i18n.t('order-product-photo-add') + ':')
          && !text.startsWith(i18n.t('order-director') + ':'));
      }

      log.texts = texts;
      logs.push(log);
    });
    return logs;
  },
  hit: async function (id, uid) {
    let ref = db.collection('orders').doc(id);
    let hitRef = ref.collection('hits').doc(uid);
    let doc = await hitRef.get()
    if (doc.exists) {
      hitRef.update({
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
      return;
    }
    await hitRef.set({
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: uid,
    });
  },
  loadHits: async function (id) {
    let hitLogs = [];
    let docRef = db.collection('orders').doc(id).collection('hits');
    let snapshot = await docRef.orderBy('time', 'asc').get();

    snapshot.forEach(function (doc) {
      let data = doc.data();
      let user = store.getters.getUser(data.uid);
      let hit = {
        time: data.time ? datefmt.full(data.time.toDate()) : '',
        user: user.name,
        vendor: store.getters.getVendor(user.vendor).name,
      };
      hitLogs.push(hit);
    });
    return hitLogs;
  },
  loadStateCounts: async function () {
    let doc = await db.collection('admin').doc('order').get();
    return doc.data();
  },
  subscribe: function (id, callback) {
    let first = true;
    return db.collection('orders').doc(id).onSnapshot((doc) => {
      // Only server change.
      if (!doc.metadata.hasPendingWrites) {
        // The first snapshot change is always called.
        if (first)
          first = false;
        else
          callback(doc.data());
      }
    });
  },
  unsubscribe: function (listener) {
    typeof (listener) === 'function' && listener();
  },

  addFile: async function (id, uid, name, size, tags, url) {
    await db.collection('orders').doc(id)
      .collection('files').add({
        time: firebase.firestore.FieldValue.serverTimestamp(),
        name: name,
        size: size,
        tags: tags,
        url: url,
      })
    await this.log(id, uid,
      i18n.t('order-' + tags + '-file') +
      ': ' +
      name +
      ' ' +
      i18n.t('order-file-added')
    )
  },

  deleteFile: async function (id, uid, file) {
    await storage
      .ref()
      .child('orders/' + id + '/' + file.name)
      .delete();

    await db.collection('orders').doc(id).collection('files').doc(file.id).delete()
    await order.log(id, uid,
      i18n.t('order-' + file.tags + '-file') +
      ': ' +
      file.name +
      ' ' +
      i18n.t('order-file-deleted')
    )
  },

  updateFileTags: async function (id, uid, fid, name, tags) {
    await db.collection('orders').doc(id).collection('files').doc(fid)
      .update({
        tags: tags,
      });
    await order.log(id, uid,
      i18n.t('order-' + tags + '-file') +
      ': ' +
      name +
      ' ' +
      i18n.t('order-file-moved')
    )
  },

  loadFiles: async function (id) {
    let files = [];

    let docRef = db.collection('orders').doc(id).collection('files');
    let snapshot = await docRef.orderBy('time').get();
    snapshot.forEach(function (doc) {
      let data = doc.data();
      let file = {
        id: doc.id,
        name: data.name,
        size: data.size,
        tags: data.tags || 'attached',
        url: data.url,
      };
      files.push(file);
    });

    return files;
  },
  uploadFile: function (files, id, uid, tags, file, callback) {
    let task = storage.ref().child('orders/' + id + '/' + file.name).put(file);
    task.on(
      'state_changed',
      (sp) => {
        files.find((f) => f.name == file.name).progress = Math.floor(
          (sp.bytesTransferred / sp.totalBytes) * 100
        );
      },
      function (error) {
        files.splice(
          files.findIndex((f) => f.name == file.name),
          1
        );
        console.log(error.message || error || 'unknown');
        callback(files);
      },
      async function () {
        let url = await task.snapshot.ref.getDownloadURL();
        await order.addFile(id, uid, file.name, file.size, tags, url)
        files.splice(
          files.findIndex((f) => f.name == file.name),
          1
        );
        callback(files);
      }
    );
  },
  deliveryMethodName: function (currency, num) {
    let deliveryMethod = '';
    let type = parseInt(num);
    if (currency != 'KRW') {
      switch (type) {
        case 0:
          deliveryMethod = i18n.t('order-delivery-vessel');
          break;
        case 1:
          deliveryMethod = i18n.t('order-delivery-flight');
          break;
      }
    }
    else {
      switch (type) {
        case 0:
          deliveryMethod = i18n.t('order-delivery-courier');
          break;
        case 1:
          deliveryMethod = i18n.t('order-delivery-quick');
          break;
        case 2:
          deliveryMethod = i18n.t('order-delivery-pickup');
          break;
        case 3:
          deliveryMethod = i18n.t('order-delivery-etc');
          break;
      }
    }
    return deliveryMethod;
  },
  exportXLSX: async function (condition, merge) {
    let snapshot = await this.list(condition);
    let docs = snapshot.docs;

    const ExcelJS = await import(/* webpackChunkName: "exceljs" */ 'exceljs');
    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet('Sheet1');

    let startNum = 0;
    let endNum = 0;

    let header = [
      { header: i18n.t('order-customer'), key: 'customer', width: 20 },
      { header: i18n.t('order-business'), key: 'business', width: 20 },
      { header: i18n.t('order-company'), key: 'company', width: 20 },
      { header: i18n.t('order-manager'), key: 'manager', width: 20 },
      { header: i18n.t('order-manager-phone'), key: 'managerPhone', width: 20 },

      { header: i18n.t('order-product-model'), key: 'productModel', width: 20 },
      { header: i18n.t('order-product-unit'), key: 'productUnit', width: 20 },
      { header: i18n.t('order-product-count'), key: 'productCount', width: 20 },
      { header: i18n.t('order-product-price'), key: 'productPrice', width: 10 },
      { header: i18n.t('order-product-note'), key: 'productNote', width: 20 },

      { header: i18n.t('order-product-cost'), key: 'productCost', width: 20 },

      { header: i18n.t('order-address'), key: 'address', width: 20 },
      { header: i18n.t('order-delivery-method'), key: 'deliveryMethod', width: 20 },
      { header: i18n.t('order-receiver'), key: 'receiver', width: 20 },
      { header: i18n.t('order-receiver-phone'), key: 'receiverPhone', width: 20 },
      { header: i18n.t('order-delivery-day'), key: 'deliveryDay', width: 20 },
      { header: i18n.t('order-shipment-day'), key: 'shipmentDay', width: 20 },
    ];
    header.push({ header: i18n.t('order-director'), key: 'director', width: 20 });
    if (store.state.user.admin) {
      header.push({ header: i18n.t('order-sales-note'), key: 'salesNote', width: 20 });
      header.push({ header: i18n.t('order-manufacture-note'), key: 'manufactureNote', width: 20 });
    }
    header.push({ header: i18n.t('order-waybill-number'), key: 'waybillNumber', width: 20 });
    header.push({ header: i18n.t('order-note'), key: 'note', width: 20 });

    if (store.state.user.admin) {
      header.push({ header: i18n.t('order-pay-check'), key: 'payCheck', width: 20 });
      header.push({ header: i18n.t('order-pay-note'), key: 'payNote', width: 20 });

      header.push({ header: i18n.t('order-manufacture-check'), key: 'manufactureCheck', width: 20 });
      header.push({ header: i18n.t('order-ecount-upload-dialog-title'), key: 'ecountCheck', width: 20 });
      header.push({ header: i18n.t('order-accept-date'), key: 'acceptDate', width: 20 });
    }

    workSheet.columns = header

    const items = docs.map((v) => {
      let customerName = customer.lookupCustomer(v.customer);
      v.customerName = customerName.name;
      v.directorName = store.getters.getUser(v.director).name;
      return v;
    }).sort((a, b) =>
      a.registerTime.seconds > b.registerTime.seconds ? 1 : -1
    );

    let init = true;
    items.forEach(async function (item) {
      let model = '';
      let count = 0;
      let note = '';
      let unit = 0;
      let price = 0;

      item.acceptDate = '';

      if (item.products.length > 0) {
        if (init) {
          startNum = 2;
          init = false;
        }
        else startNum = endNum + 1;

        item.products.forEach((v) => {
          model = v.model;
          count = parseInt(v.count);
          note = v.note;
          unit = v.unit;
          price = v.price;

          workSheet.addRow({
            customer: item.customerName,
            business: item.business,
            company: item.company,
            manager: item.manager,
            managerPhone: item.managerPhone,

            productModel: model,
            productUnit: unit,
            productCount: count,
            productPrice: price,
            productNote: note,

            productCost: item.cost,

            address: item.address,
            deliveryMethod: order.deliveryMethodName(item.currency, item.deliveryMethod),
            receiver: item.receiver,
            receiverPhone: item.receiverPhone,
            deliveryDay: item.deliveryDay,
            shipmentDay: item.shipmentDay,

            director: item.directorName,
            salesNote: item.salesNote,
            manufactureNote: item.manufactureNote,
            waybillNumber: item.waybillNumber,

            note: item.note,

            payCheck: item.payCheck ? i18n.t('order-pay-check') : '',
            payNote: item.payNote,

            manufactureCheck: item.manufactureCheck ? i18n.t('order-manufacture-check') : '',
            ecountCheck: item.ecountCheck ? i18n.t('order-ecount-item-1') : '',
            acceptDate: item.acceptDate ?
              (item.acceptDate._seconds || item.acceptDate.seconds) ?
                datefmt.date(new Date((item.acceptDate._seconds || item.acceptDate.seconds) * 1000)) :
                item.acceptDate
              : '',
          });
        })
        endNum = item.products.length + startNum - 1;
        if (item.products.length > 1 && merge) {
          workSheet.mergeCells(
            `A${startNum}:A${endNum}`
          );
          workSheet.mergeCells(
            `B${startNum}:B${endNum}`
          );
          workSheet.mergeCells(
            `C${startNum}:C${endNum}`
          );
          workSheet.mergeCells(
            `D${startNum}:D${endNum}`
          );
          workSheet.mergeCells(
            `E${startNum}:E${endNum}`
          );
          workSheet.mergeCells(
            `K${startNum}:K${endNum}`
          );
          workSheet.mergeCells(
            `L${startNum}:L${endNum}`
          );
          workSheet.mergeCells(
            `M${startNum}:M${endNum}`
          );
          workSheet.mergeCells(
            `N${startNum}:N${endNum}`
          );
          workSheet.mergeCells(
            `O${startNum}:O${endNum}`
          );
          workSheet.mergeCells(
            `P${startNum}:P${endNum}`
          );
          workSheet.mergeCells(
            `Q${startNum}:Q${endNum}`
          );
          workSheet.mergeCells(
            `R${startNum}:R${endNum}`
          );
          if (store.state.user.admin) {
            workSheet.mergeCells(
              `S${startNum}:S${endNum}`
            );
            workSheet.mergeCells(
              `T${startNum}:T${endNum}`
            );
            workSheet.mergeCells(
              `U${startNum}:U${endNum}`
            );
            workSheet.mergeCells(
              `V${startNum}:V${endNum}`
            );
            workSheet.mergeCells(
              `W${startNum}:W${endNum}`
            );
            workSheet.mergeCells(
              `X${startNum}:X${endNum}`
            );
            workSheet.mergeCells(
              `Y${startNum}:Y${endNum}`
            );
            workSheet.mergeCells(
              `Z${startNum}:Z${endNum}`
            );
            workSheet.mergeCells(
              `AA${startNum}:AA${endNum}`
            );
          }
          else {
            workSheet.mergeCells(
              `S${startNum}:S${endNum}`
            );
            workSheet.mergeCells(
              `T${startNum}:T${endNum}`
            );
          }
        }
      } else {
        workSheet.addRow({
          customer: item.customerName,
          business: item.business,
          company: item.company,
          manager: item.manager,
          managerPhone: item.managerPhone,

          productModel: model,
          productUint: unit,
          productCount: count,
          productPrice: price,
          productNote: note,

          productCost: item.cost,

          address: item.address,
          deliveryMethod: order.deliveryMethodName(item.currency, item.deliveryMethod),
          receiver: item.receiver,
          receiverPhone: item.receiverPhone,
          deliveryDay: item.deliveryDay,
          shipmentDay: item.shipmentDay,

          director: item.directorName,
          salesNote: item.salesNote,
          manufactureNote: item.manufactureNote,
          waybillNumber: item.waybillNumber,

          note: item.note,

          payCheck: item.payCheck ? i18n.t('order-pay-check') : '',
          payNote: item.payNote,

          manufactureCheck: item.manufactureCheck ? i18n.t('order-manufacture-check') : '',
          ecountCheck: item.ecountCheck ? i18n.t('order-ecount-item-1') : '',
          acceptDate: item.acceptDate ?
            (item.acceptDate._seconds || item.acceptDate.seconds) ?
              datefmt.date(new Date((item.acceptDate._seconds || item.acceptDate.seconds) * 1000)) :
              item.acceptDate
            : '',
        });
      }
    }),
    workSheet.columns.forEach(function (column) {
      let maxLength = 0;
      column['eachCell']({ includeEmpty: true }, function (cell) {
        let columnLength = cell.value ? cell.value.toString().length + 10 : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;

      column.alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };
    });
    let buffer = await workbook.xlsx.writeBuffer();
    let blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let filename = sanitize(`${i18n.t('orders')} ${datefmt.full(new Date())}.xlsx`);
    saveAs(blob, filename);
  },
  print: async function (id, uid, isManufacture, currency) {
    await order.log(id, uid, `${i18n.t('action')}: ${i18n.t('order-print')}`);
    //functions.useEmulator('localhost', 5000);
    const res = await functions.httpsCallable('orderPrint2')({
      id: id,
      isManufacture: isManufacture,
      locale: i18n.locale,
      currency: currency,
    });

    saveAs(product.b64toBlob(res.data.data, 'application/pdf'), res.data.filename);
  },
  uploadEcount: async function (id, vat, method, date) {
    const res = await functions.httpsCallable('orderUploadEcount')({ id: id, vat: vat, method: method, date: date });

    let logMessage = '';
    if (res.data.status == 200) {
      logMessage = `${i18n.t('action')}: ${i18n.t('order-ecount-success')}`;
    } else {
      logMessage = `${i18n.t('action')}: ${i18n.t('order-ecount-fail')} (${res.data.message})`;
    }
    await order.log(id, store.state.user.uid, logMessage);

    return res;
  },
  getCompanies: async function (vendor) {
    let companies = [];
    let snapshot = await db.collection('orders').where('vendor', '==', vendor).where('state', '!=', -1).get();
    snapshot.forEach((row) => {
      let data = row.data();
      if (!data.company) return;
      let company = {
        name: data.company,
        manager: data.manager,
        managerPhone: data.managerPhone,
      };
      companies[company.name] = company;
    });
    return companies;
  },
  currencySymbol: function (currency, cost) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'KRW',
    }).format(cost);
  },
  printPL: async function (id, uid, currency) {
    await order.log(id, uid, `${i18n.t('action')}: ${i18n.t('order-print-pl')}`);
    //functions.useEmulator('localhost', 5000);

    const res = await functions.httpsCallable('orderPrintPL')({
      id: id,
      currency: currency,
    });
    saveAs(product.b64toBlob(res.data.data, 'application/pdf'), res.data.filename);
  },
  printCI: async function (id, uid, currency) {
    await order.log(id, uid, `${i18n.t('action')}: ${i18n.t('order-print-ci')}`);
    //functions.useEmulator('localhost', 5000);
    const res = await functions.httpsCallable('orderPrintCI')({
      id: id,
      currency: currency,
    });

    saveAs(product.b64toBlob(res.data.data, 'application/pdf'), res.data.filename);
  },
};
export default order;
