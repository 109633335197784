<template>
  <v-hover v-slot:default="{ hover }" open-delay="1000">
    <v-autocomplete
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event)"
      :items="items"
      :currency="currency"
      hide-no-data
      hide-details
      auto-select-first
      :append-icon="editable && hover ? $svg('mdi-close-circle') : null"
      @click:append="$emit('remove')"
      :readonly="!editable"
    >
      <template v-slot:selection="data">
        <span class="mr-2">
          {{ data.item.model }}
        </span>
        <span class="text-caption d-none d-sm-inline" v-if="detail">
          {{ data.item.category }} | {{ data.item.group }}
        </span>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            <span class="mr-2">
              {{ data.item.model }}
            </span>
            <span class="text-caption">
              {{ data.item.category }} | {{ data.item.group }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption">
            {{ data.item.standard }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-hover>
</template>

<script>
import product from '@/product';

export default {
  name: 'product-select',
  props: {
    value: {
      type: String,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: String,
      default: 'KRW'
    }
  },
  data: () => ({
    items: [],
  }),
  created() {
    this.items = product.loadItems(this.currency);
  },
};
</script>
