import firebase from './firebase';
import store from './store';

const db = firebase.firestore();

let cachedCustomerList = undefined;
let cachedCustomerMap = undefined;

const customer = {
  init: async function () {
    await customer._load();
  },

  save: async function (customer) {
    const doc = {
      name: customer.name.trim() || '',
      englishName: customer.englishName.trim() || '',
      code: customer.code.trim() || '',
      phone: customer.phone.trim() || '',
      boss: customer.boss.trim() || '',
      mobile: customer.mobile.trim() || '',
      disabled: customer.disabled || false,
      manager: customer.manager.trim() || '',
      managerMobile: customer.managerMobile.trim() || '',
      registerTime: customer.registerTime ?
        new Date(customer.registerTime) :
        firebase.firestore.FieldValue.serverTimestamp(),
    };
    let modify = '';
    if (!customer.id || customer.id == '') {
      await db.collection('customers').add(doc);
      modify = 'add';
    }
    else {
      await db.collection('customers').doc(customer.id).update(doc);
      modify = 'update';
    }

    await store.dispatch('log', {
      module: 'customer',
      id: store.state.user.id,
      text:
        modify + ' ' +
        [
          doc.code,
          doc.name,
          doc.englishName,
          doc.boss,
          doc.phone,
          doc.mobile,
          doc.manager,
          doc.managerMobile,
          doc.registerTime,
          doc.disabled,
        ].join(' | '),
    });

    await this._load();
  },

  list: function () {
    return cachedCustomerList;
  },

  _load: async function () {
    let items = [];
    let maps = {};
    let snapshot = await db.collection('customers').get();
    snapshot.forEach((doc) => {
      let item = doc.data();
      let customer = {};
      customer.id = doc.id;
      customer.name = item.name;
      customer.englishName = item.englishName;
      customer.code = item.code;
      customer.phone = item.phone;
      customer.boss = item.boss;
      customer.mobile = item.mobile;
      customer.disabled = item.disabled || false;
      customer.registerTime = item.registerTime ? item.registerTime.toDate() : '';
      customer.manager = item.manager || '';
      customer.managerMobile = item.managerMobile || '';
      items.push(customer);
      maps[doc.id] = customer;
    });
    cachedCustomerMap = maps;
    cachedCustomerList = items.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  },

  lookupCustomer: function (id) {
    return cachedCustomerMap[id] || {};
  },

  getCellText: (cell) => {
    if (!cell)
      return cell;
    if (!cell.richText)
      return cell.toString().replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ').trim();
    return cell.richText.map(v => v.text).join('').replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ').trim();
  },

  loadExcelFile: async (filename) => {
    let customerList = cachedCustomerList;
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = async () => {
        let customers = [];
        const ExcelJS = await import(/* webpackChunkName: "exceljs" */ 'exceljs');
        const workbook = new ExcelJS.Workbook();
        const wb = await workbook.xlsx.load(reader.result);
        const sheet = wb.getWorksheet(1);
        sheet.eachRow((row, index) => {
          if (index <= 2) return;
          const doc = {
            code: customer.getCellText(row.values[1]) || '',
            name: customer.getCellText(row.values[2]) || '',
            boss: customer.getCellText(row.values[3]) || '',
            phone: customer.getCellText(row.values[4]) || '',
            mobile: customer.getCellText(row.values[5]) || '',
            manager: customer.getCellText(row.values[9]) || '',
            managerMobile: customer.getCellText(row.values[10]) || '',
            registerTime: customer.getCellText(row.values[11]) || '',
            englishName: customer.getCellText(row.values[12]) || '',
          };

          let found = customerList.find((item) => item.code == doc.code);
          if (!found) {
            doc.change = { add: true };
            customers.push(doc);
          }
          else {
            if (found.name != doc.name || found.boss != doc.boss
              || found.phone != doc.phone || found.mobile != doc.mobile
              || found.manager != doc.manager || found.managerMobile != doc.managerMobile
              || found.englishName != doc.englishName) {
              doc.change = { modify: true };
              doc.id = found.id;
              customers.push(doc);
            }
          }
        });
        resolve(customers);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(filename);
    });
  },
}

export default customer;