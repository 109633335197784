import Vue from 'vue';
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiAlarmPanel,
  mdiArrowLeft,
  mdiBank,
  mdiCheck,
  mdiCheckCircle,
  mdiClose,
  mdiCloseCircle,
  mdiCog,
  mdiCurrencyUsd,
  mdiDelete,
  mdiDns,
  mdiDomain,
  mdiDownload,
  mdiFilePlus,
  mdiFormatListBulletedSquare,
  mdiGift,
  mdiHome,
  mdiHospitalBox,
  mdiLabel,
  mdiMagnify,
  mdiMapMarker,
  mdiOpenInNew,
  mdiPencil,
  mdiPhone,
  mdiPlus,
  mdiPrinter,
  mdiRestore,
  mdiShieldLock,
  mdiStar,
  mdiThumbUp,
  mdiTruck,
  mdiArrowUpBoldCircleOutline,
  mdiBug,
  mdiCertificateOutline,
} from '@mdi/js';

const icons = {
  'mdi-account': mdiAccount,
  'mdi-account-multiple': mdiAccountMultiple,
  'mdi-alarm-panel': mdiAlarmPanel,
  'mdi-arrow-left': mdiArrowLeft,
  'mdi-bank': mdiBank,
  'mdi-bug': mdiBug,
  'mdi-check': mdiCheck,
  'mdi-check-circle': mdiCheckCircle,
  'mdi-close': mdiClose,
  'mdi-close-circle': mdiCloseCircle,
  'mdi-cog': mdiCog,
  'mdi-currency-usd': mdiCurrencyUsd,
  'mdi-delete': mdiDelete,
  'mdi-dns': mdiDns,
  'mdi-domain': mdiDomain,
  'mdi-download': mdiDownload,
  'mdi-file-plus': mdiFilePlus,
  'mdi-format-list-bulleted-square': mdiFormatListBulletedSquare,
  'mdi-gift': mdiGift,
  'mdi-home': mdiHome,
  'mdi-hospital-box': mdiHospitalBox,
  'mdi-label': mdiLabel,
  'mdi-magnify': mdiMagnify,
  'mdi-map-marker': mdiMapMarker,
  'mdi-open-in-new': mdiOpenInNew,
  'mdi-pencil': mdiPencil,
  'mdi-phone': mdiPhone,
  'mdi-plus': mdiPlus,
  'mdi-printer': mdiPrinter,
  'mdi-restore': mdiRestore,
  'mdi-shield-lock': mdiShieldLock,
  'mdi-star': mdiStar,
  'mdi-thumb-up': mdiThumbUp,
  'mdi-truck': mdiTruck,
  'mdi-arrow-up-bold-circle-outline': mdiArrowUpBoldCircleOutline,
  'mdi-certificate-outline': mdiCertificateOutline,
};

const icon = {
  install: function (Vue) {
    Vue.prototype.$svg = function (name) {
      const svg = icons[name];
      if (!svg)
        console.warn(`unknown svg icon: ${name}`);
      return svg;
    }
  }
}

Vue.use(icon);

export default icon;
