<template>
  <v-container>
    <v-snackbar v-model="alerting">
      {{ alertText }}
    </v-snackbar>
    <v-snackbar :timeout="-1" v-model="printing">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      {{ $t('agreement-printing-text') }}
    </v-snackbar>
    <loading :value="updating"></loading>
    <v-form v-model="valid">
      <v-card>
        <v-card-title>
          <agreement-state :state="state"></agreement-state>
          <v-avatar size="32" v-if="false">
            <v-img :src="authorPhotoURL" v-if="authorPhotoURL"></v-img>
          </v-avatar>
          <v-chip v-if="false" label outlined>
            {{ authorName }}
          </v-chip>
          <v-chip label outlined v-show="!vendorEditing" @click="editVendor">
            {{ vendorName }}
          </v-chip>
          <v-autocomplete
            v-model="vendor"
            :items="$store.state.vendorItems"
            hide-no-data
            hide-details
            auto-select-first
            :label="$t('vendor')"
            solo
            v-show="canEditVendor && vendorEditing"
            @blur="vendorEditing = false"
            ref="vendorEntry"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.label }}
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="hitDialog"
            scrollable
            max-width="400px"
            :disabled="!permission('agreement-edit')"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-if="permission('agreement-edit') && !isNew"
                small
                v-on="on"
              >
                {{ hitLogs.length }}
              </v-chip>
            </template>
            <v-card>
              <v-card-title primary-title class="text-h5">
                {{ $t('agreement-hits') }}
              </v-card-title>
              <v-card-text>
                <p v-for="(hit, index) in hitLogs" :key="index" class="mb-0">
                  <span class="text-caption">{{ hit.time }}</span>
                  <span class="separator px-2">|</span>
                  <span class="text-body-2">{{ hit.user }}</span>
                  <span class="separator px-2">|</span>
                  <span class="text-caption">{{ hit.vendor }}</span>
                </p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="hitDialog = false">
                  {{ $t('close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-show="canRestore"
                :disabled="updating"
                @click.prevent="restore"
                v-on="on"
                class="mx-0"
              >
                <v-icon>{{ $svg('mdi-restore') }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('agreement-restore-draft') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-show="canRemove"
                :disabled="updating"
                @click.prevent="remove"
                v-on="on"
                class="mx-0"
              >
                <v-icon>{{ $svg('mdi-delete') }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                :label="$t('agreement-title')"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('agreement-title-default');
                  },
                ]"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="customer"
                :label="$t('agreement-customer')"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('agreement-hint-customer');
                  },
                ]"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="business"
                :label="$t('agreement-business')"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('agreement-hint-business');
                  },
                ]"
                required
                @focus="onBusinessFocus"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="executor"
                :items="executorItems"
                :label="$t('agreement-executor')"
                :rules="[
                  function (v) {
                    return v.trim().length > 0 || $t('agreement-hint-executor');
                  },
                ]"
                required
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-number-field
                v-model="executorNumber"
                kind="company-number"
                :label="$t('agreement-executor-number')"
                :rules="[
                  function (v) {
                    return (
                      v.trim().length > 0 ||
                      $t('agreement-hint-executor-number')
                    );
                  },
                ]"
                required
              >
              </v-number-field>
            </v-col>
            <v-col cols="6">
              <v-select
                :label="$t('agreement-term')"
                v-model="term"
                :items="termList"
                required
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="6">
              <v-dialog
                ref="publishDateDialog"
                v-model="publishDateDialog"
                :return-value.sync="publishDate"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="publishDate"
                    :label="$t('agreement-publish-date')"
                    :persistent-hint="canEdit && !publishDate"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="publishDate"
                  :locale="this.$i18n.locale"
                  :publishDate-format="dayFormat"
                  :readonly="!canEdit"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn @click="publishDateDialog = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.publishDateDialog.save(publishDate)"
                    :disabled="!canEdit"
                  >
                    {{ $t('ok') }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <div class="text-subtitle-1">
                {{ $t('agreement-products') }}
              </div>
            </v-col>
            <template v-for="(p, index) in products">
              <v-col cols="10" :key="'name' + index">
                <product-select
                  v-model="p.model"
                  :label="$t('agreement-product-model')"
                  :editable="canEdit"
                  @remove="removeProduct(index)"
                >
                </product-select>
              </v-col>
              <v-col cols="2" :key="'count' + index">
                <v-text-field
                  v-model.number="p.count"
                  :label="$t('agreement-product-count')"
                  type="number"
                  min="1"
                  hide-details
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="12">
              <v-btn
                ref="productAddButton"
                :disabled="!canEdit"
                small
                @click="addProduct"
              >
                {{ $t('agreement-product-add') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="note"
                :label="$t('agreement-note')"
                :hint="$t('agreement-note-hint')"
                auto-grow
                rows="1"
                :readonly="!canEdit"
              ></v-textarea>
            </v-col>
            <v-col cols="12" v-show="!isNew">
              <v-divider class="my-4"></v-divider>
              <v-switch :label="$t('agreement-log')" v-model="showLog">
              </v-switch>
              <v-timeline v-show="showLog" dense align-top>
                <v-timeline-item
                  large
                  v-for="(log, index) in logs"
                  :key="index"
                  color=""
                >
                  <template v-slot:icon>
                    <v-avatar>
                      <v-img :src="log.photoURL" v-if="log.photoURL"></v-img>
                      <v-icon v-else>{{ $svg('mdi-account') }}</v-icon>
                    </v-avatar>
                  </template>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <div class="text-body-2">{{ log.user }}</div>
                      <div class="text-caption">{{ log.userVendor }}</div>
                      <div class="text-caption">{{ log.time }}</div>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <agreement-state
                        v-if="log.state != undefined"
                        :state="log.state"
                      ></agreement-state>
                      <v-chip v-if="log.vendor != undefined" label outlined>
                        {{ log.vendor }}
                      </v-chip>
                      <div v-html="log.text"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn
            v-show="canPrint"
            :disabled="updating"
            @click="print"
          >
            {{
              $t('agreement-print')
            }}
          </v-btn>
          <v-btn v-show="!isNew" :disabled="updating" @click.prevent="clone">
            {{ $t('agreement-clone') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-show="canRegister"
            :disabled="updating"
            @click.prevent="register"
          >
            {{ $t('agreement-register') }}
          </v-btn>
          <v-btn
            v-show="canAccept"
            :disabled="updating"
            @click.prevent="accept"
          >
            {{ $t('agreement-accept') }}
          </v-btn>
          <v-btn v-show="canDeny" :disabled="updating" @click.prevent="deny">
            {{ $t('agreement-deny') }}
          </v-btn>
          <v-btn
            v-show="canComplete"
            :disabled="updating"
            @click.prevent="complete"
          >
            {{ $t('agreement-complete') }}
          </v-btn>
          <v-btn
            v-show="canEdit"
            color="primary"
            :disabled="!valid || updating"
            @click.prevent="save"
          >
            {{ saveLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>


<script>
import i18n from '@/i18n';
import Loading from './Loading';
import agreement from '@/agreement';
import AgreementState from './AgreementState.vue';
import ProductSelect from './ProductSelect';
import VNumberField from './VNumberField';
import product from '@/product';
import datefmt from '@/datefmt';

export default {
  name: 'Agreement',
  components: {
    'agreement-state': AgreementState,
    'loading': Loading,
    'product-select': ProductSelect,
    'v-number-field': VNumberField,
  },
  data: () => ({
    id: null,
    item: {},
    updating: false,
    printing: false,
    note: '',
    isNew: true,
    valid: false,
    publishDateDialog: false,
    state: null,
    title: i18n.t('agreement-title-default'),
    vendorEditing: false,
    vendor: '',
    customer: '',
    publishDate: '',
    term: 0,
    business: '',
    executor: '',
    executorNumber: '',
    hits: 0,
    hitDialog: false,
    hitLogs: [],
    productItems: [],
    products: [],
    logs: [],
    executorItems: [],
    executors: {},
    showLog: localStorage
      ? localStorage.getItem('Agreement.showLog') === 'true'
      : false,
    termList: [
      {
        value: 0,
        text: i18n.t('agreement-term-none'),
      },
      {
        value: 1,
        text: i18n.t('agreement-term-one-year'),
      },
      {
        value: 2,
        text: i18n.t('agreement-term-two-year'),
      },
    ],
    alerting: false,
    alertText: '',
    listener: null,
  }),
  watch: {
    pageTitle() {
      this.$store.commit('setTitle', this.pageTitle || '');
    },
    showLog(val) {
      if (localStorage) localStorage.setItem('Agreement.showLog', val);
    },
    vendor: async function (v) {
      this.executors = await agreement.getExecutors(v);
      this.executorItems = Object.values(this.executors)
        .map((v) => v.name)
        .sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
    },
    executor() {
      let doc = this.executors[this.executor];
      if (doc) this.executorNumber = doc.number;
    },
  },
  computed: {
    pageTitle() {
      return `${this.business}`;
    },
    user() {
      return this.$store.state.user;
    },
    vendorName() {
      return this.$store.getters.getVendor(this.vendor).name;
    },
    saveLabel() {
      if (this.isNew || this.state == agreement.DRAFT)
        return i18n.t('agreement-save-draft');
      return i18n.t('save');
    },
    canView() {
      return (
        this.permission('agreement-view') ||
        (this.permission('agreement') && this.vendor == this.user.vendor)
      );
    },
    canAdd() {
      return this.permission('agreement-add');
    },
    canEdit() {
      return (
        this.permission('agreement-edit') ||
        (this.permission('agreement-add') &&
          (this.isNew ||
            (this.user.vendor == this.vendor && this.state == agreement.DRAFT)))
      );
    },
    canPrint() {
      return (
        this.state == agreement.ACCEPTED || this.state == agreement.COMPLETED
      );
    },
    canEditVendor() {
      return this.permission('agreement-edit');
    },
    canAccept() {
      return (
        this.permission('agreement-edit') && this.state == agreement.REGISTERED
      );
    },
    canComplete() {
      return (
        this.permission('agreement-add') && this.state == agreement.ACCEPTED
      );
    },
    canDeny() {
      return (
        this.permission('agreement-edit') && this.state == agreement.REGISTERED
      );
    },
    canRegister() {
      return (
        !this.isNew &&
        ((this.user.vendor == this.vendor &&
          this.permission('agreement-add')) ||
          this.permission('agreement-edit')) &&
        this.state == agreement.DRAFT
      );
    },
    canRemove() {
      return (
        !this.isNew &&
        ((this.user.vendor == this.vendor &&
          this.permission('agreement-add') &&
          this.state == agreement.DRAFT) ||
          (this.permission('agreement-edit') &&
            this.state != agreement.DELETED))
      );
    },
    canRestore() {
      return (
        !this.isNew &&
        this.permission('agreement-edit') &&
        this.state != agreement.DRAFT
      );
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  beforeDestroy() {
    agreement.unsubscribe(this.listener);
  },
  async mounted() {
    await this.init();
    if (!this.canView) {
      alert(i18n.t('agreement-view-denied'));
      this.$router.push({ path: '/agreements' });
      return;
    }
  },
  methods: {
    init: async function () {
      this.updating = true;
      this.id = this.$route.params.id;
      this.isNew = this.id == 'new';
      if (this.isNew) {
        if (!this.canAdd) {
          alert(i18n.t('agreement-add-denied'));
          return;
        }
        this.id = null;
        this.state = agreement.DRAFT;
        this.extended = false;
        this.author = this.user.uid;
        this.authorName = this.user.name;
        this.authorPhotoURL = this.user.photoURL;
        this.vendor = this.user.vendor;
        this.deadline = '';
        this.publishDate = datefmt.today();

        if (this.$route.query.src) {
          await this.load(this.$route.query.src);
          this.executorNumber = '';
          this.executor = '';
        }
      } else {
        await this.load(this.id);
      }
      this.productItems = product.loadItems();
      this.updating = false;
    },
    permission: function (perm) {
      return this.$store.state.permissions[perm] || false;
    },
    editVendor: function () {
      let self = this;
      if (!self.canEditVendor) return;
      self.vendorEditing = true;
      setTimeout(function () {
        self.$refs.vendorEntry.focus();
      }, 200);
    },
    clone: async function () {
      if (this.isNew) return;
      this.$router.push({ path: '/agreements/new', query: { src: this.id } });
    },
    addProduct: function () {
      if (!this.canEdit) return;
      this.products.push({
        model: '',
        count: 1,
      });
    },
    removeProduct: function (index) {
      if (!this.canEdit) return;
      this.products.splice(index, 1);
    },
    register: async function () {
      if (!this.canRegister) return;
      let res = confirm(i18n.t('agreement-register-confirm'));
      if (!res) return;

      this.state = agreement.REGISTERED;
      await this.save();
    },
    dayFormat: function (d) {
      return parseInt(d.substr(8, 10));
    },
    ripple: function ($el) {
      let ev = new Event('mousedown');
      let offset = $el.getBoundingClientRect();
      ev.clientX = offset.left + offset.width / 2;
      ev.clientY = offset.top + offset.height / 2;
      $el.dispatchEvent(ev);
      setTimeout(function () {
        $el.dispatchEvent(new Event('mouseup'));
      }, 200);
    },
    save: async function () {
      let doc = {};

      if (this.isNew) {
        doc.author = this.user.uid;
      }
      if (this.isNew || this.title.trim() !== this.item.title) {
        doc.title = this.title.trim();
      }
      if (this.isNew || this.customer.trim() !== this.item.customer) {
        doc.customer = this.customer.trim();
      }
      if (this.isNew || this.business.trim() !== this.item.business) {
        doc.business = this.business.trim();
      }
      if (this.isNew || this.executor.trim() !== this.item.executor) {
        doc.executor = this.executor.trim();
      }
      if (
        this.isNew ||
        this.executorNumber.trim() !== this.item.executorNumber
      ) {
        doc.executorNumber = this.executorNumber.trim();
      }
      if (this.isNew || this.term !== this.item.term) {
        doc.term = this.term;
      }
      if (this.isNew || this.publishDate != this.item.publishDate) {
        doc.publishDate = this.publishDate;
      }
      this.products = this.products.filter((v) => v.model.length > 0);
      if (this.products.length <= 0) {
        this.$vuetify.goTo(this.$refs.productAddButton, { offset: 100 });
        this.ripple(this.$refs.productAddButton.$el);
        this.$refs.productAddButton.$el.click();
        this.alerting = true;
        this.alertText = this.$t('agreement-product-add-alert');
        return;
      }
      if (
        this.isNew ||
        JSON.stringify(this.products) !== JSON.stringify(this.item.products)
      ) {
        doc.products = JSON.parse(JSON.stringify(this.products));
      }
      if (this.isNew || this.vendor != this.item.vendor) {
        doc.vendor = this.vendor;
      }
      if (this.isNew || this.state != this.item.state) {
        doc.state = this.state;
      }
      if (this.isNew || this.note != this.item.note) {
        doc.note = this.note;
      }

      if (Object.keys(doc).length === 0) {
        return;
      }
      this.updating = true;
      this.reloadAgreements();

      this.id = await agreement.save(doc, this.id, this.user.uid);
      this.isNew = false;
      this.updating = false;
      await this.load(this.id);
    },
    load: async function (id) {
      this.updating = true;
      let doc = await agreement.load(id);
      this.item = doc;
      this.title = doc.title || '';
      this.customer = doc.customer || '';
      this.business = doc.business || '';
      this.executor = doc.executor || '';
      this.executorNumber = doc.executorNumber || '';
      this.term = doc.term || 0;
      this.publishDate = doc.publishDate || '';
      this.products = doc.products
        ? JSON.parse(JSON.stringify(doc.products))
        : [];
      this.vendor = doc.vendor || '';
      this.registerTime = doc.registerTime;
      this.updating = false;

      if (this.isNew) return;

      this.state = doc.state || 0;
      this.note = doc.note || '';

      await agreement.hit(this.id, this.user.uid);
      await this.loadLogs();
      await this.loadHits();

      let self = this;
      agreement.unsubscribe(this.listener);
      this.listener = agreement.subscribe(id, async () => {
        await self.load(id);
      });
    },
    accept: async function () {
      if (!this.canAccept) return;
      this.state = agreement.ACCEPTED;
      await this.save();
    },
    deny: async function () {
      if (!this.canDeny) return;
      let res = prompt(i18n.t('agreement-deny-reason'));
      if (!res) return;

      this.state = agreement.DENIED;
      this.note += '\n\n' + i18n.t('agreement-deny-reason') + ': ' + res.trim();
      await this.save();
    },
    complete: async function () {
      if (!this.canComplete) return;
      this.state = agreement.COMPLETED;
      await this.save();
    },
    restore() {
      if (!this.canRestore) return;
      let res = confirm(i18n.t('agreement-restore-confirm'));
      if (!res) return;

      this.state = agreement.DRAFT;
      this.save();
    },
    remove: async function () {
      if (!this.canRemove) return;
      let res = confirm(i18n.t('agreement-delete-confirm'));
      if (!res) return;

      this.state = agreement.DELETED;
      let self = this;
      await self.save();
      self.$router.replace('/agreements');
    },
    loadHits: async function () {
      this.hitLogs = await agreement.loadHits(this.id);
    },
    loadLogs: async function () {
      let self = this;
      let logs = await agreement.loadLogs(self.id);
      logs.forEach((log) => {
        log.time = datefmt.full(log.time);
        log.text = log.texts
          .map((t) => {
            let colon = t.indexOf(':') + 1;
            if (colon <= 0) return t;
            return (
              '<span class="subtitle-2">' +
              t.slice(0, colon) +
              '</span>' +
              '<span class="text-body-2">' +
              t.slice(colon) +
              '</span>'
            );
          })
          .join('<br>');
      });
      this.logs = logs;
    },
    onBusinessFocus: function (e) {
      const customer = this.customer.trim();
      const business = this.business.trim();
      if (customer.length > 0 && business.length <= 0) {
        this.business = `${customer} `;
        setTimeout(() => {
          const length = this.business.length;
          e.target.setSelectionRange(length, length);
        }, 1);
      }
    },
    reloadAgreements: function () {
      this.$store.commit('reloadAgreements', true);
    },
    print: async function () {
      if (!this.canView || this.isNew) return;

      await this.save();
      this.updating = true;
      this.printing = true;
      await this.complete();
      await agreement.print(this.id, this.user.uid);
      await this.loadLogs();
      this.printing = false;
      this.updating = false;
    },
  },
};
</script>

<style scoped>
.v-chip {
  margin-right: 4px;
}
.link {
  color: inherit;
  text-decoration: none;
}
.separator {
  color: gray;
}
.drag {
  background-color: gray;
}
</style>